import {
  Button,
  CircularProgress,
  Input,
  Modal,
  styled,
} from "@mui/joy";
import { BASE_URL, BLACK, PRIMARY, WHITE } from "../../appConstants";
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import toast from "react-hot-toast";
import appError from "../../appError";
import { useSelector } from "react-redux";
import {
  formatDateTime
} from "../../appFunctions";
import Joi from "joi";

type UploadStateObject = {
  file_id: string;
  file_name: string;
  created_time_stamp: string;
  platform_name: string;
};
type Dashboard = {
  brand_power_bi_dashboard_type_id: string;
  color: string;
  dashboard_type: string;
}
type AddKeywordTypeProps = {
  view: string;
  setView: Dispatch<SetStateAction<string>>;
  setAddPlatformToggle: Dispatch<SetStateAction<boolean>>;
  fetchPlatforms: () => void;
  fetchDashboards: () => void;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  dashboardlist : Dashboard[]
};
const AddPlatformModal: React.FC<AddKeywordTypeProps> = ({
  view,
  setView,
  setAddPlatformToggle,
  fetchPlatforms,
  fetchDashboards,
  loading,
  setLoading,
  dashboardlist
}) => {

  type UploadPlatformComponentProps = {
    setAddPlatformToggle: Dispatch<SetStateAction<boolean>>;
    fetchPlatforms: () => void;
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
  };
  // Below is Component for Add Platform
  const UploadPlatformComponent: React.FC<UploadPlatformComponentProps> = ({
    setAddPlatformToggle,
    fetchPlatforms,
    loading,
    setLoading,
  }) => {
    const [loadingUploadExcel, setLoadingUploadExcel] =
      useState<boolean>(false);
    const [state, setState] = useState<UploadStateObject>(
      {} as UploadStateObject
    );
    // Below function for Changing only png File
    const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
      if (!(event.target.files && event.target.files.length > 0)) {
        return appError("File not selected.");
      }

      try {
        setLoadingUploadExcel(true);
        let file = event.target.files[0];
        const token = localStorage.getItem("token");

        const res = await axios.post(
          `${BASE_URL}brand/platform/logo`,
          { myImage: file },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const {
          message,
          data: { filename, originalname, created_time_stamp },
        } = res?.data;
        setState((prevState) => ({
          ...prevState,
          file_id: filename,
          file_name: originalname,
          created_time_stamp,
        }));
        toast.success(message);
      } catch (err: any) {
        // console.log(err);
        setState((prevState) => ({
          ...prevState,
          file_id: "",
          file_name: "",
          created_time_stamp: "",
        }));
        appError(err?.response?.data?.data?.message);
      } finally {
        setLoadingUploadExcel(false);
      }
    };

    const VisuallyHiddenInput = styled("input")`
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      bottom: 0;
      left: 0;
      white-space: nowrap;
      width: 1px;
    `;
    // Below function for Changing only Platform Name
    const onInputChangeHandler = ({
      target: { value, name },
    }: React.ChangeEvent<HTMLInputElement>): void => {
      setState((prevState) => ({ ...prevState, platform_name: value }));
    };

    // Save Function for validating and saving by calling POST Platform API
    const save = async () => {
      const schema = Joi.object({
        file_name: Joi.string(),
        created_time_stamp: Joi.string(),
        platform_name: Joi.any().required().messages({
          "any.required": "Please enter Platform name.",
        }),
        file_id: Joi.string().required().messages({
          "string.base": "Please upload file.",
          "any.required": "Please upload file.",
        }),
      });
      const { file_name, file_id, platform_name, created_time_stamp } = state;
      const validate = schema.validate({
        file_name,
        created_time_stamp,
        platform_name,
        file_id,
      });
      if (validate.error) {
        appError(validate?.error?.toString());
        return;
      }
      try {
        setLoading(true);
        const token = localStorage.getItem("token");

        const res = await axios.post(
          `${BASE_URL}brand/platform`,
          { platform: platform_name, platform_logo_url: file_id, file_name},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { message } = res?.data?.data;
        fetchPlatforms();
        setAddPlatformToggle(false);
        toast.success(message);
      } catch (error) {
        appError(error);
        return;
      } finally {
        setLoading(false);
      }
    };

    return (
      <>
      {/* 1st Div : For All Uploading Fields */}
        <div className="flex flex-col items-start gap-6 self-stretch">
          {/* 1st sub div : For Add Platform Details Heading*/}
          <div style={{ height: 46 }} className="flex flex-col justify-between">
            <div
              className="self-stretch"
              style={{
                fontSize: 16,
                fontWeight: 600,
                color: BLACK.B_100,
                lineHeight: "24px",
              }}
            >
              Add Platform Details
            </div>
            <p
              className="self-stretch"
              style={{
                fontSize: 12,
                fontWeight: 400,
                color: BLACK.B_40,
                lineHeight: "18px",
              }}
            >
              Complete the form with the platform name and logo
            </p>
          </div>
          {/* 2nd sub div : For Input Field Platform Name*/}
          <div className="flex h-[76px] w-[710px] flex-col items-start gap-1">
            <div
              className="flex h-[20px] flex-col justify-center flex-shrink-0 self-stretch"
              style={{
                fontSize: 14,
                fontWeight: 500,
                lineHeight: "20px",
                color: BLACK.B_80,
              }}
            >
              Platform Name
            </div>
            <Input
              autoComplete="off"
              placeholder="Add Platform Name"
              sx={{
                "--Input-focusedThickness": "0px",
                height: "52px",
                width: "100%",
                backgroundColor: WHITE.W_100,
                fontSize: 14,
                fontWeight: 400,
                lineHeight: "20px",
              }}
              name="platform_name"
              value={state?.platform_name}
              onChange={onInputChangeHandler}
            />
          </div>
          {/* 3rd sub div : For Input File where png logo can upload*/}
          <div className="">
            <Button
              component="label"
              role={undefined}
              tabIndex={-1}
              variant="outlined"
              color="neutral"
              style={{
                height: 160,
                width: 710,
                border: `1px dashed ${BLACK.B_10}`,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <svg
                  className="mb-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                >
                  <path
                    d="M18.75 20.3587L16.5288 22.5546C16.3007 22.7851 16.0156 22.9004 15.6733 22.9004C15.3314 22.9004 15.0364 22.7765 14.7883 22.5288C14.5469 22.2979 14.4289 22.0053 14.4342 21.6508C14.4397 21.2961 14.5578 21.0033 14.7883 20.7725L18.9454 16.6154C19.2468 16.314 19.5983 16.1633 20 16.1633C20.4017 16.1633 20.7532 16.314 21.0546 16.6154L25.2117 20.7725C25.4422 21.0033 25.5644 21.2913 25.5783 21.6362C25.5922 21.9812 25.47 22.2792 25.2117 22.53C24.9808 22.76 24.6924 22.8765 24.3463 22.8796C24.0001 22.8829 23.7031 22.7692 23.455 22.5383L21.25 20.3587V29.4871C21.25 29.6154 21.3035 29.7329 21.4104 29.8396C21.5171 29.9465 21.6346 30 21.7629 30H30.8333C32 30 32.9861 29.5972 33.7917 28.7917C34.5972 27.9861 35 27 35 25.8333C35 24.6667 34.5972 23.6806 33.7917 22.875C32.9861 22.0694 32 21.6667 30.8333 21.6667H28.3333V18.3333C28.3333 16.0278 27.5208 14.0625 25.8958 12.4375C24.2708 10.8125 22.3056 10 20 10C17.6944 10 15.7292 10.8125 14.1042 12.4375C12.4792 14.0625 11.6667 16.0278 11.6667 18.3333H10.8013C9.22208 18.3333 7.86042 18.9028 6.71625 20.0417C5.57208 21.1806 5 22.5556 5 24.1667C5 25.7778 5.56944 27.1528 6.70833 28.2917C7.84722 29.4306 9.22222 30 10.8333 30H13.75C14.1042 30 14.4011 30.1199 14.6408 30.3596C14.8803 30.5993 15 30.8963 15 31.2504C15 31.6049 14.8803 31.9017 14.6408 32.1408C14.4011 32.3803 14.1042 32.5 13.75 32.5H10.8333C8.53 32.5 6.56528 31.701 4.93917 30.1029C3.31306 28.5049 2.5 26.5518 2.5 24.2438C2.5 22.1732 3.16611 20.3521 4.49833 18.7804C5.83056 17.2088 7.48278 16.2776 9.455 15.9871C9.98917 13.4957 11.2419 11.4583 13.2133 9.875C15.1844 8.29167 17.4467 7.5 20 7.5C23.0178 7.5 25.5778 8.55111 27.68 10.6533C29.7822 12.7556 30.8333 15.3156 30.8333 18.3333V19.1667H31.3463C33.1026 19.3033 34.5674 20.0096 35.7404 21.2854C36.9135 22.561 37.5 24.0769 37.5 25.8333C37.5 27.6922 36.859 29.2681 35.5771 30.5608C34.2949 31.8536 32.7243 32.5 30.8654 32.5H21.7629C20.921 32.5 20.2083 32.2083 19.625 31.625C19.0417 31.0417 18.75 30.329 18.75 29.4871V20.3587Z"
                    fill="#1C1C1C"
                  />
                </svg>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: 14,
                    fontWeight: 700,
                    lineHeight: "20px",
                    height: 40,
                  }}
                >
                  Upload Your File{" "}
                  <span
                    className="ml-1"
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                      lineHeight: "20px",
                      color: PRIMARY.P_BLue,
                      borderBottom: `1px solid ${PRIMARY.P_BLue}`,
                    }}
                  >
                    Here
                  </span>
                </div>
                <div
                  style={{ fontSize: 10, fontWeight: 500, color: BLACK.B_20 }}
                >
                  Please upload your logo here. Only .jpg or .png files are supported.
                </div>
              </div>
              <VisuallyHiddenInput onChange={onFileChange} type="file" />
            </Button>
          </div>
          {/* 4th sub div : This Div is for Details of Logo that is uploaded*/}
          {state?.file_id && (
            <div className="flex flex-start gap-4 self-stretch ">
              <div className="flex items-start gap-2 flex-1 basis-0 shrink-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M7.07696 27.3334C6.4034 27.3334 5.83329 27.1 5.36663 26.6334C4.89996 26.1667 4.66663 25.5966 4.66663 24.923V7.07702C4.66663 6.40346 4.89996 5.83335 5.36663 5.36669C5.83329 4.90002 6.4034 4.66669 7.07696 4.66669H24.923C25.5965 4.66669 26.1666 4.90002 26.6333 5.36669C27.1 5.83335 27.3333 6.40346 27.3333 7.07702V24.923C27.3333 25.5966 27.1 26.1667 26.6333 26.6334C26.1666 27.1 25.5965 27.3334 24.923 27.3334H7.07696ZM7.07696 25.3334H24.923C25.0256 25.3334 25.1196 25.2906 25.205 25.205C25.2905 25.1197 25.3333 25.0257 25.3333 24.923V7.07702C25.3333 6.97435 25.2905 6.88035 25.205 6.79502C25.1196 6.70946 25.0256 6.66669 24.923 6.66669H7.07696C6.97429 6.66669 6.88029 6.70946 6.79496 6.79502C6.7094 6.88035 6.66663 6.97435 6.66663 7.07702V24.923C6.66663 25.0257 6.7094 25.1197 6.79496 25.205C6.88029 25.2906 6.97429 25.3334 7.07696 25.3334ZM8.99996 22.3334H23.1023L18.718 16.4874L14.9743 21.359L12.3076 17.9487L8.99996 22.3334Z"
                    fill="#1C1C1C"
                    fill-opacity="0.4"
                  />
                </svg>
                <div className="flex flex-col item-start gap-1 flex-1 basis-0 shrink-0">
                  <div
                    className="w-[82.8%]"
                    style={{
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "18px",
                    }}
                  >
                    {state?.file_name}
                  </div>
                  <div className="flex items-center gap-1 self-stretch">
                    <div
                      className=""
                      style={{
                        color: BLACK.B_20,
                        fontSize: "10px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                      }}
                    >
                      {formatDateTime(state?.created_time_stamp)[0]}{" "}
                    </div>
                    <div
                      className=""
                      style={{
                        color: BLACK.B_20,
                        fontSize: "10px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                      }}
                    >
                      <svg
                        className="mx-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="4"
                        height="4"
                        viewBox="0 0 4 4"
                        fill="none"
                      >
                        <circle cx="2" cy="2" r="2" fill="#B8B8B8" />
                      </svg>{" "}
                    </div>
                    <div
                      className=""
                      style={{
                        color: BLACK.B_20,
                        fontSize: "10px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                      }}
                    >
                      {formatDateTime(state?.created_time_stamp)[1]}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                {loadingUploadExcel && <CircularProgress size="sm" />}
                {state?.file_id && !loadingUploadExcel && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M10.5808 14.1463L8.25775 11.823C8.11925 11.6847 7.94517 11.6138 7.7355 11.6105C7.526 11.6073 7.34875 11.6782 7.20375 11.823C7.05892 11.968 6.9865 12.1437 6.9865 12.35C6.9865 12.5563 7.05892 12.732 7.20375 12.877L9.948 15.6212C10.1288 15.8019 10.3398 15.8923 10.5808 15.8923C10.8218 15.8923 11.0327 15.8019 11.2135 15.6212L16.777 10.0578C16.9153 9.91925 16.9862 9.74517 16.9895 9.5355C16.9927 9.326 16.9218 9.14875 16.777 9.00375C16.632 8.85892 16.4563 8.7865 16.25 8.7865C16.0437 8.7865 15.868 8.85892 15.723 9.00375L10.5808 14.1463ZM12.0017 21.5C10.6877 21.5 9.45267 21.2507 8.2965 20.752C7.14033 20.2533 6.13467 19.5766 5.2795 18.7218C4.42433 17.8669 3.74725 16.8617 3.24825 15.706C2.74942 14.5503 2.5 13.3156 2.5 12.0017C2.5 10.6877 2.74933 9.45267 3.248 8.2965C3.74667 7.14033 4.42342 6.13467 5.27825 5.2795C6.13308 4.42433 7.13833 3.74725 8.294 3.24825C9.44967 2.74942 10.6844 2.5 11.9983 2.5C13.3123 2.5 14.5473 2.74933 15.7035 3.248C16.8597 3.74667 17.8653 4.42342 18.7205 5.27825C19.5757 6.13308 20.2528 7.13833 20.7518 8.294C21.2506 9.44967 21.5 10.6844 21.5 11.9983C21.5 13.3123 21.2507 14.5473 20.752 15.7035C20.2533 16.8597 19.5766 17.8653 18.7218 18.7205C17.8669 19.5757 16.8617 20.2528 15.706 20.7518C14.5503 21.2506 13.3156 21.5 12.0017 21.5Z"
                      fill="#007F5F"
                    />
                  </svg>
                )}
              </div>
            </div>
          )}
        </div>
          {/* 2nd Div: For Cancel and Save Button */}
        <div className="flex justify-center items-start gap-4 self-stretch">
          <Button
            color="neutral"
            sx={{
              height: "48px",
              width: "49%",
              backgroundColor: BLACK.B_5,
              color: BLACK.B_100,
              ":hover": {
                backgroundColor: BLACK.B_20,
              },
            }}
            onClick={() => setAddPlatformToggle(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={save}
            sx={{
              height: "48px",
              width: "49%",
              backgroundColor: PRIMARY.P_BLue,
            }}
            loading={loading}
          >
            Save
          </Button>
        </div>
      </>
    );
  };

  // Below is Component for Add Dashboard
  const UploadDashboardComponent: React.FC<{
    setAddPlatformToggle: Dispatch<SetStateAction<boolean>>;
    setLoading: Dispatch<SetStateAction<boolean>>;
    dashboardlist: Dashboard[];
  }> = ({ setAddPlatformToggle, setLoading, dashboardlist }) => {
    type Selected = {
      dashboard_type:string,
      color:string
    }
    const [selectedColor, setSelectedColor] = useState<Selected>({
      dashboard_type:"",
      color: ""
    });

    // Below onChangeHandler is used for changing Dashboard Type Name
    const onChangeHandler = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>)=>{
      const {name, value} = e.target;
      setSelectedColor((prev)=> ({
        ...prev,
        [name]: value
      }));
    }

    // Below onChangeColorHandler is used for Changing only colors
    const onChangeColorHandler = (code: string) =>{
      setSelectedColor((prev)=> ({
        ...prev,
        "color": code
      }));
    }

    const [colors, setColors] = useState<
      { color_name: string; color_code: string }[]
    >([
      {
        color_name: "Light Sky Blue",
        color_code: "#B1E3FF",
      },
      {
        color_name: "Lavender Blue",
        color_code: "#C6C7F8",
      },
      {
        color_name: "Pale Mint Green.",
        color_code: "#BAEDBD",
      },
      {
        color_name: "Peach",
        color_code: "#FFDBA1",
      },
      {
        color_name: "Salmon Pink",
        color_code: "#FFB8B8",
      },
      {
        color_name: "Aqua Green",
        color_code: "#A4FFE5",
      },
      {
        color_name: "Lavender Pink.",
        color_code: "#FFD5FE",
      },
      {
        color_name: "Grey",
        color_code: "#1C1C1C1A",
      },
    ]); 

    // FilteredColors function is used for filtering colors Array
    const filteredColors = ()=>{
      if(dashboardlist.length > 0){
        const dashboardSet = new Set(dashboardlist.map(dashboard => dashboard.color));
        const filteredcolors = colors.filter(color => !dashboardSet.has(color.color_code));  
        setColors(filteredcolors);
      }
    }
    // useEffect is used for calling filteredColors function only on First render
    useEffect(()=>{
      filteredColors();
    },[]);

    // Save function is used for validating and saving by calling POST Dashboard Type API.
    const save = async() => {
      const schema = Joi.object({
        dashboard_type: Joi.string().required(),
        color: Joi.string().required(),
      });
      const validate = schema.validate(selectedColor);
      if (validate.error) {
        appError(validate?.error?.toString());
        return;
      }
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        const {dashboard_type, color} = selectedColor;
        const res = await axios.post(
          `${BASE_URL}brand/powerBiDashboardType`,
          { dashboard_type, color },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { message } = res?.data?.data;
        fetchDashboards();
        setAddPlatformToggle(false);
        toast.success(message);
      } catch (error) {
        appError(error);
        return;
      } finally {
        setLoading(false);
      }
    }
    
    return (
      <>
        {/* 1st Div : For All Uploading Fields */}
        <div className="flex flex-col items-start gap-6 self-stretch">
          {/* 1st sub div : For Dashboard Type SubHeading*/}
          <div className="flex h-[46px] flex-col items-start gap-1">
            <div
              className="self-stretch"
              style={{
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "24px",
                color: BLACK.B_100,
              }}
            >
              Add Dashboard Type Details
            </div>
            <div
              className="self-stretch"
              style={{
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "18px",
                color: BLACK.B_40,
              }}
            >
              Fill in the dashboard name and select a corresponding color
            </div>
          </div>

          {/* 2nd Sub div : For Dashboard Type Name Input Field*/}
          <div className="flex h-[76px] flex-col items-start gap-1 self-stretch">
            <div
              className="flex h-[20px] flex-col justify-center flex-shrink-0 self-stretch"
              style={{
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
                color: "#5B5B5B",
              }}
            >
              Dashboard Type Name
            </div>
            <Input
              placeholder="Add Dashboard Type Name"
                value={selectedColor?.dashboard_type}
              className="flex px-4 py-5 items-center self-stretch gap-6 rounded-[8px] "
              sx={{
                "--Input-focusedThickness": "0px",
                height: "52px",
                border: `1px solid ${BLACK.B_10}`,
                fontSize: 14,
                fontWeight: 400,
                lineHeight: "20px",
                backgroundColor: `${WHITE.W_100}`,
                boxShadow: "none",
              }}
              name="dashboard_type"
                onChange={onChangeHandler}
            />
          </div>

          {/* 3rd sub div : For Selecting color from colors*/}
          <div className="flex flex-col item-start gap-4 self-stretch">
            <div
              className="flex h-[20px] flex-col justify-center self-stretch"
              style={{
                color: "#5B5B5B",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              Dashboard Type Color
            </div>
            <div className="flex items-start gap-2">
              {colors.length > 0 &&
                colors.map((color) => (
                  <div
                    className="w-[40px] h-[40px] rounded-[50%] flex justify-center items-center cursor-pointer"
                    style={{ backgroundColor: color.color_code }}
                    key={color.color_name}
                    onClick={()=> onChangeColorHandler(color.color_code)}
                  >
                    { selectedColor && selectedColor?.color === color.color_code && (<svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M21.5306 7.28061L9.53055 19.2806C9.4609 19.3503 9.37818 19.4057 9.28713 19.4434C9.19609 19.4812 9.09849 19.5006 8.99993 19.5006C8.90137 19.5006 8.80377 19.4812 8.71272 19.4434C8.62168 19.4057 8.53896 19.3503 8.4693 19.2806L3.2193 14.0306C3.07857 13.8899 2.99951 13.699 2.99951 13.5C2.99951 13.301 3.07857 13.1101 3.2193 12.9694C3.36003 12.8286 3.55091 12.7496 3.74993 12.7496C3.94895 12.7496 4.13982 12.8286 4.28055 12.9694L8.99993 17.6897L20.4693 6.21936C20.61 6.07863 20.8009 5.99957 20.9999 5.99957C21.199 5.99957 21.3898 6.07863 21.5306 6.21936C21.6713 6.36009 21.7503 6.55097 21.7503 6.74999C21.7503 6.94901 21.6713 7.13988 21.5306 7.28061Z"
                        fill="white"
                      />
                    </svg>)}
                  </div>
                ))}
            </div>
          </div>
        </div>

        {/* 2nd Div: For Cancel and Save Button */}
        <div className="flex justify-center items-start gap-4 self-stretch">
          <Button
            color="neutral"
            sx={{
              height: "48px",
              width: "340px",
              backgroundColor: BLACK.B_5,
              color: BLACK.B_100,
              ":hover": {
                backgroundColor: BLACK.B_20,
              },
            }}
            onClick={() => setAddPlatformToggle(false)}
          >
            Cancel
          </Button>
          <Button
            loading={loading}
            onClick={save}
            sx={{
              height: "48px",
              width: "340px",
              backgroundColor: PRIMARY.P_BLue,
            }}
          >
            Save
          </Button>
        </div>
      </>
    );
  };

  return (
    <Modal
      open={true}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      className="h-full"
    >
      <div
        style={{ width: 790 }}
        className="flex flex-col items-start rounded-[18px] p-10 bg-white gap-10"
      >
        {view === "add_platform" && (
          <UploadPlatformComponent
            setAddPlatformToggle={setAddPlatformToggle}
            fetchPlatforms={fetchPlatforms}
            loading={loading}
            setLoading={setLoading}
          />
        )}
        {view === "add_dashboard" && (
          <UploadDashboardComponent
            setAddPlatformToggle={setAddPlatformToggle}
            setLoading={setLoading}
            dashboardlist={dashboardlist}
          />
        )}
      </div>
    </Modal>
  );
};

export default AddPlatformModal;