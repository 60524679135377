import { Button, CircularProgress } from "@mui/joy";
import { BASE_URL, BLACK, PRIMARY, STATUS, WHITE } from "../../appConstants";
import { useEffect, useState } from "react";
import RemovePlatformModal from "./RemovePlatformModal";
import AddPlatformModal from "./AddPlatformModal";
import EditPlatformModal from "./EditPlatformModal";
import axios from "axios";
import appError from "../../appError";
import PlatformLogo from "./PlatformLogo";

type Remove = {
  remove_toggle: boolean;
  remove_name: string;
  remove_type: string;
  remove_id: string;
};
type Platform = {
  platform_id: string;
  platform: string;
  platform_logo_url: string;
  file_name: string;
  created_time_stamp: string;
  updated_time_stamp: string;
};
type Dashboard = {
  brand_power_bi_dashboard_type_id: string;
  color: string;
  dashboard_type: string;
};

const PlatformdashboardList: React.FC = () => {
  const [platformlist, setPlatformList] = useState<Platform[]>([]);
  const [dashboardlist, setDashboardList] = useState<Dashboard[]>([]);
  const [removedata, setRemoveData] = useState<Remove>({
    remove_toggle: false,
    remove_name: "",
    remove_type: "",
    remove_id: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [addPlatformtoggle, setAddPlatformToggle] = useState<boolean>(false);
  const [editPlatformtoggle, setEditPlatformToggle] = useState<boolean>(false);
  const [editDashboarddata, setEditDashboardData] = useState<Dashboard>({
    brand_power_bi_dashboard_type_id: "",
    color: "",
    dashboard_type: "",
  });
  const [editPlatformdata, setEditPlatformdata] = useState<Platform>({
    platform_id: "",
    platform: "",
    platform_logo_url: "",
    file_name: "",
    created_time_stamp: "",
    updated_time_stamp: "",
  });
  const [view, setView] = useState<string>("");
  // Below function is used for setting view for Add Platform & Add Dashboard Modal
  const addPlatformDashboardHandler = (type_name: string) => {
    setAddPlatformToggle(true);
    setView(type_name);
  };

  // Below function is used for setting view for Edit Platform Modal
  const editPlatformHandler = (p: Platform, type_name: string) => {
    setEditPlatformdata(p);
    setEditPlatformToggle(true);
    setView(type_name);
  };

  // Below function is used for setting view for Edit Dashboard Modal
  const editDashboardHandler = (d: Dashboard, type_name: string) => {
    setEditDashboardData(d);
    setEditPlatformToggle(true);
    setView(type_name);
  };

  // fetchPlatforms function is used for fetching all platforms details data.
  const fetchPlatforms = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      let api = `${BASE_URL}brand/platform`;
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const result = await axios.get(api, { headers });
      const { platform_array } = result?.data?.data;
      setPlatformList(platform_array);
    } catch (error) {
      // console.log(error);
      appError(error);
    } finally {
      setLoading(false);
    }
  };

  // fetchDashboards function is used for fetching all Dashboards details data.
  const fetchDashboards = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      let api = `${BASE_URL}brand/powerBiDashboardType`;
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const result = await axios.get(api, { headers });
      const { dashboard_type_array } = result?.data?.data;
      setDashboardList(dashboard_type_array);
    } catch (error) {
      // console.log(error);
      appError(error);
    } finally {
      setLoading(false);
    }
  };

  // UseEffect is used for calling above both functions only on First Render
  useEffect(() => {
    fetchPlatforms();
    fetchDashboards();
  }, []);

  return (
    <>
      <div className="pt-6 px-10 pb-10 flex flex-col items-start gap-6">
        {/* SubComponent 1 : Platforms */}
        <div className="flex flex-col items-start gap-6 self-stretch">
          {/* Sub Div 1 : This Div is used for Platform Heading & Add Platform Button*/}
          <div className="flex items-center justify-between gap-6 self-stretch">
            <div
              className=""
              style={{
                fontSize: 18,
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "24px",
                textTransform: "uppercase",
                color: BLACK.B_20,
              }}
            >
              Platforms
            </div>
            <Button
              onClick={() => addPlatformDashboardHandler("add_platform")}
              sx={{
                height: "48px",
                width: "14.7%",
                backgroundColor: PRIMARY.P_BLue,
                padding: "8px 16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
              }}
              loading={loading}
            >
              <div className="w-[20px] h-[20px] flex-shrink-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <path
                    d="M9.66663 10.8333H5.49996C5.26385 10.8333 5.06593 10.7535 4.90621 10.5937C4.74649 10.434 4.66663 10.2361 4.66663 9.99999C4.66663 9.76388 4.74649 9.56596 4.90621 9.40624C5.06593 9.24652 5.26385 9.16666 5.49996 9.16666H9.66663V4.99999C9.66663 4.76388 9.74649 4.56596 9.90621 4.40624C10.0659 4.24652 10.2638 4.16666 10.5 4.16666C10.7361 4.16666 10.934 4.24652 11.0937 4.40624C11.2534 4.56596 11.3333 4.76388 11.3333 4.99999V9.16666H15.5C15.7361 9.16666 15.934 9.24652 16.0937 9.40624C16.2534 9.56596 16.3333 9.76388 16.3333 9.99999C16.3333 10.2361 16.2534 10.434 16.0937 10.5937C15.934 10.7535 15.7361 10.8333 15.5 10.8333H11.3333V15C11.3333 15.2361 11.2534 15.434 11.0937 15.5937C10.934 15.7535 10.7361 15.8333 10.5 15.8333C10.2638 15.8333 10.0659 15.7535 9.90621 15.5937C9.74649 15.434 9.66663 15.2361 9.66663 15V10.8333Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div
                style={{
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                Add Platform
              </div>
            </Button>
          </div>
          {/* Sub Div 2 : This is Parent Div used for All Platforms*/}
          <div className="flex flex-col self-stretch gap-6">
            <div className="flex flex-wrap justify-between gap-6">
              {platformlist.length > 0 &&
                platformlist.map((p) => (
                  // Below Div Represent One Single Platform
                  <div
                    className="flex flex-col p-4 items-start gap-10 rounded-[8px] w-[49%]"
                    style={{ border: `1px solid ${BLACK.B_5}` }}
                    key={p.platform_id}
                  >
                    {/* Below Div is used For Platform Information Sub Heading and Remove & Edit Details Button */}
                    <div className="flex items-center gap-4 justify-between self-stretch w-full">
                      <div className="flex flex-col items-start justify gap-[4px]">
                        <div
                          className="self-stretch"
                          style={{
                            fontSize: 16,
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "20px",
                            color: BLACK.B_100,
                          }}
                        >
                          Platform Information
                        </div>
                        <div
                          className="self-stretch"
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            fontStyle: "normal",
                            lineHeight: "18px",
                            color: BLACK.B_40,
                          }}
                        >
                          Explore key details, including platform name and logo
                        </div>
                      </div>
                      <div className="flex items-center gap-4">
                        <div className="flex justify-center items-center gap-2 rounded-[8px]">
                          <div
                            className="underline cursor-pointer"
                            onClick={() =>
                              setRemoveData({
                                ...removedata,
                                remove_toggle: true,
                                remove_name: p.platform,
                                remove_type: "Platform",
                                remove_id: p.platform_id,
                              })
                            }
                            style={{
                              fontSize: 14,
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "20px",
                              color: STATUS.S_Red,
                            }}
                          >
                            Remove
                          </div>
                        </div>
                        <div className="flex justify-center items-center gap-2 rounded-[8px]">
                          <div
                            className="underline cursor-pointer"
                            onClick={() =>
                              editPlatformHandler(p, "edit_platform")
                            }
                            style={{
                              fontSize: 14,
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "20px",
                              color: PRIMARY.P_BLue,
                            }}
                          >
                            Edit Details
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Below Div is used for Platform name and Platform logo*/}
                    <div className="flex flex-col justify-center items-start gap-4 self-stretch">
                      <div className="flex items-start gap-[60px]">
                        <div className="flex flex-col items-start gap-2">
                          <div
                            className="self-stretch"
                            style={{
                              fontSize: 10,
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "normal",
                              color: BLACK.B_40,
                            }}
                          >
                            Platform Name
                          </div>
                          <div
                            className="self-stretch"
                            style={{
                              fontSize: 14,
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "20px",
                            }}
                          >
                            {p.platform}
                          </div>
                        </div>
                        {/* For Logo */}
                        <div className="flex flex-col items-start gap-2">
                          <div
                            className="self-stretch"
                            style={{
                              fontSize: 10,
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "normal",
                              color: BLACK.B_40,
                            }}
                          >
                            Platform Logo
                          </div>
                          <PlatformLogo
                            logo_url={p?.platform_logo_url}
                          />
                          {/* <div
                            className="self-stretch"
                            style={{
                              fontSize: 14,
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "20px",
                            }}
                          >
                            "Logo"
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        {/* SubComponent 2 : Dashboard Type*/}
        <div className="flex flex-col items-start gap-6 self-stretch">
          {/* Sub Div 1 : This Div is used for Dashboard Type Heading & Add Dashboard Button*/}
          <div className="flex items-center justify-between gap-6 self-stretch">
            <div
              className=""
              style={{
                fontSize: 18,
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "24px",
                textTransform: "uppercase",
                color: BLACK.B_20,
              }}
            >
              Dashboard Types
            </div>
            <Button
              onClick={() => addPlatformDashboardHandler("add_dashboard")}
              sx={{
                height: "48px",
                width: "14.7%",
                backgroundColor: PRIMARY.P_BLue,
                padding: "8px 16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
              }}
              loading={loading}
            >
              <div className="w-[20px] h-[20px] flex-shrink-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <path
                    d="M9.66663 10.8333H5.49996C5.26385 10.8333 5.06593 10.7535 4.90621 10.5937C4.74649 10.434 4.66663 10.2361 4.66663 9.99999C4.66663 9.76388 4.74649 9.56596 4.90621 9.40624C5.06593 9.24652 5.26385 9.16666 5.49996 9.16666H9.66663V4.99999C9.66663 4.76388 9.74649 4.56596 9.90621 4.40624C10.0659 4.24652 10.2638 4.16666 10.5 4.16666C10.7361 4.16666 10.934 4.24652 11.0937 4.40624C11.2534 4.56596 11.3333 4.76388 11.3333 4.99999V9.16666H15.5C15.7361 9.16666 15.934 9.24652 16.0937 9.40624C16.2534 9.56596 16.3333 9.76388 16.3333 9.99999C16.3333 10.2361 16.2534 10.434 16.0937 10.5937C15.934 10.7535 15.7361 10.8333 15.5 10.8333H11.3333V15C11.3333 15.2361 11.2534 15.434 11.0937 15.5937C10.934 15.7535 10.7361 15.8333 10.5 15.8333C10.2638 15.8333 10.0659 15.7535 9.90621 15.5937C9.74649 15.434 9.66663 15.2361 9.66663 15V10.8333Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div
                style={{
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                Add Dashboard Type
              </div>
            </Button>
          </div>
          {/* Sub Div 2 : This is Parent Div used for All Dashboards*/}
          <div className="flex flex-col self-stretch gap-6">
            <div className="flex flex-wrap justify-between gap-6">
              {dashboardlist.length > 0 &&
                dashboardlist.map((d) => (
                  // Below Div Represent One Single Dashboard
                  <div
                    className="flex flex-col p-4 items-start gap-10 rounded-[8px] w-[49%]"
                    style={{ border: `1px solid ${BLACK.B_5}` }}
                    key={d.brand_power_bi_dashboard_type_id}
                  >
                    {/* Below Div is used For Dashboard Type Information Sub Heading and Remove & Edit Details Button */}
                    <div className="flex items-center gap-4 justify-between self-stretch w-full">
                      <div className="flex flex-col items-start justify gap-[4px]">
                        <div
                          className="self-stretch"
                          style={{
                            fontSize: 16,
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "20px",
                            color: BLACK.B_100,
                          }}
                        >
                          Dashboard Type Information
                        </div>
                        <div
                          className="self-stretch"
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            fontStyle: "normal",
                            lineHeight: "18px",
                            color: BLACK.B_40,
                          }}
                        >
                          Review the dashboard type name and color in detail
                        </div>
                      </div>
                      <div className="flex items-center gap-4">
                        <div className="flex justify-center items-center gap-2 rounded-[8px]">
                          <div
                            className="underline cursor-pointer"
                            onClick={() =>
                              setRemoveData({
                                ...removedata,
                                remove_toggle: true,
                                remove_name: d.dashboard_type,
                                remove_type: "Dashboard",
                                remove_id: d.brand_power_bi_dashboard_type_id,
                              })
                            }
                            style={{
                              fontSize: 14,
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "20px",
                              color: STATUS.S_Red,
                            }}
                          >
                            Remove
                          </div>
                        </div>
                        <div className="flex justify-center items-center gap-2 rounded-[8px]">
                          <div
                            className="underline cursor-pointer"
                            onClick={() =>
                              editDashboardHandler(d, "edit_dashboard")
                            }
                            style={{
                              fontSize: 14,
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "20px",
                              color: PRIMARY.P_BLue,
                            }}
                          >
                            Edit Details
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Below Div is used for Dashboard Type name and Dashboard Type Color*/}
                    <div className="flex flex-col justify-center items-start gap-4 self-stretch">
                      <div className="flex items-start gap-[60px]">
                        <div className="flex flex-col items-start gap-2">
                          <div
                            className="self-stretch"
                            style={{
                              fontSize: 10,
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "normal",
                              color: BLACK.B_40,
                            }}
                          >
                            Dashboard Type Name
                          </div>
                          <div
                            className="self-stretch"
                            style={{
                              fontSize: 14,
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "20px",
                            }}
                          >
                            {d.dashboard_type}
                          </div>
                        </div>
                        <div className="flex flex-col items-start gap-2">
                          <div
                            className="self-stretch"
                            style={{
                              fontSize: 10,
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "normal",
                              color: BLACK.B_40,
                            }}
                          >
                            Dashboard Type Color
                          </div>
                          <div
                            className="flex w-[32px] h-[32px] py-1 flex-col justify-center items-start gap-[10px] rounded-[4px]"
                            style={{ backgroundColor: d.color }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      {loading && (
        <div
          className="flex justify-center items-center"
          style={{
            position: "absolute",
            height: "calc(100vh - 486px)",
            width: "calc(100% - 80px)",
          }}
        >
          <CircularProgress />
        </div>
      )}

      {removedata?.remove_toggle && (
        <RemovePlatformModal
          removedata={removedata}
          setRemoveData={setRemoveData}
          loading={loading}
          setLoading={setLoading}
          fetchPlatforms={fetchPlatforms}
          fetchDashboards={fetchDashboards}
        />
      )}

      {addPlatformtoggle && (
        <AddPlatformModal
          view={view}
          setView={setView}
          setAddPlatformToggle={setAddPlatformToggle}
          fetchPlatforms={fetchPlatforms}
          fetchDashboards={fetchDashboards}
          loading={loading}
          setLoading={setLoading}
          dashboardlist={dashboardlist}
        />
      )}

      {editPlatformtoggle && (
        <EditPlatformModal
          view={view}
          setView={setView}
          setEditPlatformToggle={setEditPlatformToggle}
          fetchPlatforms={fetchPlatforms}
          fetchDashboards={fetchDashboards}
          editDashboarddata={editDashboarddata}
          setEditDashboardData={setEditDashboardData}
          loading={loading}
          setLoading={setLoading}
          dashboardlist={dashboardlist}
          editPlatformdata={editPlatformdata}
        />
      )}
    </>
  );
};

export default PlatformdashboardList;
