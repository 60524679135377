import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { BASE_URL, BLACK, PRIMARY, WHITE } from "../../appConstants";
import axios from "axios";
import appError from "../../appError";

type SubDashboard = {
  sub_module_for_dashboard_type: string;
  sub_module_for_dashboard_type_id: string;
};

type SubDashboardsProps = {
  dashboard_id: string;
  userDashboards: SubDashboard[];
};
const SubDashboardsForView: React.FC<SubDashboardsProps> = ({
  dashboard_id,
  userDashboards,
}) => {
  const dataset = new Set(userDashboards && userDashboards.map(dashboard => dashboard.sub_module_for_dashboard_type));
  const [dashboards, setDashboards] = useState<SubDashboard[]>([]);

  const fetchSubDashboards = async () => {
    const token = localStorage.getItem("token");

    let api = `${BASE_URL}user/getSubDashboardByDashboardType?dashboard_id=${dashboard_id}`;

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const result = await axios.get(api, { headers });
        const {dashboard_type_sub_module} = result?.data?.data;
        if (!dashboard_type_sub_module) {
            // console.log("Error in fetching SubDashboards");
            appError("Error in fetching SubDashboards");
        } else {
            setDashboards(dashboard_type_sub_module);
        } 
    } catch (error) {
      appError(error);
      // console.log(error);
      return;
    } finally {
    }
  };
  useEffect(() => {
    fetchSubDashboards();
  }, []);

  return (
    <div className="flex gap-10 items-start">
      {dashboards.length > 0 &&
        dashboards.map((dashboard) => {
            const isChecked = dataset.has(dashboard.sub_module_for_dashboard_type);
          return (
            <label
              className="flex items-center gap-2"
              style={{
                fontSize: 14,
                fontWeight: 400,
                fontStyle: "normal",
                color: "#1C1C1C",
              }}
              key = {dashboard.sub_module_for_dashboard_type_id}
            >
              <input
                type="checkbox"
                name="options"
                value="keywordmapping"
                disabled
                checked ={isChecked}
                style={{ width: 16, height: 16 }}
              />
              {dashboard.sub_module_for_dashboard_type}
            </label>
          );
        })}
    </div>
  );
};

export default SubDashboardsForView;
