import { Pagination } from "@mui/material"
import React from "react";
import { BLACK } from "../../appConstants";

const PaginationComponent: React.FC<{ page: number, limit: number, totalRows: number, onChange: (page: number) => void }> = ({ page, limit, totalRows, onChange }) => {
    //--------------------Pagination Function-----------------------
    const getPaginationInfo = (page: number, limit: number, totalRows: number): string => {
        const start = (page - 1) * limit + 1;
        let end = page * limit;
        if (end > totalRows) {
            end = totalRows;
        }

        return `${start}-${end} of ${totalRows}`;
    }
    //--------------------Pagination Function-----------------------

    return (
        <div className="flex flex-row items-center " >
            <div className="mr-6" style={{ fontSize: 12, fontWeight: 400, lineHeight: 18, color: BLACK.B_80 }}>
                {getPaginationInfo(page, limit, totalRows)}
            </div>
            <Pagination
                page={page}
                count={Math.ceil(totalRows / limit)}
                onChange={(e, page) => onChange(page)}
                shape="rounded" />
        </div>
    )

}

export default PaginationComponent;