import { useNavigate, useParams } from "react-router-dom";
import {
  BASE_URL,
  BLACK,
  PRIMARY,
  STATUS,
  SECONDARY,
} from "../../appConstants";
import axios from "axios";
import { useEffect, useState } from "react";
import { Skeleton } from "@mui/joy";
import SubDashboardsForView from "./SubDashboardsForView";
import appError from "../../appError";
import BrandsAssign from "../commonComponents/BrandsAssign";

type SubDashboard = {
  sub_module_for_dashboard_type: string;
  sub_module_for_dashboard_type_id: string;
};
type Dashboard = {
  color: string;
  dashboard_type_id: string;
  dashboard_type_name: string;
  dashboard_type_sub_module: SubDashboard[];
};
type Platform = {
  platform_id: string;
  platform_name: string;
  dashboard_type: Dashboard[];
};
type Brand_det = {
  brand_id: string;
  brand_name: string;
  platform_array: Platform[];
};
type User = {
  // user_id: string; // UUID (string format)
  full_name: string; // Name of the user
  status: string; // User Status (Active/Inactive)
  user_type: string; // User Type (Manager/Analyst)
  email: string; // Email of User
  organisation: string; // Organisation name of User
  contact_number: string; // Mobile Number of User
  brand_array: Brand_det[];
};
type Brand = {
  brand_id: string;
  brand_name: string;
};

const BrandDetailView = () => {
  const navigate = useNavigate();
  const { user_id } = useParams<{ user_id: string }>();
  const [dataFetchingState, setDataFetchingState] = useState<string>("LOADING");
  const [user, setUser] = useState<User>({} as User);
  type Brand = {
    brand_id: string;
    brand_name: string;
  };
  const [brands, setBrands] = useState<Brand[]>([]);
  const fetchBrands = async () => {
    if (!user_id) return;
    const token = localStorage.getItem("token");
    try {
      const api = `${BASE_URL}user/getBrandByUser?user_id=${user_id}`;
      const headers = { Authorization: `Bearer ${token}` };
      const result = await axios.get(api, { headers });
      const { brand_platform_mapping } = result?.data?.data;
      if (brand_platform_mapping) setBrands(brand_platform_mapping);
    } catch (error) {
      appError(error);
    }
  };
  // For Converting Capital letters of word into normal form
  const convertToNormalForm = (variable: string) => {
    // Capitalize the first letter of each word
    let result = variable
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    return result;
  };

  // FetchUserDetails function for fetching all details of User
  const fetchUserDetails = async () => {
    setDataFetchingState("LOADING");

    const token = localStorage.getItem("token");

    let api = `${BASE_URL}user/UserPlatformDashboardMapping?user_id=${user_id}`;

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const result = await axios.get(api, { headers });

      if (!result?.data?.data[0].result) {
        setDataFetchingState("ERROR");
      } else {
        setDataFetchingState("SUCCESS");

        // Function to check if all properties in an object are null
        const isObjectEmpty = (obj: Brand): boolean => {
          return Object.values(obj).every((value) => value === null);
        };
        const {brand_array, contact_number, email, full_name, organisation, status, user_type} = result?.data?.data[0].result;

        // Filter the brand_array
        let brand_arr  = brand_array.filter((brand : Brand_det) => !isObjectEmpty(brand));
        setUser({
          full_name,
          status,
          user_type,
          email,
          organisation,
          contact_number,
          brand_array: brand_arr
        });
      } // set the data fetching state
    } catch (error) {
      // console.log(error);
      appError(error);
    } finally {
    }
  };
  
  // UseEffect is used for calling fechUserDetails function only on First Render
  useEffect(() => {
    fetchUserDetails();
    fetchBrands();
  }, []);

  return (
    <>
      <div className="px-10 pt-4 pb-10 flex flex-col gap-6">
        {/* This is User List > User Details Sub Component */}
        <div className="flex w-full justify-between items-center">
          <div className="flex flex-row items-center">
            <span
              onClick={() => navigate("/userManagement/userList")}
              className="cursor-pointer"
              style={{
                fontSize: 12,
                fontWeight: 400,
                lineHeight: "18px",
                color: BLACK.B_40,
              }}
            >
              User List
            </span>
            <span className="mx-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M8.40003 7.99999L5.80003 5.39999C5.67781 5.27777 5.6167 5.12221 5.6167 4.93333C5.6167 4.74444 5.67781 4.58888 5.80003 4.46666C5.92225 4.34444 6.07781 4.28333 6.2667 4.28333C6.45559 4.28333 6.61114 4.34444 6.73337 4.46666L9.80003 7.53333C9.8667 7.59999 9.91392 7.67221 9.9417 7.74999C9.96948 7.82777 9.98337 7.9111 9.98337 7.99999C9.98337 8.08888 9.96948 8.17221 9.9417 8.24999C9.91392 8.32777 9.8667 8.39999 9.80003 8.46666L6.73337 11.5333C6.61114 11.6555 6.45559 11.7167 6.2667 11.7167C6.07781 11.7167 5.92225 11.6555 5.80003 11.5333C5.67781 11.4111 5.6167 11.2555 5.6167 11.0667C5.6167 10.8778 5.67781 10.7222 5.80003 10.6L8.40003 7.99999Z"
                  fill="#1C1C1C"
                  fill-opacity="0.4"
                />
              </svg>
            </span>
            <span style={{ fontSize: 12, fontWeight: 500, lineHeight: "18px" }}>
              User Details
            </span>
          </div>
          {dataFetchingState === "SUCCESS" ? (
            <div
              className="flex justify-center items-center gap-2 rounded-[8px]"
              style={{ height: 40 }}
            >
              <div
                className="underline cursor-pointer"
                onClick={() =>
                  navigate(`/userManagement/userDetailEdit/${user_id}`)
                }
                style={{
                  fontSize: 14,
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: 20,
                  color: "#1886FE",
                }}
              >
                Edit Details
              </div>
            </div>
          ) : (
            <div className="h-[40px]"></div>
          )}
        </div>

        {dataFetchingState === "LOADING" && (
          <div className="w-full" style={{ height: 150 }}>
            <Skeleton
              sx={{
                height: 150,
                borderRadius: 16,
                width: "calc(100% - 80px)",
                opacity: 0.5,
              }}
            />
          </div>
        )}
        {dataFetchingState === "LOADING" && (
          <div className="w-full" style={{ height: 689 }}>
            <Skeleton
              sx={{
                height: "calc(100vh - 400px)",
                borderRadius: 16,
                width: "calc(100% - 80px)",
                opacity: 0.5,
              }}
            />
          </div>
        )}

        {dataFetchingState === "SUCCESS" && (
          <div className="flex flex-col items-start gap-6">
            {/* Sub Div 1 : Basic Details of User name, type, email, etc. */}
            {dataFetchingState === "SUCCESS" && (
              <div
                className="flex p-6 flex-col items-start gap-6 self-stretch rounded-[16px] "
                style={{
                  border: `1px solid ${BLACK.B_5}`,
                  boxShadow: "0px 4px 10px 4px rgba(130, 130, 130, 0.05)",
                }}
              >
                <div
                  className="flex items-center gap-2 self-stretch"
                  style={{ width: "100%" }}
                >
                  {/* bg css remaining */}
                  <div
                    style={{
                      fontSize: 18,
                      fontWeight: 600,
                      lineHeight: "18px",
                      color: "#1C1C1C",
                    }}
                  >
                    {/* color remaining */}
                    {user?.full_name}
                  </div>
                  <div
                    className="flex justify-center items-center gap-1 px-2 py-1"
                    style={{
                      borderRadius: "4px",
                      fontSize: 10,
                      fontWeight: 500,
                      lineHeight: "normal",
                      color: user?.status === "ACTIVE" ? "#007F5F" : "#D72C0D",
                      backgroundColor:
                        user?.status === "ACTIVE" ? "#E0F2F1" : "#FFEBEE",
                    }}
                  >
                    {convertToNormalForm(user?.status)}
                  </div>
                </div>

                <div className="flex items-start gap-[60px] self-stretch">
                  <div className="" style={{ width: "4.64%" }}>
                    <div
                      className=""
                      style={{
                        fontSize: 10,
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                        color: "rgba(28,28,28,0.4)",
                      }}
                    >
                      User Type
                    </div>
                    <div
                      className=""
                      style={{
                        fontSize: 14,
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: 1.5,
                        color: "#1C1C1C",
                      }}
                    >
                      {convertToNormalForm(user?.user_type)}
                    </div>
                  </div>
                  <div className="" style={{ width: "16%" }}>
                    <div
                      className=""
                      style={{
                        fontSize: 10,
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                        color: "rgba(28,28,28,0.4)",
                      }}
                    >
                      Email
                    </div>
                    <div
                      className=""
                      style={{
                        fontSize: 14,
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: 1.5,
                        color: "#1C1C1C",
                      }}
                    >
                      {user?.email}
                    </div>
                  </div>
                  <div className="" style={{ width: "8.3%" }}>
                    <div
                      className=""
                      style={{
                        fontSize: 10,
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                        color: "rgba(28,28,28,0.4)",
                      }}
                    >
                      Mobile No.
                    </div>
                    <div
                      className=""
                      style={{
                        fontSize: 14,
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: 1.5,
                        color: "#1C1C1C",
                      }}
                    >
                      {user?.contact_number}
                    </div>
                  </div>
                  <div className="" style={{ width: "4.64%" }}>
                    <div
                      className=""
                      style={{
                        fontSize: 10,
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                        color: "rgba(28,28,28,0.4)",
                      }}
                    >
                      Organisation
                    </div>
                    <div
                      className=""
                      style={{
                        fontSize: 14,
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: 1.5,
                        color: "#1C1C1C",
                      }}
                    >
                      {user?.organisation}
                    </div>
                  </div>
                  <div className="flex flex-col gap-1" style={{ width: "48%" }}>
                    <div
                      className=""
                      style={{
                        fontSize: 10,
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                        color: "rgba(28,28,28,0.4)",
                      }}
                    >
                      Brands Assigned
                    </div>
                    {user_id && <BrandsAssign user_id={user_id} view={true} ipwidth="100%" brands={brands}/>}

                    {/* <div className="flex items-center gap-2 w-full" style={{}}>
                {/* flex 1 0 0 remaining 
                {brands.length > 0 &&
                  brands?.map((brand) => {
                    return (
                      <div
                        className="flex px-2 py-1 justify-center items-center rounded-[4px]"
                        style={{ backgroundColor: "rgba(28,28,28,0.05" }}
                        key={brand?.brand_id}
                      >
                        <div
                          style={{
                            fontSize: 10,
                            fontWeight: 500,
                            fontStyle: "normal",
                            lineHeight: "normal",
                          }}
                        >
                          {/* font family remaining
                          {brand?.brand_name}
                        </div>
                      </div>
                    );
                  })}
                <div
                  style={{
                    fontSize: 10,
                    fontWeight: 500,
                    fontStyle: "normal",
                    lineHeight: "normal",
                  }}
                >
                  {/* font family remaining 
                  +3
                </div>
              </div> */}
                  </div>
                </div>
              </div>
            )}

            {/* Sub Div 2 : For Single Brand Detail*/}
            {dataFetchingState === "SUCCESS" &&
              user?.brand_array?.length > 0 &&
              user?.brand_array?.map((brand) => {
                return (
                  <div
                    className="flex flex-col items-start self-stretch rounded-[16px]"
                    style={{
                      boxShadow: "0px 4px 10px 4px rgba(130, 130, 130, 0.05)",
                    }}
                    key={brand.brand_name}
                  >
                    {/*1. For Brand Heading */}
                    <div
                      className="flex px-[24px] py-4 items-center gap-2 self-stretch rounded-t-[16px]"
                      style={{
                        height: 56,
                        // boxShadow: "0px 4px 10px 4px rgba(130,130,130,0.05)",
                        background: "#F6F7F9",
                      }}
                    >
                      <div
                        className=""
                        style={{
                          fontSize: 16,
                          fontStyle: "normal",
                          fontWeight: 700,
                          lineHeight: 20,
                        }}
                      >
                        {brand.brand_name}
                      </div>
                    </div>
                    {/* 2. For Select Brand Access & all Platforms */}
                    <div className="flex p-6 flex-col items-start gap-6 self-stretch">
                      {/* Select Brand Access Div */}
                      <div
                        className="flex p-6 flex-col items-start gap-6 self-stretch rounded-[16px]"
                        style={{ border: `1px solid ${BLACK.B_10}` }}
                      >
                        <div className="">
                          <div
                            className=""
                            style={{
                              fontSize: 16,
                              fontStyle: "normal",
                              fontWeight: 700,
                            }}
                          >
                            Select Brand Access
                          </div>
                          <div
                            className="self-stretch"
                            style={{
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "18px",
                              color: `${BLACK.B_40}`,
                            }}
                          >
                            Choose access permissions based on your brand
                          </div>
                        </div>
                        <div className="flex items-start gap-10 ">
                          <label className="flex items-center gap-2">
                            <input
                              type="checkbox"
                              name="options"
                              value="keywordmapping"
                              disabled
                              checked
                              style={{ width: 16, height: 16 }}
                            />
                            Keyword Mapping
                          </label>
                          <label className="flex items-center gap-2">
                            <input
                              type="checkbox"
                              name="options"
                              value="crossplatformmapping"
                              disabled
                              checked
                              style={{ width: 16, height: 16 }}
                            />
                            Cross Platform Mapping
                          </label>
                          <label className="flex items-center gap-2">
                            <input
                              type="checkbox"
                              name="options"
                              value="Favouritegroups"
                              disabled
                              checked
                              style={{ width: 16, height: 16 }}
                            />
                            Favourite Groups
                          </label>
                          <label className="flex items-center gap-2">
                            <input
                              type="checkbox"
                              name="options"
                              value="keywordmapping"
                              disabled
                              checked
                              style={{ width: 16, height: 16 }}
                            />
                            Unmapped Data
                          </label>
                        </div>
                      </div>

                      {/*Parent Component of all Plaforms :  all platforms justify-between error */}
                      <div
                        className="w-full flex flex-wrap justify-between gap-6"
                        style={{}}
                      >
                        {brand?.platform_array?.length > 0 &&
                          brand?.platform_array?.map((platform) => {
                            return (
                              // Div for Single Platform
                              <div
                                className="p-6 gap-8 flex flex-col items-start rounded-[16px] flex-grow flex-shrink-0 basis-0"
                                style={{
                                  border: `1px solid ${BLACK.B_10}`,
                                  width: "49%",
                                }}
                                key={platform.platform_id}
                              >
                                <div className="flex items-start gap-6 self-stretch ">
                                  {platform?.platform_name}
                                </div>

                                {/* subdiv1 : For Select Perform Access */}
                                <div className="flex flex-col self-start gap-6 self-stretch">
                                  <div className="flex flex-col items-start gap-[8px] self-stretch">
                                    <div className="flex flex-col items-start self-stretch">
                                      <div
                                        className=""
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 700,
                                          fontStyle: "normal",
                                          color: "#1C1C1C",
                                        }}
                                      >
                                        Select Platform Access
                                      </div>
                                      <div
                                        className=""
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 400,
                                          fontStyle: "normal",
                                          color: `${BLACK.B_40}`,
                                          lineHeight: "18px",
                                        }}
                                      >
                                        Choose access permissions based on your
                                        platform
                                      </div>
                                    </div>
                                    <div className="flex gap-10 items-start">
                                      <label
                                        className="flex items-center gap-2"
                                        style={{
                                          fontSize: 14,
                                          fontWeight: 400,
                                          fontStyle: "normal",
                                          color: "#1C1C1C",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          name="options"
                                          value="keywordmapping"
                                          disabled
                                          checked
                                          style={{ width: 16, height: 16 }}
                                        />
                                        Favourite Groups
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className=""
                                  style={{
                                    border: `1px solid ${BLACK.B_10}`,
                                    width: "100%",
                                  }}
                                ></div>

                                {/* subdiv2 : Parent Div of All Dashboards */}
                                <div className="gap-10 flex flex-col items-start self-stretch">
                                  {platform?.dashboard_type?.length > 0 &&
                                    platform?.dashboard_type?.map(
                                      (dashboard) => {
                                        return (
                                          // Single Dashboard Div
                                          <div className="flex flex-col items-start gap-4 self-stretch">
                                            <div
                                              className="flex justify-center items-center gap-2 py-1 pl-3"
                                              style={{
                                                borderLeft: `4px solid ${dashboard.color}`
                                              }}
                                            >
                                              <div
                                                className=""
                                                style={{
                                                  fontSize: 16,
                                                  fontWeight: 700,
                                                  fontStyle: "normal",
                                                  color: "#1C1C1C",
                                                  lineHeight: "24px",
                                                }}
                                              >
                                                {`${dashboard.dashboard_type_name}`}
                                              </div>
                                            </div>
                                            {/* dashboard_type_id is used because SubDashboards component is mounting before it */}
                                            {dashboard.dashboard_type_id && (
                                              <SubDashboardsForView
                                                dashboard_id={
                                                  dashboard.dashboard_type_id
                                                }
                                                userDashboards={
                                                  dashboard.dashboard_type_sub_module
                                                }
                                              />
                                            )}
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}

        {dataFetchingState === "ERROR" && (
          <div
            className="flex justify-center items-center mt-6 h-[75vh]"
            style={{
              fontSize: 14,
              fontWeight: 500,
              lineHeight: "20px",
              color: BLACK.B_40,
            }}
          >
            Unable to fetch User data please refresh.
          </div>
        )}
      </div>
    </>
  );
};
export default BrandDetailView;
