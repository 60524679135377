
//exports.BASE_URL  = "http://103.112.4.139:3000/";
exports.BASE_URL="https://secure.infytrix.live:3000/";//PROD
// exports.BASE_URL = "https://secure.infytrix.live:3001/"; //UAT
//exports.BASE_URL = "http://localhost:8000/";

//exports.BASE_URL = "https://infytrix-backend-uat-a583b82fe968.herokuapp.com/" //UAT
//exports.BASE_URL = "https://infytrix-backend-5eaf63f8ac6c.herokuapp.com/";
exports.FLASK_EXCEL_API_URL = "https://flask-excel-api-d6a79afc3728.herokuapp.com/"
//exports.FLASK_EXCEL_API_URL = 'http://127.0.0.1:5000/'
//COLOR
exports.PRIMARY = {
    P_Light: "#F7F9FB",
    P_Blue: "#E3F5FF",
    P_Purple: "#E5ECF6",
    P_Purple50: "#E5ECF680",
    P_BLue: "#1886FE",
};
exports.SECONDARY = {
    S_Purple_A: "#95A4FC",
    S_Purple_B: "#C6C7F8",
    S_Blue_A: "#A8C5DA",
    S_Blue_B: "#B1E3FF",
    S_Green_A: "#A1E3CB",
    S_Green_B: "#BAEDBD",
    S_Yellow: "#FFE999",
    S_Red: "#FF6565"
}
exports.BLACK = {
    B_100: "#1C1C1C",
    B_80: "#1C1C1CCC",
    B_40: "#1C1C1C66",
    B_20: "#1C1C1C33",
    B_10: "#1C1C1C1A",
    B_5: "#1C1C1C0D"
}

exports.WHITE = {
    W_100: "#FFFFFF",
    W_80: "#FFFFFFCC",
    W_40: "#FFFFFF66",
    W_20: "#FFFFFF33",
    W_10: "#FFFFFF1A",
    W_5: "#FFFFFF0D"
}
exports.STATUS = {
    S_Green: "#007F5F",
    S_Red: "#D72C0D",
    S_Red10: "rgba(215,44,13,0.1)", // "#D72C0D",
    S_Green10: "rgba(0, 127, 95, 0.1)"//"#007F5F"
}

