import React, { useEffect, useState } from "react";
import { BASE_URL, BLACK } from "../../appConstants";
import axios from "axios";


type BrandPlatform = {
    platform: string;
    platform_id: string;
};

type Brand = {
    brand_id: string;
    brand_name: string;
    company_name: string;
    status: string;
    platform_array: BrandPlatform[];
};

const PlatformList: React.FC<{ brand_id: string }> = ({ brand_id }) => {
    const [dataFetchingState, setDataFetchingState] = useState<string>("LOADING");
    useEffect(() => {
        fetchBrandPlatformMapping();
    }, []);


    const fetchBrandPlatformMapping = async () => {
        setDataFetchingState("LOADING");

        const token = localStorage.getItem("token");

        let api = `${BASE_URL}brand/brandPlatformMapping?brand_id=${brand_id}`;

        const headers = {
            "Authorization": `Bearer ${token}`
        }
        try {
            const result = await axios.get(api, { headers });
            const { brand_platform_mapping } = result?.data?.data;

            setBrandPlatformMapping(brand_platform_mapping);
            setDataFetchingState("SUCCESS");
        } catch (error) {
            setDataFetchingState("ERROR");
        } finally {

        }
    }

    const [brandPlatformMapping, setBrandPlatformMapping] = useState<Brand>();
    return (
        <div className="flex" >
            {
                brandPlatformMapping?.platform_array.map((platform, index) => {
                    let marginLeft = index === 0 ? 0 : 8;
                    return (
                        <div key={index} className="flex justify-center items-center  px-2 py-1" style={{ marginLeft, height: 20, borderRadius: 4, backgroundColor: BLACK.B_5, fontSize: 10, fontWeight: 500 }} >{platform.platform}</div>
                    )
                })
            }
        </div>
    )
}

export default PlatformList;