import { Outlet } from "react-router-dom";

const PlatformManagement: React.FC = () => {
    return (
        <div className="" style={{ height:"calc(100vh - 72px)", overflow:"scroll" , scrollbarWidth:"none"}} >{/* margin top 40px, margin bottom 50px */}
            <Outlet />
        </div>
    )
}

export default PlatformManagement;