import { Button, Input } from "@mui/joy";
import { BASE_URL, BLACK, PRIMARY } from "../../appConstants";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import Joi from "joi";
import appError from "../../appError";
import axios from "axios";
import toast from "react-hot-toast";
import AppLogo from "../appComponents/AppLogo";


type StateObject = {
    first_name: string,
    last_name: string,
    password: string
}

const CreatePassword = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [state, setState] = useState<StateObject>();
    const [loading, setLoding] = useState<boolean>(false);
    const [viewPassword, setViewPassword] = useState(false);

    const onInputChangeHandler = ({ target: { value, name } }: React.ChangeEvent<HTMLInputElement & { name: keyof StateObject }>) => {
        setState(prevState => {
            if (prevState) {
                return { ...prevState, [name]: value }
            } else {
                return { [name]: value } as StateObject
            }
        })
    }




    const onSaveClickHandler = async () => {
        const schema = Joi.object({
            first_name: Joi.string().required().min(3).max(10).label("First Name"),
            last_name: Joi.string().required().min(3).max(10).label("Last Name"),
            password: Joi.string().min(8)  // Minimum length of 9
                .max(50) // Maximum length of 49
                .pattern(new RegExp('(?=.*[a-zA-Z])'))  // At least one alphabet letter
                .pattern(new RegExp('(?=.*[0-9])'))  // At least one number
                .pattern(new RegExp('(?=.*[!@#$%^&*(),.?":{}|<>])'))  // At least one special character
                .required()
                .messages({
                    'string.min': 'Password must be at least 9 characters long',
                    'string.max': 'Password must be less than 50 characters long',
                    'string.pattern.base': 'Password must include at least one letter, one number, and one special character',
                    'any.required': 'Password is required'
                })
            // Joi.string().required().min(6).max(10).label("Password"),
        })
        const validate = schema.validate(state);
        if (validate.error) {
            appError(validate?.error?.toString());
            return;
        }

        try {
            setLoding(true)
            const res = await axios.post(`${BASE_URL}auth/createPassword`,
                validate?.value,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }

            );
            const { message } = res?.data?.data;
            toast.success(message);
            navigate("/login");
        } catch (error) {
            appError(error, "auth");
        } finally {
            setLoding(false)
        }


    }

    const disabledBtn = (!(state?.first_name != "" && state?.last_name != "" && state?.password != "") ? true : loading);
    return (
        <div className="flex flex-col items-center  justify-center h-lvh" >
            <div onClick={() => navigate("/login")} className="p-4 mb-4" style={{ backgroundColor: BLACK.B_80, borderRadius: 18 }} >
                <AppLogo height={60} />
            </div>

            <div style={{ fontSize: 18, fontWeight: 500, width: 400, textAlign: "center", color: BLACK.B_80 }} className="mb-10" >Please provide your First Name, Last Name and create new password.</div>
            <div style={{ border: `1px solid ${PRIMARY.P_BLue}`, borderRadius: 24 }} className="flex flex-col p-10 max-w-fit" >
                <Input
                    // startDecorator={}
                    // endDecorator={}
                    onChange={onInputChangeHandler}
                    name="first_name"
                    className="self-center mb-4"
                    placeholder="First Name"
                    sx={{
                        "--Input-focusedThickness": "0px",
                        width: "240px",
                        height: "60px"
                    }}
                // disabled={loader}
                />
                <Input
                    // startDecorator={}
                    // endDecorator={}
                    onChange={onInputChangeHandler}
                    name="last_name"
                    className="self-center mb-4"
                    placeholder="Last Name"
                    sx={{
                        "--Input-focusedThickness": "0px",
                        width: "240px",
                        height: "60px"
                    }}
                // disabled={loader}
                />
                <Input
                    // startDecorator={}
                    endDecorator={
                        <>
                            {
                                viewPassword ? (
                                    <svg onClick={() => setViewPassword(prevState => !prevState)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={PRIMARY.P_BLue} className="bi bi-eye cursor-pointer" viewBox="0 0 16 16">
                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                    </svg>
                                ) : (
                                    <svg onClick={() => setViewPassword(prevState => !prevState)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-slash cursor-pointer" viewBox="0 0 16 16">
                                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                        <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                                    </svg>
                                )

                            }
                        </>

                    }
                    onChange={onInputChangeHandler}
                    name="password"
                    className="self-center mb-4"
                    placeholder="Password"
                    type={viewPassword ? "text" : "password"}
                    sx={{
                        "--Input-focusedThickness": "0px",
                        width: "240px",
                        height: "60px"
                    }}
                // disabled={loader}
                />
                <Button
                    disabled={disabledBtn}
                    loading={loading}
                    onClick={onSaveClickHandler}
                    endDecorator={<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                        <path d="M17.8172 10.4425L12.1922 16.0675C12.0749 16.1848 11.9159 16.2507 11.75 16.2507C11.5841 16.2507 11.4251 16.1848 11.3078 16.0675C11.1905 15.9503 11.1247 15.7912 11.1247 15.6253C11.1247 15.4595 11.1905 15.3004 11.3078 15.1832L15.8664 10.6253H3.625C3.45924 10.6253 3.30027 10.5595 3.18306 10.4423C3.06585 10.3251 3 10.1661 3 10.0003C3 9.83459 3.06585 9.67562 3.18306 9.55841C3.30027 9.4412 3.45924 9.37535 3.625 9.37535H15.8664L11.3078 4.81753C11.1905 4.70026 11.1247 4.5412 11.1247 4.37535C11.1247 4.2095 11.1905 4.05044 11.3078 3.93316C11.4251 3.81588 11.5841 3.75 11.75 3.75C11.9159 3.75 12.0749 3.81588 12.1922 3.93316L17.8172 9.55816C17.8753 9.61621 17.9214 9.68514 17.9529 9.76101C17.9843 9.83688 18.0005 9.91821 18.0005 10.0003C18.0005 10.0825 17.9843 10.1638 17.9529 10.2397C17.9214 10.3156 17.8753 10.3845 17.8172 10.4425Z" fill="white" />
                    </svg>
                    }
                >Sign Up</Button>
                <div style={{ color: BLACK.B_40, fontSize: 14, fontWeight: 500, textAlign: "center", marginTop: "16px" }}>
                    Already have an account? <span onClick={() => navigate("/login")} style={{ color: PRIMARY.P_BLue, cursor: "pointer" }} >Login</span>
                </div>
            </div>
        </div >
    )
}



export default CreatePassword;