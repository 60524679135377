import React, { useEffect, Dispatch, SetStateAction, useState} from "react";
import { BASE_URL } from "../../appConstants";

type Platform_Logo = {
    logo_url: string;
}
// This is only for Logo of Each Platform 
const PlatformLogo: React.FC<Platform_Logo> = ({ logo_url }) => {
  return (
    <img height={"24px"}
      className="self-stretch"
      src= {`${BASE_URL}brand/platform/logo/${logo_url}`}
      alt={logo_url ? "Platform Logo" : "Unable to fetch Platform Logo"}
    />
  );
};

export default PlatformLogo;