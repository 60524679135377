import axios from "axios";
import { BASE_URL, BLACK, PRIMARY } from "../../appConstants";
import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/joy";
import PlatformLogo from "../PlatformAndDashboardManagement/PlatformLogo";

type Dashboard = {
    brand_power_bi_dashboard_id: string;
    brand_power_bi_dashboard_type_id: string;
    dashboard_type: string;
    workspace_id: string;
    report_id: string;
    color: string;
};

type PlatformDashboardMapping = {
    platform_id: string;
    platform: string;
    platform_logo_url: string;
    dashboard_array: Dashboard[];
};

type PlatformDashboardMappingViewProps = {
    brand_id: string | undefined;
    platform_id: string;
};

const PlatformDashboardMappingView: React.FC<PlatformDashboardMappingViewProps> = ({ brand_id, platform_id }) => {

    const [platformDashboardMapping, setPlatformDashboardMapping] = useState<PlatformDashboardMapping>();
    const [dataFetchingState, setDataFetchingState] = useState<string>("LOADING");
    useEffect(() => {
        fetchPlatformDashboardMapping();
    }, []);
    const fetchPlatformDashboardMapping = async () => {
        const token = localStorage.getItem("token");
        setDataFetchingState("LOADING");
        let api = `${BASE_URL}brand/platformDashboardMapping?brand_id=${brand_id}&platform_id=${platform_id}`;

        const headers = {
            "Authorization": `Bearer ${token}`
        }
        try {
            const result = await axios.get(api, { headers });
            const { platform_dashboard_mapping } = result?.data?.data;
            if (!platform_dashboard_mapping) {
                setDataFetchingState("ERROR");
            } else {
                setDataFetchingState("SUCCESS");
                setPlatformDashboardMapping(platform_dashboard_mapping);
            }

        } catch (error) {

        } finally {

        }
    }

    return (
        <>
            {
                dataFetchingState === "LOADING" && (
                    <div className="w-fit" style={{}} >
                        <Skeleton
                            sx={{ height: 260, borderRadius: 16, width: "calc(100% - 80px)", opacity: 0.5 }}
                        />
                    </div>
                )

            }
            {
                dataFetchingState === "ERROR" && (
                    <div className="flex justify-center items-center  mt-6" style={{ height: 132, fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_40 }} >Unable to fetch platform data please refresh.</div>
                )
            }

            {
                dataFetchingState === "SUCCESS" && (

                    <div style={{ borderRadius: 16 }} >
                        <div className="flex items-center px-6" style={{ height: 56, backgroundColor: "#F6F7F9", borderTopLeftRadius: 16, borderTopRightRadius: 16, fontSize: 16, fontWeight: 700, lineHeight: "20px" }} >
                            {platformDashboardMapping?.platform}
                        </div>
                        <div className="p-6" style={{ boxShadow: "0px 4px 10px 4px rgba(130, 130, 130, 0.05)", borderEndEndRadius: 16, borderEndStartRadius: 16 }} >
                            <div style={{ height: 30 }}>{/**Platform image */}
                                <PlatformLogo 
                                logo_url={platformDashboardMapping?.platform_logo_url as string}/>
                            </div>
                            {
                                platformDashboardMapping?.dashboard_array.map((dashboard, index) => {
                                    return (
                                        <div className="mt-10" style={{ height: 84 }} key={dashboard?.brand_power_bi_dashboard_id}>
                                            <div className="flex items-center" style={{ height: 32 }}  >
                                                <div style={{ width: 4, height: 32, backgroundColor: dashboard?.color }}></div>
                                                <div className="ml-2" style={{ fontSize: 16, fontWeight: 700, lineHeight: "24px" }} >{dashboard?.dashboard_type}</div>
                                            </div>
                                            <div className="flex mt-4" style={{ height: 30 }} >
                                                <div className="flex flex-col justify-between" style={{ marginRight: 60 }} >
                                                    <div style={{ fontSize: 10, fontWeight: 500, color: BLACK.B_40 }} >Workspace ID</div>
                                                    <div style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px" }}>{dashboard?.workspace_id}</div>
                                                </div>
                                                <div className="flex flex-col justify-between">
                                                    <div style={{ fontSize: 10, fontWeight: 500, color: BLACK.B_40 }} >Report ID</div>
                                                    <div style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px" }}>{dashboard?.report_id}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                )
            }
        </>

    );
}

export default PlatformDashboardMappingView;