import { CircularProgress, Input, Tooltip } from "@mui/joy";
import { BASE_URL, BLACK, WHITE } from "../../appConstants";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import axios from "axios";
import appError from "../../appError";
import { set } from "lodash";
import { useDispatch } from "react-redux";
import { updateBrandData } from "../../redux/appDataSlice";
import { useNavigate } from "react-router-dom";
type Brand = {
  brand_id: string;
  brand_name: string;
  stage: string;
  onboarding_type: string;
};
type Platform = {
  platform_id: string;
  platform: string;
};
type Dashboard = {
  brand_power_bi_dashboard_type_id: string;
  dashboard_type: string;
  brand_power_bi_dashboard_id: string;
};
type navigate = {
  openBrandDropdown: Boolean;
  setOpenBrandDropdown: Dispatch<SetStateAction<Boolean>>;
  setNewopen: Dispatch<SetStateAction<boolean>>;
};
const Navigation: React.FC<navigate> = ({
  openBrandDropdown,
  setOpenBrandDropdown,
  setNewopen,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [openBrandDropdown, setOpenBrandDropdown] = useState(false);
  const [openPlatformDropdown, setOpenPlatformDropdown] = useState(false);
  const [openDashboardDropdown, setOpenDashboardDropdown] = useState(false);

  const [selectedBrand, setSelectedBrand] = useState<Brand>({} as Brand);
  const [selectedPlatform, setSelectedPlatform] = useState<Platform>(
    {} as Platform
  );
  const [selectedDashboard, setSelectedDashboard] = useState<Dashboard>(
    {} as Dashboard
  );

  const [brands, setBrands] = useState<Brand[]>([] as Brand[]);
  const [brandDataState, setBrandDataState] = useState<string>();

  const [platforms, setPlatforms] = useState([] as Platform[]);
  const [platformDataState, setPlatformDataState] = useState<string>();

  const [dashboards, setDashboards] = useState<Dashboard[]>([] as Dashboard[]);
  const [dashboardDataState, setDashboardDataState] = useState<string>();

  const [displayPath, setDisplayPath] = useState<string>("Select Dashboard");

  useEffect(() => {
    const brand = JSON.parse(localStorage.getItem("brand") as string);
    const platform = JSON.parse(localStorage.getItem("platform") as string);
    const dashboard = JSON.parse(localStorage.getItem("dashboard") as string);
    if (brand) {
      setSelectedBrand(brand);
    }
    if (platform) {
      setSelectedPlatform(platform);
    }
    if (dashboard) {
      setSelectedDashboard(dashboard);
    }
    if (brand?.brand_name && platform?.platform && dashboard?.dashboard_type) {
      setDisplayPath(
        brand?.brand_name +
          ", " +
          platform?.platform +
          ", " +
          dashboard?.dashboard_type
      );
    } else {
      setDisplayPath("Select Dashboard");
    }

    dispatch(
      updateBrandData({
        brand_id: brand?.brand_id,
        platform_id: platform?.platform_id,
        brand_power_bi_dashboard_id: dashboard?.brand_power_bi_dashboard_id,
        brand_power_bi_dashboard_type_id:
          dashboard?.brand_power_bi_dashboard_type_id,
      })
    );
    fetchBrands();
  }, []);

  useEffect(() => {
    if (selectedBrand?.brand_id) {
      fetchPlatforms();
    }
  }, [selectedBrand?.brand_id]);

  useEffect(() => {
    if (selectedBrand?.brand_id && selectedPlatform?.platform_id) {
      fetchDashboards();
    }
  }, [selectedBrand?.brand_id, selectedPlatform?.platform_id]);

  const fetchBrands = async () => {
    try {
      //  setLoadingBrands(true);
      setBrandDataState("LOADING");
      const token = localStorage.getItem("token");
      const brandsRes = await axios.get(`${BASE_URL}user/getAuthUserBrands`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const brands: Brand[] = brandsRes?.data?.data?.brands;
      if (brands.length) {
        setBrands(brands);
        setBrandDataState("SUCCESS");
      } else {
        setBrandDataState("EMPTY");
      }
    } catch (error) {
      appError(error);
    } finally {
      // setLoadingBrands(false);
    }
  };

  const fetchPlatforms = async () => {
    try {
      //  setLoadingBrands(true);
      setPlatformDataState("LOADING");
      const token = localStorage.getItem("token");
      const brandsRes = await axios.get(
        `${BASE_URL}user/getAuthUserPlatformByBrandId?brand_id=${selectedBrand?.brand_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const platforms: Platform[] = brandsRes?.data?.data?.platforms;
      if (platforms.length) {
        setPlatforms(platforms);
        setPlatformDataState("SUCCESS");
      } else {
        setPlatformDataState("EMPTY");
      }
    } catch (error) {
      // appError(error);
    } finally {
      // setLoadingBrands(false);
    }
  };

  const fetchDashboards = async () => {
    try {
      //  setLoadingBrands(true);
      setDashboardDataState("LOADING");
      const token = localStorage.getItem("token");
      const brandsRes = await axios.get(
        `${BASE_URL}user/getAuthUserDashboardByBrandIdAndPlatformId?brand_id=${selectedBrand?.brand_id}&platform_id=${selectedPlatform?.platform_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const dashboards: Dashboard[] = brandsRes?.data?.data?.dashboards;
      if (dashboards.length) {
        setDashboards(dashboards);
        setDashboardDataState("SUCCESS");
      } else {
        setDashboardDataState("EMPTY");
      }
    } catch (error) {
      // appError(error);
    } finally {
      // setLoadingBrands(false);
    }
  };
  const onBrandChange = (e: any, brand: Brand) => {
    e.stopPropagation();
    setSelectedBrand(brand);
    // dispatch(updateBrandData({ brand_id: brand.brand_id }));
    setSelectedPlatform({} as Platform);
    setOpenPlatformDropdown(true);
    localStorage.setItem("brand", JSON.stringify(brand));
  };
  const onPlatformChange = (e: any, platform: Platform) => {
    e.stopPropagation();
    setSelectedPlatform(platform);
    //dispatch(updateBrandData({ platform_id: platform.platform_id }));
    setOpenDashboardDropdown(true);
    setSelectedDashboard({} as Dashboard);
    localStorage.setItem("platform", JSON.stringify(platform));
  };

  const onDashboardChange = (e: any, dashboard: Dashboard) => {
    e.stopPropagation();
    // dispatch(updateBrandData({ brand_power_bi_dashboard_id: dashboard.brand_power_bi_dashboard_id, brand_power_bi_dashboard_type_id: dashboard.brand_power_bi_dashboard_type_id }));
    dispatch(
      updateBrandData({
        brand_id: selectedBrand.brand_id,
        platform_id: selectedPlatform.platform_id,
        brand_power_bi_dashboard_id: dashboard.brand_power_bi_dashboard_id,
        brand_power_bi_dashboard_type_id:
          dashboard.brand_power_bi_dashboard_type_id,
      })
    );
    setSelectedDashboard(dashboard);
    setDisplayPath(
      selectedBrand?.brand_name +
        ", " +
        selectedPlatform?.platform +
        ", " +
        dashboard.dashboard_type
    );
    localStorage.setItem("dashboard", JSON.stringify(dashboard));
    navigate("/dashboard");
    setOpenBrandDropdown(false);
    setOpenPlatformDropdown(false);
    setOpenDashboardDropdown(false);
  };
  const openbrandHandler = () => {
    setNewopen(false);
    setOpenBrandDropdown((prevState) => {
      if (prevState) {
        setOpenPlatformDropdown(false);
        setOpenDashboardDropdown(false);
      }
      return !prevState;
    });
  };

  // const displayPath = (selectedBrand?.brand_name ? selectedBrand?.brand_name : "Select Brand") + ", " + (selectedPlatform?.platform ? selectedPlatform?.platform : "Select Platform") + ", " + (selectedDashboard?.dashboard_type ? selectedDashboard?.dashboard_type : "Select Dashboard");

  return (
    <div>
      <div
        onClick={openbrandHandler}
        className="flex justify-between"
        style={{
          fontSize: 14,
          fontWeight: 400,
          lineHeight: "20px",
          width: "200px",
          height: "40px",
          backgroundColor: WHITE.W_100,
          color: BLACK.B_100,
          cursor: "pointer",
          borderRadius: 8,
          padding: "10px 16px",
        }}
        // endDecorator={
        //     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        //         <path d="M9.99984 12.4792C9.88873 12.4792 9.78456 12.4618 9.68734 12.4271C9.59012 12.3924 9.49984 12.3333 9.4165 12.25L5.58317 8.41668C5.43039 8.2639 5.354 8.06945 5.354 7.83334C5.354 7.59723 5.43039 7.40279 5.58317 7.25001C5.73595 7.09723 5.93039 7.02084 6.1665 7.02084C6.40262 7.02084 6.59706 7.09723 6.74984 7.25001L9.99984 10.5L13.2498 7.25001C13.4026 7.09723 13.5971 7.02084 13.8332 7.02084C14.0693 7.02084 14.2637 7.09723 14.4165 7.25001C14.5693 7.40279 14.6457 7.59723 14.6457 7.83334C14.6457 8.06945 14.5693 8.2639 14.4165 8.41668L10.5832 12.25C10.4998 12.3333 10.4096 12.3924 10.3123 12.4271C10.2151 12.4618 10.1109 12.4792 9.99984 12.4792Z" fill="#1C1C1C" fill-opacity="0.4" />
        //     </svg>
        // }
        // onChange={onChangeHandler}
      >
        <div
          style={{
            width: 124,
            textWrap: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Tooltip
            title={displayPath}
            placement="left-start"
            onClick={() => console.log("Working")}
          >
            <span style={{
                fontSize:"14px",
                fontWeight:"400",
                lineHeight:"20px",
                color:BLACK.B_40}}>{displayPath}</span>
          </Tooltip>
        </div>
        <div className="w-fit">
          {openBrandDropdown ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10.0001 9L6.75008 12.25C6.5973 12.4028 6.40286 12.4792 6.16675 12.4792C5.93064 12.4792 5.73619 12.4028 5.58341 12.25C5.43064 12.0972 5.35425 11.9028 5.35425 11.6667C5.35425 11.4306 5.43064 11.2361 5.58341 11.0833L9.41675 7.25C9.58342 7.08333 9.77786 7 10.0001 7C10.2223 7 10.4167 7.08333 10.5834 7.25L14.4167 11.0833C14.5695 11.2361 14.6459 11.4306 14.6459 11.6667C14.6459 11.9028 14.5695 12.0972 14.4167 12.25C14.264 12.4028 14.0695 12.4792 13.8334 12.4792C13.5973 12.4792 13.4029 12.4028 13.2501 12.25L10.0001 9Z"
                fill="#1C1C1C"
                fill-opacity="0.4"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M9.99984 12.4792C9.88873 12.4792 9.78456 12.4618 9.68734 12.4271C9.59012 12.3924 9.49984 12.3333 9.4165 12.25L5.58317 8.41668C5.43039 8.2639 5.354 8.06945 5.354 7.83334C5.354 7.59723 5.43039 7.40279 5.58317 7.25001C5.73595 7.09723 5.93039 7.02084 6.1665 7.02084C6.40262 7.02084 6.59706 7.09723 6.74984 7.25001L9.99984 10.5L13.2498 7.25001C13.4026 7.09723 13.5971 7.02084 13.8332 7.02084C14.0693 7.02084 14.2637 7.09723 14.4165 7.25001C14.5693 7.40279 14.6457 7.59723 14.6457 7.83334C14.6457 8.06945 14.5693 8.2639 14.4165 8.41668L10.5832 12.25C10.4998 12.3333 10.4096 12.3924 10.3123 12.4271C10.2151 12.4618 10.1109 12.4792 9.99984 12.4792Z"
                fill="#1C1C1C"
                fill-opacity="0.4"
              />
            </svg>
          )}
        </div>
      </div>

      {openBrandDropdown && (
        <div
          style={{
            zIndex: 997,
            //maxHeight: 400,
            paddingTop: "8px",
            paddingBottom: "8px",
            width: 200,
            backgroundColor: WHITE.W_100,
            borderRadius: 8,
            marginTop: 2,
            position: "absolute",
            boxShadow: "0px 4px 10px 4px rgba(130, 130, 130, 0.10)",
          }}
        >
          {brands?.map((brand) => {
            return (
              <div
                key={brand?.brand_id}
                className="flex justify-between items-center px-4 py-2 cursor-pointer onHoverBlack5"
                style={{
                  backgroundColor:
                    selectedBrand?.brand_id === brand?.brand_id
                      ? BLACK.B_5
                      : "",
                  // backgroundColor: (selectedBrand?.brand_id === brand?.brand_id) ? BLACK.B_5 : "",
                  height: 34,
                  width: 200,
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: "20px",
                  position: "relative",
                }}
                onClick={(e) => onBrandChange(e, brand)}
              >
                {brand?.brand_name}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10.4999 9.99999L7.24992 6.74999C7.09714 6.59721 7.02075 6.40277 7.02075 6.16666C7.02075 5.93055 7.09714 5.7361 7.24992 5.58332C7.4027 5.43055 7.59714 5.35416 7.83325 5.35416C8.06936 5.35416 8.26381 5.43055 8.41659 5.58332L12.2499 9.41666C12.3333 9.49999 12.3923 9.59027 12.427 9.68749C12.4617 9.78471 12.4791 9.88888 12.4791 9.99999C12.4791 10.1111 12.4617 10.2153 12.427 10.3125C12.3923 10.4097 12.3333 10.5 12.2499 10.5833L8.41659 14.4167C8.26381 14.5694 8.06936 14.6458 7.83325 14.6458C7.59714 14.6458 7.4027 14.5694 7.24992 14.4167C7.09714 14.2639 7.02075 14.0694 7.02075 13.8333C7.02075 13.5972 7.09714 13.4028 7.24992 13.25L10.4999 9.99999Z"
                    fill="#1C1C1C"
                    fill-opacity="0.4"
                  />
                </svg>

                {/* Platform DropDown */}
                {openPlatformDropdown &&
                  platformDataState != "EMPTY" &&
                  brand?.brand_id === selectedBrand?.brand_id && (
                    <div
                      style={{
                        zIndex: 999,
                        // maxHeight: 200,
                        width: 200,
                        backgroundColor: WHITE.W_100,
                        borderRadius: 8,
                        position: "absolute",
                        top: 0,
                        left: -200,
                        boxShadow: "0px 4px 10px 4px rgba(130, 130, 130, 0.10)",
                      }}
                    >
                      {platformDataState === "SUCCESS" && (
                        <div className="flex w-[200px] py-[8px] flex-col items-center gap-2">
                          <div className="flex flex-col items-start self-stretch">
                            {platforms?.map((platform) => {
                              return (
                                <div
                                  key={platform?.platform_id}
                                  className="flex justify-between items-center px-4 py-2 cursor-pointer onHoverBlack5"
                                  style={{
                                    // backgroundColor: (selectedPlatform?.platform_id === platform?.platform_id) ? BLACK.B_5 : "",
                                    backgroundColor:
                                      selectedPlatform?.platform_id ===
                                      platform?.platform_id
                                        ? BLACK.B_5
                                        : "",
                                    height: 34,
                                    width: 200,
                                    fontSize: 14,
                                    fontWeight: 400,
                                    lineHeight: "20px",
                                    position: "relative",
                                  }}
                                  onClick={(e) => onPlatformChange(e, platform)}
                                >
                                  {platform?.platform}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.4999 9.99999L7.24992 6.74999C7.09714 6.59721 7.02075 6.40277 7.02075 6.16666C7.02075 5.93055 7.09714 5.7361 7.24992 5.58332C7.4027 5.43055 7.59714 5.35416 7.83325 5.35416C8.06936 5.35416 8.26381 5.43055 8.41659 5.58332L12.2499 9.41666C12.3333 9.49999 12.3923 9.59027 12.427 9.68749C12.4617 9.78471 12.4791 9.88888 12.4791 9.99999C12.4791 10.1111 12.4617 10.2153 12.427 10.3125C12.3923 10.4097 12.3333 10.5 12.2499 10.5833L8.41659 14.4167C8.26381 14.5694 8.06936 14.6458 7.83325 14.6458C7.59714 14.6458 7.4027 14.5694 7.24992 14.4167C7.09714 14.2639 7.02075 14.0694 7.02075 13.8333C7.02075 13.5972 7.09714 13.4028 7.24992 13.25L10.4999 9.99999Z"
                                      fill="#1C1C1C"
                                      fill-opacity="0.4"
                                    />
                                  </svg>

                                  {/* Dashboard DropDown */}
                                  {openDashboardDropdown &&
                                    dashboardDataState != "EMPTY" &&
                                    platform.platform_id ===
                                      selectedPlatform?.platform_id && (
                                      <div
                                        className="flex flex-col items-center w-[200px] gap-[8px] py-[8px] "
                                        style={{
                                          zIndex: 998,
                                          maxHeight: 200,
                                          width: 200,
                                          backgroundColor: WHITE.W_100,
                                          borderRadius: 8,
                                          position: "absolute",
                                          top: 0,
                                          left: -200,
                                          boxShadow:
                                            "0px 4px 10px 4px rgba(130, 130, 130, 0.10)",
                                        }}
                                      >
                                        {dashboardDataState === "SUCCESS" &&
                                          dashboards?.map((dashboard) => {
                                            return (
                                              <div
                                                key={
                                                  dashboard?.brand_power_bi_dashboard_type_id
                                                }
                                                className="flex justify-between items-center px-4 py-2 cursor-pointer onHoverBlack5"
                                                style={{
                                                  height: 34,
                                                  width: 200,
                                                  fontSize: 14,
                                                  fontWeight: 400,
                                                  lineHeight: "20px",
                                                  position: "relative",
                                                }}
                                                onClick={(e) =>
                                                  onDashboardChange(
                                                    e,
                                                    dashboard
                                                  )
                                                }
                                              >
                                                {dashboard?.dashboard_type}
                                              </div>
                                            );
                                          })}
                                        {/* Line */}
                                        {/* <div
                                          className=" w-[85%]"
                                          style={{
                                            border:
                                              "1px solid rgba(28,28,28,0.05)",
                                          }}
                                        ></div> */}
                                        {/* Static Platform Level Access */}
                                        {/* <div className="flex flex-col items-start self-stretch">
                                          <div className="flex h-[34px] px-4 py-2 items-center gap-[10px] self-stretch onHoverBlack5">
                                            <div
                                              style={{
                                                fontSize: "12px",
                                                fontWeight: "400",
                                                lineHeight: "18px",
                                                color: BLACK.B_80,
                                              }}
                                            >
                                              Mapping for onboarding
                                            </div>
                                          </div>
                                          
                                        </div> */}
                                        {dashboardDataState === "LOADING" && (
                                          <div
                                            className="flex justify-center items-center"
                                            style={{ height: 34, width: 200 }}
                                          >
                                            <CircularProgress size="sm" />
                                          </div>
                                        )}
                                      </div>
                                    )}
                                </div>
                              );
                            })}
                          </div>
                          {/* Line */}
                          {/* <div
                            className=" w-[85%]"
                            style={{
                              border: "1px solid rgba(28,28,28,0.05)",
                            }}
                          ></div> */}
                          {/* Static Platform Level Access */}
                          {/* <div className="flex flex-col items-start self-stretch">
                            <div className="flex h-[34px] px-4 py-2 items-center gap-[10px] self-stretch onHoverBlack5">
                              <div
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  lineHeight: "18px",
                                  color: BLACK.B_80,
                                }}
                              >
                                Keyword Mapping
                              </div>
                            </div>
                            <div className="flex h-[34px] px-4 py-2 items-center gap-[10px] self-stretch onHoverBlack5">
                              <div
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  lineHeight: "18px",
                                  color: BLACK.B_80,
                                }}
                              >
                                Cross Platform Mapping
                              </div>
                            </div>
                            <div className="flex h-[34px] px-4 py-2 items-center gap-[10px] self-stretch onHoverBlack5">
                              <div
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  lineHeight: "18px",
                                  color: BLACK.B_80,
                                }}
                              >
                                Favourite Groups
                              </div>
                            </div>
                            <div className="flex h-[34px] px-4 py-2 items-center gap-[10px] self-stretch onHoverBlack5">
                              <div
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  lineHeight: "18px",
                                  color: BLACK.B_80,
                                }}
                              >
                                Unmapped data
                              </div>
                            </div>
                          </div> */}
                        </div>
                      )}

                      {platformDataState === "LOADING" && (
                        <div
                          className="flex justify-center items-center"
                          style={{ height: 34, width: 200 }}
                        >
                          <CircularProgress size="sm" />
                        </div>
                      )}
                    </div>
                  )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Navigation;
