import {
  Button,
  CircularProgress,
  Input,
  Modal,
  Option,
  Select,
  selectClasses,
  styled,
  SvgIcon,
  Textarea,
} from "@mui/joy";
import { BASE_URL, BLACK, PRIMARY, WHITE } from "../../appConstants";
import React, {
  ChangeEvent,
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import axios from "axios";
import toast from "react-hot-toast";
import appError from "../../appError";
import Joi, { boolean } from "joi";
import { useNavigate } from "react-router-dom";
import MultiSelect from "../commonComponents/MultiSelect";
import SelectDashboardEditModal from "./SelectDashboardEditModal";

type SubDashboard = {
  sub_module_for_dashboard_type: string;
  sub_module_for_dashboard_type_id: string;
}
type Dashboard = {
  color: string;
  dashboard_type_id: string;
  dashboard_type_name: string;
  dashboard_type_sub_module?: SubDashboard[];
};

type Platform = {
  platform_id: string;
  platform_name: string;
  dashboard_type: Dashboard[];
};

type Brand_det = {
  brand_id: string;
  brand_name: string; // This property is in Brand_det, but not in EditBrand_det
  platform_array: Platform[];
};

type User = {
  full_name: string;
  status: string;
  user_type: string;
  email: string;
  organisation: string;
  contact_number: string;
  brand_array: Brand_det[];
};
type Brand = {
  brand_id: string;
  brand_name: string;
}
type UserPlatformEditTypeProps = {
  setToggleModal: Dispatch<SetStateAction<boolean>>;
  setRefresh: Dispatch<SetStateAction<boolean>>;
  view: string;
  setView: Dispatch<SetStateAction<string>>;
  user_id: string;
  user: User;
  setUser: Dispatch<SetStateAction<User>>;
  brands : Brand[];
  setBrands: Dispatch<SetStateAction<Brand[]>>;
  setSelectedBrandId: Dispatch<SetStateAction<string>>;
  selectedBrandId: string;
  selPlatforms: string[];
  setSelPlatforms: Dispatch<SetStateAction<string[]>>;
  setDataChanged: Dispatch<SetStateAction<boolean>>;
};
type AddBrandTypeProps = {
  setView: Dispatch<SetStateAction<string>>;
  setUser: Dispatch<SetStateAction<User>>;
  selectedBrandId: string;
  setSelectedBrandId: Dispatch<SetStateAction<string>>;
  cancelToggle: () => void;
}
type AddPlatformTypeProps = {
  setUser: Dispatch<SetStateAction<User>>;
  setView: Dispatch<SetStateAction<string>>;
  user_id: string;
  selectedBrandId: string;
  setSelPlatforms : Dispatch<SetStateAction<string[]>>;
  cancelToggle: () => void;
} 
type AddDashboardTypeProps = {
  user: User;
  setUser: Dispatch<SetStateAction<User>>;
  setlocaluser: Dispatch<SetStateAction<User>>;
  setBrands: Dispatch<SetStateAction<Brand[]>>;
  setView: Dispatch<SetStateAction<string>>;
  selectedBrandId: string;
  selPlatforms: string[];
  selectedbrandname: string;
  cancelToggle: () => void;
}

const UserPlatformEditModal: React.FC<UserPlatformEditTypeProps> = ({
  setToggleModal,
  view,
  setView,
  user_id,
  user,
  setUser,
  brands,
  setBrands,
  setSelectedBrandId,
  selectedBrandId,
  selPlatforms,
  setSelPlatforms,
  setDataChanged
}) => {
  // const brandId = useSelector((state: any) => state.appData.brand_id);

  const [localuser, setLocaluser] = useState<User>(user);
  
  //Dummy Static Data
  type Brandaccess = {
    permission_name: string;
    checked: boolean;
  };
  const [brandAccessPermissions, setBrandAccessPermissions] = useState<
    Brandaccess[]
  >([
    {
      permission_name: "Keyword Mapping",
      checked: true,
    },
    {
      permission_name: "Cross Platform Mapping",
      checked: false,
    },
    {
      permission_name: "Favourite Groups",
      checked: true,
    },
    {
      permission_name: "Unmapped data",
      checked: true,
    },
  ]);
  const cancelToggle = ()=>{
    setSelectedBrandId("");
    setLocaluser(user);
    setToggleModal(false);
  }
  // const [selPlatforms, setSelPlatforms] = useState<string[]>([]); // For filtering platfroms in Add Dashboard 
  const [selectedbrandname, setSelectedBrandName] = useState<string>("");

  const AddBrandComponent: React.FC<AddBrandTypeProps> = ({
    setView,
    setUser,
    selectedBrandId,
    setSelectedBrandId,
    cancelToggle
  }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    // Handle the change event for the dropdown
    const handleDropdownChange = (event: any, value: any) => {
      setSelectedBrandId(value); // Update the state with the selected value
    };
    type SuggestBrand = {
      brand_id: string;
      brand_name: string;
    };
    const [suggestBrands, setSuggestBrands] = useState<SuggestBrand[]>([]);

    const fetchsuggestbrands = async () => {
      const token = localStorage.getItem("token");
      try {
        let api = `${BASE_URL}user/getBrandForAccess`;
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const result = await axios.get(api, { headers });
        const { brand_array } = result?.data?.data;
        // Using Hashmap 
        const initialBrandMap = new Map<string,boolean>();
        brands.forEach(brand => {
          initialBrandMap.set(brand.brand_id, true);
        })
        const filteredData : SuggestBrand[] = brand_array.filter((b: Brand) => !initialBrandMap.get(b.brand_id));
        setSuggestBrands(filteredData);
      } catch (error) {
        appError(error);
      } finally {
      }
    };
    useEffect(() => {
      fetchsuggestbrands();
    }, []);
    
    const onProceedClick = () => {
      const schema = Joi.string()
        .guid({ version: ["uuidv4", "uuidv5"] })
        .required()
        .label("Brand Name");
      const validate = schema.validate(selectedBrandId);
      if (validate.error) {
        // console.log(validate.error.message);
        appError("Please Select Brand");
        return;
      } else {
        const p = suggestBrands.find(
          (p) => p.brand_id === selectedBrandId
        );
        if (!p) {
          appError("Brand not found");
          return;
        }
        setSelectedBrandName(p.brand_name);
        setUser((prevMain) => ({
          ...prevMain,
          brand_array: [
            ...prevMain.brand_array,
            {
              brand_id: selectedBrandId,
              brand_name: p.brand_name,
              platform_array: [],
            },
          ],
        }));
        setView("add_platform");
      }
    };
    return (
      <>
        <div className="flex flex-col items-start self-stretch gap-6 ">
          <div className="flex flex-col w-full">
            <div
              className="self-stretch"
              style={{
                color: `${BLACK.B_100}`,
                fontSize: 16,
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "24px",
              }}
            >
              Add Brands for Access
            </div>
            <div
              className="self-stetch"
              style={{
                color: `${BLACK.B_40}`,
                fontSize: 12,
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "18px",
              }}
            >
              Assign brands and define their access levels
            </div>
          </div>

          <div className="flex gap-4 items-start self-stretch">
            <div className="w-[50%] flex flex-col gap-[4px]">
              <div
                className="flex flex-col h-[20px] justify-center self-stretch"
                style={{
                  fontSize: 14,
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20px",
                  color:"#5B5B5B"

                }}
              >
                Select Brand
              </div>
              <Select
                onChange={handleDropdownChange}
                placeholder="Please Select"
                name="selectedBrandId"
                value={selectedBrandId}
                indicator={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.99984 12.4791C9.88873 12.4791 9.78456 12.4618 9.68734 12.4271C9.59012 12.3923 9.49984 12.3333 9.4165 12.25L5.58317 8.41665C5.43039 8.26387 5.354 8.06942 5.354 7.83331C5.354 7.5972 5.43039 7.40276 5.58317 7.24998C5.73595 7.0972 5.93039 7.02081 6.1665 7.02081C6.40262 7.02081 6.59706 7.0972 6.74984 7.24998L9.99984 10.5L13.2498 7.24998C13.4026 7.0972 13.5971 7.02081 13.8332 7.02081C14.0693 7.02081 14.2637 7.0972 14.4165 7.24998C14.5693 7.40276 14.6457 7.5972 14.6457 7.83331C14.6457 8.06942 14.5693 8.26387 14.4165 8.41665L10.5832 12.25C10.4998 12.3333 10.4096 12.3923 10.3123 12.4271C10.2151 12.4618 10.1109 12.4791 9.99984 12.4791Z"
                      fill="#1C1C1C"
                      fill-opacity="0.4"
                    />
                  </svg>
                }
                slotProps={{
                  listbox: {},
                }}
                sx={{
                  "--Input-focusedThickness": "0px",
                  height: "52px",
                  color: BLACK.B_80,
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: "20px",
                  backgroundColor: WHITE.W_100,
                  boxShadow: "none",
                  border: `1px solid ${BLACK.B_5}`,
                  [`& .${selectClasses.indicator}`]: {
                    transition: "0.2s",
                    [`&.${selectClasses.expanded}`]: {
                      transform: "rotate(-180deg)",
                    },
                  },
                  ":hover": {
                    backgroundColor: WHITE.W_100,
                  },
                }}
              >
                {suggestBrands.length > 0 &&
                  suggestBrands.map((brand) => {
                    return (
                      <Option value={brand.brand_id} key={brand.brand_id}>
                        {" "}
                        {brand.brand_name}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          </div>

          <div className="flex gap-4 items-start self-stretch">
            <div className="w-[100%] flex flex-col gap-[8px] items-start self-stretch">
              <div
                className="flex flex-col h-[20px] justify-center self-stretch"
                style={{
                  fontSize: 14,
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20px",
                  color:"#1C1C1C"
                }}
              >
                Select Brand Access
              </div>
              <div className="flex items-start justify-between self-stretch rounded-[16px]">
                {brandAccessPermissions.length > 0 &&
                  brandAccessPermissions.map((permission) => {
                    return (
                      <label
                        className="flex items-center gap-1"
                        style={{
                          fontSize: 14,
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "20px",
                          color: `${BLACK.B_100}`,
                          paddingTop:"8px",
                            paddingBottom:"8px"
                        }}
                        key={permission.permission_name}
                      >
                        <input
                          type="checkbox"
                          name="options"
                          value="keywordmapping"
                          // disabled
                          checked
                          style={{ width: 16, height: 16,accentColor: "black",
                            borderRadius:"4px", }}
                          // currently remaining from here
                        />
                        {permission?.permission_name}
                      </label>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-start justify-center gap-4 self-stretch">
          <Button
            color="neutral"
            sx={{
              height: "48px",
              width: "50%",
              backgroundColor: BLACK.B_5,
              color: BLACK.B_100,
              ":hover": {
                backgroundColor: BLACK.B_20,
              },
              padding: "8px 16px",
            }}
            // className="flex px-4 py-2 justify-center items-center gap-[8px]"
            onClick={cancelToggle}
          >
            {/* flex 1 0 0 remaining */}
            Cancel
          </Button>
          <Button
            onClick={onProceedClick}
            sx={{
              height: "48px",
              width: "50%",
              backgroundColor: PRIMARY.P_BLue,
              padding: "8px 16px",
            }}
            // loading={loading}
          >
            Proceed
          </Button>
        </div>
      </>
    );
  };

  const AddPlatformComponent: React.FC<AddPlatformTypeProps> = ({ setUser, setView, user_id, selectedBrandId, setSelPlatforms,cancelToggle}) => {
    const [selectedPlatforms, setSelectedPlatforms] = useState<Set<string>>(
      new Set([])
    );
    type Platform = {
      platform_id: string;
      platform: string;
    };
    const [platforms, setPlatforms] = useState<Platform[]>([]);
    let platformList = platforms.map((platform) => {
      return { id: platform.platform_id, label: platform.platform };
    });
    const onChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
      let set = new Set(selectedPlatforms);
      if (e.target.checked) {
        set.add(id);
      } else {
        if (set.has(id)) {
          set.delete(id);
        }
      }
      setSelectedPlatforms(set);
    };

    const onProceedClick = () => {
      const platform_ids = Array.from(selectedPlatforms);
      if (platform_ids.length === 0) {
        appError("Please select a Platform");
        return;
      }
      setSelPlatforms(platform_ids);
      const updatedData = { ...localuser };
      updatedData.brand_array.forEach((brand) => {
      if (brand.brand_id === selectedBrandId) {
        selectedPlatforms.forEach((id) => {
          const platform = platformList.find((platform) => platform.id === id);
          if (platform) {
            brand?.platform_array.push({
              platform_id: id,
              platform_name: platform.label,
              dashboard_type: [],
            });
          }
        });
      }
    });
      setUser(updatedData);
      setView("add_dashboard");
    };

    const fetchsuggestplatforms = async () => {
      const token = localStorage.getItem("token");
      try {
        let api = `${BASE_URL}user/getPlatformForAccess?brand_id=${selectedBrandId}`;
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const result = await axios.get(api, { headers });
        const { platform_array } = result?.data?.data;
        // Using Hashmap
        const brand = user.brand_array.find(b => b.brand_id === selectedBrandId);
        if(brand?.platform_array.length === 0){
          setPlatforms(platform_array);
        }
        else{
        const platformIdmap = new Map<string,boolean>();
        brand?.platform_array.forEach(platform => {
          platformIdmap.set(platform.platform_id, true);
        })
        const filteredData = platform_array.filter((p: {platform_id : string, platform: string}) => !platformIdmap.get(p.platform_id));
        setPlatforms(filteredData);
      }
      } catch (error) {
        appError(error);
      } finally {
      }
    };
    useEffect(() => {
      fetchsuggestplatforms();
    }, []);
    return (
      <>
        <div className="flex flex-col items-start self-stretch gap-6 ">
          <div className="flex flex-col w-full">
            <div
              className="self-stretch"
              style={{
                color: `${BLACK.B_100}`,
                fontSize: 16,
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "24px",
              }}
            >
              Assign Platforms for Access
            </div>
            <div
              className="self-stetch"
              style={{
                color: `${BLACK.B_40}`,
                fontSize: 12,
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "18px",
              }}
            >
              Choose the platforms and select the access level for each
            </div>
          </div>

          <div className="flex gap-4 items-start self-stretch">
            <div className="w-[50%] flex flex-col gap-[4px]">
              <div
                className="flex flex-col h-[20px] justify-center self-stretch"
                style={{
                  fontSize: 14,
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20px",
                  color:"#5B5B5B"
                }}
              >
                Select Platform
              </div>
              <MultiSelect
                children={platformList}
                selected={selectedPlatforms}
                onChange={onChange}
                placeholder="Please Select"
              />
            </div>
          </div>
        </div>

        <div className="flex items-start justify-center gap-4 self-stretch">
          <Button
            color="neutral"
            sx={{
              height: "48px",
              width: "50%",
              backgroundColor: BLACK.B_5,
              color: BLACK.B_100,
              ":hover": {
                backgroundColor: BLACK.B_20,
              },
              padding: "8px 16px",
            }}
            // className="flex px-4 py-2 justify-center items-center gap-[8px]"
            onClick={cancelToggle}
          >
            {/* flex 1 0 0 remaining */}
            Cancel
          </Button>
          <Button
            onClick={onProceedClick}
            sx={{
              height: "48px",
              width: "50%",
              backgroundColor: PRIMARY.P_BLue,
              padding: "8px 16px",
            }}
            // loading={loading}
          >
            Proceed
          </Button>
        </div>
      </>
    );
  };

  const AddDashboardComponent: React.FC<AddDashboardTypeProps> = ({ user, setUser,setBrands, setlocaluser, setView,selectedBrandId, selectedbrandname, selPlatforms,cancelToggle }) => {

    const foundBrand = localuser.brand_array.find((brand) => brand.brand_id === selectedBrandId);

    const [platform_arr, setPlatform_arr] = useState<Platform[]>(foundBrand ? foundBrand?.platform_array.filter(platform => selPlatforms.includes(platform.platform_id)) : []);

    const merge = (main: User, platform_arr: Platform[]) => {
      return {
        ...main,
        brand_array: main.brand_array.map((brand) => ({
          ...brand,
          platform_array: brand.platform_array.map((platform) => {
            const newPlatform = platform_arr.find(
              (p) => p.platform_id === platform.platform_id
            );
            if (newPlatform) {
              // Create a Set to hold existing dashboard_type_ids to avoid duplicates
              const existingDashboardIds = new Set(
                platform.dashboard_type.map((d) => d.dashboard_type_id)
              );

              // Filter new dashboard types to remove duplicates
              const newDashboardTypes = newPlatform.dashboard_type.filter(
                (d) => !existingDashboardIds.has(d.dashboard_type_id)
              );

              return {
                ...platform,
                dashboard_type: [
                  ...platform.dashboard_type,
                  ...newDashboardTypes,
                ], // Merge arrays
              };
            }
            return platform;
          }),
        })),
      };
    };
    const saveuser = async () => {
      const SubDashboardTypeSchema = Joi.object({
        sub_module_for_dashboard_type_id: Joi.string()
          .guid({ version: "uuidv4" })
          .required(),
        sub_module_for_dashboard_type: Joi.string().label("Sub Dashboard Name"),
      });
      const dashboardTypeSchema = Joi.object({
        color: Joi.string().required(),
        dashboard_type_id: Joi.string()
          .guid({ version: ["uuidv4", "uuidv5"] })
          .required(),
        dashboard_type_name: Joi.string().required(),
        dashboard_type_sub_module: Joi.array()
        .items(SubDashboardTypeSchema),
      });
      const schema = Joi.array()
        .min(1)
        .items(
          Joi.object({
            platform_id: Joi.string()
              .guid({ version: ["uuidv4", "uuidv5"] })
              .required(),
            dashboard_type: Joi.array()
              .min(1)
              .items(dashboardTypeSchema)
              .required()
              .label("Dashboard"),
            platform_name: Joi.string()
              .min(3)
              .required()
              .label("Platform Name"),
          })
        );
      const validate = schema.validate(platform_arr);
      if (validate.error) {
        // console.log(validate.error);
        appError("Please select Atleast 1 Dashboard For Platform");
        return;
      }
      merge(localuser, platform_arr);
      setlocaluser(localuser);
      setDataChanged(true);
      if(selectedbrandname){
        setBrands([...brands,{brand_id:selectedBrandId, brand_name: selectedbrandname}]);
      }
      setUser(localuser);
      setView("");
      setToggleModal(false);
    };
    return (
      <>
        <div className="flex flex-col items-start self-stretch gap-6 ">
          <div className="flex flex-col w-full">
            <div
              className="self-stretch"
              style={{
                color: `${BLACK.B_100}`,
                fontSize: 16,
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "24px",
              }}
            >
              Assign Dashboard for Access
            </div>
            <div
              className="self-stetch"
              style={{
                color: `${BLACK.B_40}`,
                fontSize: 12,
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "18px",
              }}
            >
              Choose the platforms and select the access level for each
            </div>
          </div>

          <div className="flex flex-wrap gap-x-4 gap-y-6 items-start self-stretch">
            {platform_arr.length > 0 &&
              platform_arr.map((platform) => (
                <SelectDashboardEditModal
                  platform_arr={platform_arr}
                  setPlatform_arr={setPlatform_arr}
                  platform_id={platform.platform_id}
                  platform_name={platform.platform_name}
                  brand_id={foundBrand? foundBrand.brand_id : ""}
                  user={user}
                  key={platform.platform_id}
                />
              ))}   
          </div>
        </div>

        <div className="flex items-start justify-center gap-4 self-stretch">
          <Button
            color="neutral"
            sx={{
              height: "48px",
              width: "50%",
              backgroundColor: BLACK.B_5,
              color: BLACK.B_100,
              ":hover": {
                backgroundColor: BLACK.B_20,
                padding: "8px 16px",
              },
            }}
            // className="flex px-4 py-2 justify-center items-center gap-[8px]"
            onClick={cancelToggle}
          >
            {/* flex 1 0 0 remaining */}
            Cancel
          </Button>
          <Button
            onClick={saveuser}
            sx={{
              height: "48px",
              width: "50%",
              backgroundColor: PRIMARY.P_BLue,
              padding: "8px 16px",
            }}
            // loading={loading}
          >
            Save
          </Button>
        </div>
      </>
    );
  };

  return (
    <Modal
      open={true}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      className="h-full"
    >
      <div
        style={{ width: 790, backgroundColor: `${WHITE.W_100}` }}
        className="flex flex-col items-center rounded-[18px] px-10 py-10 gap-10"
      >
        {view === "add_brand" && (
          <AddBrandComponent
            setView={setView}
            setUser={setLocaluser}
            selectedBrandId={selectedBrandId}
            setSelectedBrandId={setSelectedBrandId}
            cancelToggle = {cancelToggle}
          />
        )}
        {/* From Here REmaining */}
        {view === "add_platform" && (
          <AddPlatformComponent
            setUser={setLocaluser}
            user_id={user_id}
            selectedBrandId={selectedBrandId}
            setView={setView}
            setSelPlatforms={setSelPlatforms}
            cancelToggle = {cancelToggle}
          />
        )}

        {view === "add_dashboard" && (
          <AddDashboardComponent 
          user={localuser} 
          setUser={setUser}
          setBrands={setBrands}
          setlocaluser={setLocaluser}
          selectedBrandId={selectedBrandId} 
          setView={setView} 
          selPlatforms={selPlatforms}
          cancelToggle = {cancelToggle}
          selectedbrandname={selectedbrandname}
          />
        )}
      </div>
    </Modal>
  );
};

export default UserPlatformEditModal;
