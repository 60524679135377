import { createSlice } from '@reduxjs/toolkit'

export const counterSlice = createSlice({
    name: 'appData',
    initialState: {
        brand_id: "",
        stage: "loading",
        onboarding_type: null,
        power_bi_url: "loading",
        brand_power_bi_dashboard_id: "",
        brand_power_bi_dashboard_type_id: "",
        platform_id: "",
        brand_array: []
    },
    reducers: {
        updateBrandData: (state, { payload }) => {
            for (let el in payload) {
                state[el] = payload[el];
            }
        }
    },
})


export const { updateBrandData } = counterSlice.actions

export default counterSlice.reducer

