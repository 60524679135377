import { Button, Input, Modal, Option, Select, selectClasses } from "@mui/joy"
import { BASE_URL, BLACK, PRIMARY, WHITE } from "../../appConstants";
import MultiSelect from "../commonComponents/MultiSelect";
import React, { useEffect, useState } from "react";
import axios from "axios";
import appError from "../../appError";
import Joi from "joi";
import toast from "react-hot-toast";
import { runInThisContext } from "vm";

type DashboardTypeObj = {
    brand_power_bi_dashboard_type_id: string,
    dashboard_type: string,
    color: string
}

type DashboardObj = {
    brand_power_bi_dashboard_type_id: string,
    dashboard_type: string,
    workspace_id: string,
    report_id: string,
    color: string
}

type AddDashboardModalProps = {
    dashboard_type_array: string[];
    setAddDashboardModalToggle: (value: boolean) => void;
    addNewDashboard: (dashboard: DashboardObj, platformId: string) => void;
    platformId: string
}
const AddDashboardModal: React.FC<AddDashboardModalProps> = ({ dashboard_type_array: dashboardTypeArray, setAddDashboardModalToggle, addNewDashboard, platformId }) => {
    const [dashboardTypes, setDashboardTypes] = useState<DashboardTypeObj[]>([]);

    const [state, setState] = useState<DashboardObj>();

    useEffect((() => {
        fetchDashboardType();
    }),[]);

    const fetchDashboardType = async () => {
        try {
            const token = localStorage.getItem("token");

            const brandsRes = await axios.get(`${BASE_URL}brand/powerBiDashboardType`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const { dashboard_type_array } = brandsRes?.data?.data;
            const dashboardTypeSet = new Set([...dashboardTypeArray]);
            const dashboard_type_array_copy = [];
            for (let dashboardType of dashboard_type_array) {
                if (!dashboardTypeSet.has(dashboardType.brand_power_bi_dashboard_type_id)) {
                    dashboard_type_array_copy.push(dashboardType);
                }
            }

            setDashboardTypes(dashboard_type_array_copy);
        } catch (error) {
            appError(error);
        }
    }

    const selectDashboardType = (e: any, dashboardTypeId: any) => {
        if (dashboardTypeId) {
            const dashboardType = dashboardTypes.find((dashboardType) => dashboardType.brand_power_bi_dashboard_type_id === dashboardTypeId);
            if (!dashboardType) {
                return;
            }
            setState(prevState => {
                if (!prevState) {
                    return {
                        brand_power_bi_dashboard_type_id: dashboardType?.brand_power_bi_dashboard_type_id,
                        dashboard_type: dashboardType?.dashboard_type,
                        color: dashboardType?.color,
                        workspace_id: "",
                        report_id: ""
                    }
                }
                return {
                    ...prevState,
                    brand_power_bi_dashboard_type_id: dashboardType?.brand_power_bi_dashboard_type_id,
                    dashboard_type: dashboardType?.dashboard_type,
                    color: dashboardType?.color
                }
            })
        }
    }

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setState(prevState => {
            if (!prevState) {
                return {
                    [name]: value,
                    brand_power_bi_dashboard_type_id: "",
                    color: "",
                    dashboard_type: "",
                    workspace_id: "",
                    report_id: ""
                }
            }
            return {
                ...prevState,
                [name]: value
            }
        })
    }

    const onSaveClickHandler = () => {
        addNewDashboard(state as DashboardObj, platformId);
        setAddDashboardModalToggle(false);
    }
    return (
        <Modal
            open={true}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <div style={{ height: 414, width: 790, backgroundColor: WHITE.W_100, borderRadius: 18, padding: 40 }} >
                <div className="flex flex-col justify-between" style={{ height: 46 }} >
                    <div style={{ fontSize: 16, fontWeight: 600, lineHeight: "24px" }}>Add Dashboard Type</div>
                    <div style={{ fontSize: 12, fontWeight: 400, lineHeight: "18px", color: BLACK.B_40 }} >Select a dashboard type and provide the relevant IDs to set it up</div>
                </div>
                <div className="flex mt-6" >
                    <div className="w-full" style={{ marginRight: 16 }} >
                        <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_80 }} >Dashboard Type</div>
                        <Select
                            //  onChange={(e1, e2) => onSelectChangeHandler(e1, e2, "status")}
                            onChange={(e, value) => selectDashboardType(e, value)}
                            // placeholder="Select State"
                            name="status"
                            value={state?.brand_power_bi_dashboard_type_id}
                            defaultValue={"Select Dashboard Type"}
                            indicator={
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M9.99984 12.4791C9.88873 12.4791 9.78456 12.4618 9.68734 12.4271C9.59012 12.3923 9.49984 12.3333 9.4165 12.25L5.58317 8.41665C5.43039 8.26387 5.354 8.06942 5.354 7.83331C5.354 7.5972 5.43039 7.40276 5.58317 7.24998C5.73595 7.0972 5.93039 7.02081 6.1665 7.02081C6.40262 7.02081 6.59706 7.0972 6.74984 7.24998L9.99984 10.5L13.2498 7.24998C13.4026 7.0972 13.5971 7.02081 13.8332 7.02081C14.0693 7.02081 14.2637 7.0972 14.4165 7.24998C14.5693 7.40276 14.6457 7.5972 14.6457 7.83331C14.6457 8.06942 14.5693 8.26387 14.4165 8.41665L10.5832 12.25C10.4998 12.3333 10.4096 12.3923 10.3123 12.4271C10.2151 12.4618 10.1109 12.4791 9.99984 12.4791Z" fill="#1C1C1C" fill-opacity="0.4" />
                                </svg>
                            }
                            sx={{
                                "--Input-focusedThickness": "0px",
                                boxShadow: "none",
                                height: "52px",
                                backgroundColor: WHITE.W_100,
                                color: BLACK.B_80,
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: "20px",
                                border: `1px solid ${BLACK.B_5}`,
                                [`& .${selectClasses.indicator}`]: {
                                    transition: '0.2s',
                                    [`&.${selectClasses.expanded}`]: {
                                        transform: 'rotate(-180deg)',
                                    },
                                },


                            }}>
                            {
                                dashboardTypes.map((dashboardType) => {
                                    return (
                                        <Option value={dashboardType.brand_power_bi_dashboard_type_id} >
                                            {/* <div className="flex items-center" >
                                                <div style={{ width: 4, height: 32, backgroundColor: dashboardType?.color }}></div>
                                                <div className="ml-2" >
                                                    {dashboardType.dashboard_type}
                                                </div>
                                            </div> */}

                                            {dashboardType.dashboard_type}
                                        </Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                    <div className="w-full" style={{}} >

                    </div>
                </div>
                <div className="flex mt-6" >
                    <div className="w-full" style={{ marginRight: 16 }} >
                        <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_80 }} >Workspace ID</div>
                        <Input
                            placeholder="Enter the workspace ID"
                            value={state?.workspace_id}
                            onChange={onChangeHandler}
                            sx={{
                                "--Input-focusedThickness": "0px",

                                height: "52px",
                                border: `1px solid ${BLACK.B_5}`,
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: "20px",
                                backgroundColor: WHITE.W_100,
                                boxShadow: "none"
                            }}
                            name="workspace_id"
                        />
                    </div>
                    <div className="w-full" style={{}} >
                        <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_80 }} >Report ID</div>
                        <Input
                            placeholder="Enter the report ID"
                            value={state?.report_id}
                            onChange={onChangeHandler}
                            sx={{
                                "--Input-focusedThickness": "0px",
                                // width: "340px",
                                height: "52px",
                                border: `1px solid ${BLACK.B_5}`,
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: "20px",
                                backgroundColor: WHITE.W_100,
                                boxShadow: "none"
                            }}
                            name="report_id"
                        />
                    </div>
                </div>


                <div className="flex flex-row justify-between mt-10">
                    <Button color="neutral"
                        sx={{
                            height: "48px",
                            width: "340px",
                            backgroundColor: BLACK.B_5,
                            color: BLACK.B_100,
                            ":hover": {
                                backgroundColor: BLACK.B_20,
                            }
                        }}
                        onClick={() => setAddDashboardModalToggle(false)}
                    >Cancel</Button>
                    <Button
                        //loading={loading}
                        onClick={onSaveClickHandler}

                        sx={{
                            height: "48px",
                            width: "340px",
                            backgroundColor: PRIMARY.P_BLue,
                        }}
                    >Save</Button>
                </div>
            </div>
        </Modal>
    )
}

export default AddDashboardModal;  