import {
  Button,
  CircularProgress,
  Input,
  Modal,
  Option,
  Select,
  selectClasses,
  styled,
  SvgIcon,
  Textarea,
} from "@mui/joy";
import { BASE_URL, BLACK, PRIMARY, WHITE } from "../../appConstants";
import React, {
  ChangeEvent,
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  DatePicker,
  DateValidationError,
  DesktopDatePicker,
  LocalizationProvider,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import axios from "axios";
import toast from "react-hot-toast";
import appError from "../../appError";
import { useSelector } from "react-redux";
import {
  formatDateTime,
  getCurrentMonthLastDate,
  getDateString,
  getFutureDate,
  getTimeString,
  getUTC,
} from "../../appFunctions";
import { UUID } from "crypto";
import TimeSlotInput from "../commonComponents/TimeSlotInput";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Joi, { boolean } from "joi";
import { useNavigate } from "react-router-dom";
import MultiSelect from "../commonComponents/MultiSelect";
import SelectDashboard from "./SelectDashboard";

type AddKeywordTypeProps = {
  setToggleModal: Dispatch<SetStateAction<boolean>>;
  setRefresh: Dispatch<SetStateAction<boolean>>;
  fetchusers: () => void;
};

interface AccountInfo {
  marketplaceStringId: string;
  id: string;
  type: string;
  name: string;
  validPaymentMethod: boolean;
}

interface ProfileObject {
  profileId: number;
  countryCode: string;
  currencyCode: string;
  dailyBudget: number;
  timezone: string;
  accountInfo: AccountInfo;
}

type FileDetailObj = {
  file_id: string;
  file_name: string;
  created_time_stamp: string;
  schedule_name: string;
  schedule_type: string;
  profile_obj: ProfileObject;
};

type UploadStateObject = {
  file_id: string;
  file_name: string;
  created_time_stamp: string;
  schedule_name: string;
  schedule_type: string;
  profile_obj: ProfileObject;
};

type TimeSlotObject = {
  type: string;
  week_days: (number | undefined)[];
  time_slots: string[];
};

type StateObject = {
  start_time_stamp: Date | null;
  end_time_stamp: Date | null;
  time_slots: TimeSlotObject;
  emails: [];
};
type HourAndMin = {
  hour: string;
  min: string;
};

const AddNewUserModal: React.FC<AddKeywordTypeProps> = ({
  setToggleModal,
  setRefresh,
  fetchusers,
}) => {
  const brandId = useSelector((state: any) => state.appData.brand_id);

  const [view, setView] = useState<string>("add_user");
  const [fileDetail, setFileDetail] = useState<FileDetailObj>(
    {} as FileDetailObj
  );
  // console.log("View :", view);
  type Brandaccess = {
    permission_name: string;
    checked: boolean;
  };
  const [brandAccessPermissions, setBrandAccessPermissions] = useState<
    Brandaccess[]
  >([
    {
      permission_name: "Keyword Mapping",
      checked: true,
    },
    {
      permission_name: "Cross Platform Mapping",
      checked: false,
    },
    {
      permission_name: "Favourite Groups",
      checked: true,
    },
    {
      permission_name: "Unmapped data",
      checked: true,
    },
  ]);

  // From Here New Code for add User
  type Dashboard = {
    dashboard_type_id: string;
    dashboard_type_name: string;
  };
  type Platform = {
    platform_id: string;
    platform_name: string;
    dashboard_type: Dashboard[];
  };
  type Brand = {
    brand_id: string;
    platform_array: Platform[];
  };
  type Main = {
    full_name: string;
    email: string;
    contact_number: string;
    organisation: string;
    user_type: string;
    brand_array: Brand[];
    status: string;
  };
  const [main, setMain] = useState<Main>({
    full_name: "",
    email: "",
    contact_number: "",
    organisation: "",
    user_type: "",
    brand_array: [],
    status: "ACTIVE",
  });
  // End
  // console.log("Main Object :", main);

  type AddUserComponentProps = {
    main: Main;
    setMain: Dispatch<SetStateAction<Main>>;
    setView: Dispatch<SetStateAction<string>>;
  };

  const AddUserComponent: React.FC<AddUserComponentProps> = ({
    main,
    setMain,
    setView,
  }) => {
    type User = {
      full_name: string;
      email: string;
      contact_number: string;
      organisation: string;
      user_type: string;
    };
    const [user, setUser] = useState<User>({
      full_name: "",
      email: "",
      contact_number: "",
      organisation: "",
      user_type: "",
    });

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setUser((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    };

    const onProceedClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      const schema = Joi.object({
        full_name: Joi.string().required().min(3).max(30).label("Full Name"),
        email: Joi.string()
          .required()
          .email({ minDomainSegments: 2, tlds: { allow:false } })
          .label("Email"),
        organisation: Joi.string()
          .required()
          .min(3)
          .max(30)
          .label("Organisation"),
        contact_number: Joi.string()
          .required()
          .min(10)
          .max(10)
          .label("Contact_Number"),
        user_type: Joi.string()
          .required()
          .min(3)
          .uppercase()
          .label("User Type"),
      });
      const validate = schema.validate(user);
      if (validate.error) {
        appError(validate?.error?.toString());
        return;
      }
      setMain((prevMain) => ({
        ...prevMain,
        ...user, // Spreading the user object properties into main
      }));
      setView("add_brand");
    };

    const handleDropdownChange = (event: any, value: any) => {
      setUser((prev) => {
        return {
          ...prev,
          user_type: value,
        };
      });
    };
    return (
      <>
        <div className="flex flex-col items-start self-stretch gap-6 ">
          <div className="flex flex-col w-full">
            <div
              className="self-stretch"
              style={{
                color: `${BLACK.B_100}`,
                fontSize: 16,
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "24px",
              }}
            >
              Create New User
            </div>
            <div
              className="self-stetch"
              style={{
                color: `${BLACK.B_40}`,
                fontSize: 12,
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "18px",
              }}
            >
              Enter the details to create a new user account
            </div>
          </div>

          <div className="flex flex-col w-full">
            <div
              className="flex flex-col h-[20px] justify-center self-stretch"
              style={{
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              Full Name
            </div>
            <Input
              placeholder="Enter the Full Name"
              value={user.full_name}
              className="flex px-4 py-5 items-center self-stretch gap-6 rounded-[8px] "
              sx={{
                "--Input-focusedThickness": "0px",
                height: "52px",
                border: `1px solid ${BLACK.B_10}`,
                fontSize: 14,
                fontWeight: 400,
                lineHeight: "20px",
                backgroundColor: `${WHITE.W_100}`,
                boxShadow: "none",
              }}
              name="full_name"
              onChange={onChangeHandler}
            />
          </div>

          <div className="flex gap-4 items-start self-stretch">
            <div className="w-[50%] flex flex-col">
              <div
                className="flex flex-col h-[20px] justify-center self-stretch"
                style={{
                  fontSize: 14,
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20px",
                }}
              >
                Email ID
              </div>
              <Input
                placeholder="Enter the email ID"
                value={user.email}
                className="flex px-4 py-5 items-center self-stretch gap-6 rounded-[8px] "
                sx={{
                  "--Input-focusedThickness": "0px",
                  height: "52px",
                  border: `1px solid ${BLACK.B_10}`,
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: "20px",
                  backgroundColor: WHITE.W_100,
                  boxShadow: "none",
                }}
                name="email"
                onChange={onChangeHandler}
              />
            </div>
            <div className="w-[50%] flex flex-col">
              <div
                className="flex flex-col h-[20px] justify-center self-stretch"
                style={{
                  fontSize: 14,
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20px",
                }}
              >
                Mobile No.
              </div>
              <Input
                placeholder="Enter the Mobile No."
                value={user.contact_number}
                className="flex px-4 py-5 items-center self-stretch gap-6 rounded-[8px] "
                sx={{
                  "--Input-focusedThickness": "0px",
                  height: "52px",
                  border: `1px solid ${BLACK.B_10}`,
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: "20px",
                  backgroundColor: WHITE.W_100,
                  boxShadow: "none",
                }}
                name="contact_number"
                onChange={onChangeHandler}
              />
            </div>
          </div>

          <div className="flex gap-4 items-start self-stretch">
            <div className="w-[50%] flex flex-col">
              <div
                className="flex flex-col h-[20px] justify-center self-stretch"
                style={{
                  fontSize: 14,
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20px",
                }}
              >
                Organisation
              </div>
              <Input
                placeholder="Enter the Organisation Name"
                value={user.organisation}
                className="flex px-4 py-5 items-center self-stretch gap-6 rounded-[8px] "
                sx={{
                  "--Input-focusedThickness": "0px",
                  height: "52px",
                  border: `1px solid ${BLACK.B_10}`,
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: "20px",
                  backgroundColor: WHITE.W_100,
                  boxShadow: "none",
                }}
                name="organisation"
                onChange={onChangeHandler}
              />
            </div>
            <div className="w-[50%] flex flex-col ">
              <div
                className="flex flex-col h-[20px] justify-center self-stretch"
                style={{
                  fontSize: 14,
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20px",
                }}
              >
                User Type
              </div>
              <Select
                onChange={handleDropdownChange}
                placeholder="Please Select"
                // defaultValue={userdetails?.user_type}
                name="user_type"
                value={user.user_type}
                indicator={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.99984 12.4791C9.88873 12.4791 9.78456 12.4618 9.68734 12.4271C9.59012 12.3923 9.49984 12.3333 9.4165 12.25L5.58317 8.41665C5.43039 8.26387 5.354 8.06942 5.354 7.83331C5.354 7.5972 5.43039 7.40276 5.58317 7.24998C5.73595 7.0972 5.93039 7.02081 6.1665 7.02081C6.40262 7.02081 6.59706 7.0972 6.74984 7.24998L9.99984 10.5L13.2498 7.24998C13.4026 7.0972 13.5971 7.02081 13.8332 7.02081C14.0693 7.02081 14.2637 7.0972 14.4165 7.24998C14.5693 7.40276 14.6457 7.5972 14.6457 7.83331C14.6457 8.06942 14.5693 8.26387 14.4165 8.41665L10.5832 12.25C10.4998 12.3333 10.4096 12.3923 10.3123 12.4271C10.2151 12.4618 10.1109 12.4791 9.99984 12.4791Z"
                      fill="#1C1C1C"
                      fill-opacity="0.4"
                    />
                  </svg>
                }
                slotProps={{
                  listbox: {},
                }}
                sx={{
                  "--Input-focusedThickness": "0px",
                  height: "52px",
                  color: BLACK.B_80,
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: "20px",
                  backgroundColor: WHITE.W_100,
                  boxShadow: "none",
                  border: `1px solid ${BLACK.B_5}`,
                  [`& .${selectClasses.indicator}`]: {
                    transition: "0.2s",
                    [`&.${selectClasses.expanded}`]: {
                      transform: "rotate(-180deg)",
                    },
                  },
                  ":hover": {
                    backgroundColor: WHITE.W_100,
                  },
                }}
              >
                <Option value={"MANAGER"}>Manager</Option>
                <Option value={"ANALYST"}>Analyst</Option>
              </Select>
            </div>
          </div>
        </div>

        <div className="flex items-start justify-center gap-4 self-stretch">
          <Button
            color="neutral"
            sx={{
              height: "48px",
              width: "50%",
              backgroundColor: BLACK.B_5,
              color: BLACK.B_100,
              ":hover": {
                backgroundColor: BLACK.B_20,
              },
              padding: "8px 16px",
            }}
            // className="flex px-4 py-2 justify-center items-center gap-[8px]"
            onClick={() => setToggleModal(false)}
          >
            {/* flex 1 0 0 remaining */}
            Cancel
          </Button>
          <Button
            onClick={onProceedClick}
            sx={{
              height: "48px",
              width: "50%",
              backgroundColor: PRIMARY.P_BLue,
              padding: "8px 16px",
            }}
            // loading={loading}
          >
            Proceed
          </Button>
        </div>
      </>
    );
  };

  const AddBrandComponent: React.FC<{
    main: Main;
    setMain: Dispatch<SetStateAction<Main>>;
    setView: Dispatch<SetStateAction<string>>;
  }> = ({ main, setMain, setView }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedBrandId, setSelectedBrandId] = useState<string>("");
    // Handle the change event for the dropdown
    const handleDropdownChange = (event: any, value: any) => {
      setSelectedBrandId(value); // Update the state with the selected value
    };
    type SuggestBrand = {
      brand_id: string;
      brand_name: string;
    };
    const [suggestBrands, setSuggestBrands] = useState<SuggestBrand[]>([]);

    const fetchsuggestbrands = async () => {
      const token = localStorage.getItem("token");
      try {
        let api = `${BASE_URL}user/getBrandForAccess`;
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const result = await axios.get(api, { headers });
        const { brand_array } = result?.data?.data;
        setSuggestBrands(brand_array);
      } catch (error) {
      } finally {
      }
    };
    useEffect(() => {
      fetchsuggestbrands();
    }, []);

    const onProceedClick = () => {
      const schema = Joi.string()
        .guid({ version: ["uuidv4", "uuidv5"] })
        .required()
        .label("Brand Name");
      const validate = schema.validate(selectedBrandId);
      if (validate.error) {
        // console.log(validate.error.message);
        appError("Please Select Brand");
        return;
      } else {
        setMain((prevMain) => ({
          ...prevMain,
          brand_array: [
            ...prevMain.brand_array,
            { brand_id: selectedBrandId, platform_array: [] },
          ], // Spreading the user object properties into main
        }));
        setView("add_platform");
      }
    };
    return (
      <>
        <div className="flex flex-col items-start self-stretch gap-6 ">
          <div className="flex flex-col w-full">
            <div
              className="self-stretch"
              style={{
                color: `${BLACK.B_100}`,
                fontSize: 16,
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "24px",
              }}
            >
              Add Brands for Access
            </div>
            <div
              className="self-stetch"
              style={{
                color: `${BLACK.B_40}`,
                fontSize: 12,
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "18px",
              }}
            >
              Assign brands and define their access levels
            </div>
          </div>

          <div className="flex gap-4 items-start self-stretch">
            <div className="w-[50%] flex flex-col gap-[4px]">
              <div
                className="flex flex-col h-[20px] justify-center self-stretch"
                style={{
                  fontSize: 14,
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20px",
                  color:"#5B5B5B"
                }}
              >
                Select Brand
              </div>
              <Select
                onChange={handleDropdownChange}
                placeholder="Please Select"
                name="selectedBrandId"
                value={selectedBrandId}
                indicator={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.99984 12.4791C9.88873 12.4791 9.78456 12.4618 9.68734 12.4271C9.59012 12.3923 9.49984 12.3333 9.4165 12.25L5.58317 8.41665C5.43039 8.26387 5.354 8.06942 5.354 7.83331C5.354 7.5972 5.43039 7.40276 5.58317 7.24998C5.73595 7.0972 5.93039 7.02081 6.1665 7.02081C6.40262 7.02081 6.59706 7.0972 6.74984 7.24998L9.99984 10.5L13.2498 7.24998C13.4026 7.0972 13.5971 7.02081 13.8332 7.02081C14.0693 7.02081 14.2637 7.0972 14.4165 7.24998C14.5693 7.40276 14.6457 7.5972 14.6457 7.83331C14.6457 8.06942 14.5693 8.26387 14.4165 8.41665L10.5832 12.25C10.4998 12.3333 10.4096 12.3923 10.3123 12.4271C10.2151 12.4618 10.1109 12.4791 9.99984 12.4791Z"
                      fill="#1C1C1C"
                      fill-opacity="0.4"
                    />
                  </svg>
                }
                slotProps={{
                  listbox: {},
                }}
                sx={{
                  "--Input-focusedThickness": "0px",
                  height: "52px",
                  color: BLACK.B_80,
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: "20px",
                  backgroundColor: WHITE.W_100,
                  boxShadow: "none",
                  border: `1px solid ${BLACK.B_5}`,
                  [`& .${selectClasses.indicator}`]: {
                    transition: "0.2s",
                    [`&.${selectClasses.expanded}`]: {
                      transform: "rotate(-180deg)",
                    },
                  },
                  ":hover": {
                    backgroundColor: WHITE.W_100,
                  },
                }}
              >
                {suggestBrands.length > 0 &&
                  suggestBrands.map((brand) => {
                    return (
                      <Option value={brand.brand_id} key={brand.brand_id}>
                        {" "}
                        {brand.brand_name}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          </div>

          <div className="flex gap-4 items-start self-stretch">
            <div className="w-[100%] flex flex-col gap-[8px] items-start self-stretch">
              <div
                className="flex flex-col h-[20px] justify-center self-stretch"
                style={{
                  fontSize: 14,
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20px",
                }}
              >
                Select Brand Access
              </div>
              <div className="flex items-start justify-between self-stretch rounded-[16px]">
                {brandAccessPermissions.length > 0 &&
                  brandAccessPermissions.map((permission) => {
                    return (
                      <label
                        className="flex items-center justify-center gap-1"
                        style={{
                          fontSize: 14,
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "20px",
                          color: `${BLACK.B_100}`,
                          paddingTop:"8px",
                            paddingBottom:"8px"
                        }}
                        key={permission.permission_name}
                      >
                        <input
                          type="checkbox"
                          name="options"
                          value="keywordmapping"
                          // disabled
                          checked
                          style={{
                            width: 16,
                            height: 16,
                            accentColor: "black",
                            borderRadius:"4px",
                          }}
                          // currently remaining from here
                        />
                        {permission?.permission_name}
                      </label>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-start justify-center gap-4 self-stretch">
          <Button
            color="neutral"
            sx={{
              height: "48px",
              width: "50%",
              backgroundColor: BLACK.B_5,
              color: BLACK.B_100,
              ":hover": {
                backgroundColor: BLACK.B_20,
              },
              padding: "8px 16px",
            }}
            // className="flex px-4 py-2 justify-center items-center gap-[8px]"
            onClick={() => setToggleModal(false)}
          >
            {/* flex 1 0 0 remaining */}
            Cancel
          </Button>
          <Button
            onClick={onProceedClick}
            sx={{
              height: "48px",
              width: "50%",
              backgroundColor: PRIMARY.P_BLue,
              padding: "8px 16px",
            }}
            // loading={loading}
          >
            Proceed
          </Button>
        </div>
      </>
    );
  };

  const AddPlatformComponent: React.FC<{
    main: Main;
    setMain: Dispatch<SetStateAction<Main>>;
    setView: Dispatch<SetStateAction<string>>;
  }> = ({ main, setMain, setView }) => {
    const navigate = useNavigate();
    const [selectedPlatforms, setSelectedPlatforms] = useState<Set<string>>(
      new Set([])
    );
    type Platform = {
      platform_id: string;
      platform: string;
    };
    const [platforms, setPlatforms] = useState<Platform[]>([]);
    let platformList = platforms.map((platform) => {
      return { id: platform.platform_id, label: platform.platform };
    });
    const onChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
      let set = new Set(selectedPlatforms);
      if (e.target.checked) {
        set.add(id);
      } else {
        if (set.has(id)) {
          set.delete(id);
        }
      }
      setSelectedPlatforms(set);
    };
    const onProceedClick = () => {
      const platform_ids = Array.from(selectedPlatforms);
      if (platform_ids.length === 0) {
        appError("Please select a Platform");
        return;
      }
      const updatedData = { ...main };
      updatedData.brand_array.forEach((brand) => {
        selectedPlatforms.forEach((id) => {
          const platform = platformList.find((p) => p.id === id);
          if (platform) {
            brand.platform_array.push({
              platform_id: id,
              platform_name: platform.label,
              dashboard_type: [], // Initialize as needed
            });
          }
        });
      });
      setMain(updatedData);
      setView("add_dashboard");
    };

    const { brand_id } = main.brand_array[0];
    const fetchsuggestplatforms = async () => {
      const token = localStorage.getItem("token");
      try {
        let api = `${BASE_URL}user/getPlatformForAccess?brand_id=${brand_id}`;
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const result = await axios.get(api, { headers });
        const { platform_array } = result?.data?.data;
        setPlatforms(platform_array);
      } catch (error) {
        appError(error);
      } finally {
      }
    };
    useEffect(() => {
      fetchsuggestplatforms();
    }, []);

    return (
      <>
        <div className="flex flex-col items-start self-stretch gap-6 ">
          <div className="flex flex-col w-full">
            <div
              className="self-stretch"
              style={{
                color: `${BLACK.B_100}`,
                fontSize: 16,
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "24px",
              }}
            >
              Assign Platforms for Access
            </div>
            <div
              className="self-stetch"
              style={{
                color: `${BLACK.B_40}`,
                fontSize: 12,
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "18px",
              }}
            >
              Choose the platforms and select the access level for each
            </div>
          </div>

          <div className="flex gap-4 items-start self-stretch">
            <div className="w-[50%] flex flex-col gap-[4px]">
              <div
                className="flex flex-col h-[20px] justify-center self-stretch"
                style={{
                  fontSize: 14,
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20px",
                  color:"#5B5B5B"
                }}
              >
                Select Platform
              </div>
              <MultiSelect
                children={platformList}
                selected={selectedPlatforms}
                onChange={onChange}
                placeholder="Select Platform"
              />
            </div>
          </div>
        </div>

        <div className="flex items-start justify-center gap-4 self-stretch">
          <Button
            color="neutral"
            sx={{
              height: "48px",
              width: "50%",
              backgroundColor: BLACK.B_5,
              color: BLACK.B_100,
              ":hover": {
                backgroundColor: BLACK.B_20,
              },
              padding: "8px 16px",
            }}
            // className="flex px-4 py-2 justify-center items-center gap-[8px]"
            onClick={() => setToggleModal(false)}
          >
            {/* flex 1 0 0 remaining */}
            Cancel
          </Button>
          <Button
            onClick={onProceedClick}
            sx={{
              height: "48px",
              width: "50%",
              backgroundColor: PRIMARY.P_BLue,
              padding: "8px 16px",
            }}
            // loading={loading}
          >
            Proceed
          </Button>
        </div>
      </>
    );
  };

  const AddDashboardComponent: React.FC<{
    main: Main;
    setMain: Dispatch<SetStateAction<Main>>;
    setView: Dispatch<SetStateAction<string>>;
  }> = ({ main, setMain, setView }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedDashboard, setSelectedDashboard] = useState<Set<string>>(
      new Set([])
    );
    const { platform_array } = main.brand_array[0];
    const [platform_arr, setPlatform_arr] = useState<Platform[]>(
      main.brand_array[0].platform_array
    );
    const merge = (main: Main, platform_arr: Platform[]) => {
      return {
        ...main,
        brand_array: main.brand_array.map((brand) => ({
          ...brand,
          platform_array: brand.platform_array.map((platform) => {
            const newPlatform = platform_arr.find(
              (p) => p.platform_id === platform.platform_id
            );
            if (newPlatform) {
              // Create a Set to hold existing dashboard_type_ids to avoid duplicates
              const existingDashboardIds = new Set(
                platform.dashboard_type.map((d) => d.dashboard_type_id)
              );

              // Filter new dashboard types to remove duplicates
              const newDashboardTypes = newPlatform.dashboard_type.filter(
                (d) => !existingDashboardIds.has(d.dashboard_type_id)
              );

              return {
                ...platform,
                dashboard_type: [
                  ...platform.dashboard_type,
                  ...newDashboardTypes,
                ], // Merge arrays
              };
            }
            return platform;
          }),
        })),
      };
    };
    const saveuser = async () => {
      const dashboardTypeSchema = Joi.object({
        dashboard_type_id: Joi.string()
          .guid({ version: ["uuidv4", "uuidv5"] })
          .required(),
        dashboard_type_name: Joi.string()
          .min(3)
          .required()
          .label("Dashboard Name"),
      });
      const schema = Joi.array()
        .min(1)
        .items(
          Joi.object({
            platform_id: Joi.string()
              .guid({ version: ["uuidv4", "uuidv5"] })
              .required(),
            dashboard_type: Joi.array()
              .items(dashboardTypeSchema)
              .required()
              .label("Dashboard"),
            platform_name: Joi.string()
              .min(3)
              .required()
              .label("Platform Name"),
          })
        );
      const validate = schema.validate(platform_arr);
      if (validate.error) {
        // console.log(validate.error);
        appError("Please select Atleast 1 Dashboard For Platform");
        return;
      }
      merge(main, platform_arr);
      try {
        setLoading(true);
        const {
          full_name,
          email,
          contact_number,
          user_type,
          status: mainstatus,
          organisation,
          brand_array,
        } = main;
        const token = localStorage.getItem("token");
        const res = await axios.put(
          `${BASE_URL}user/UserPlatformDashboardMapping`,
          {
            full_name,
            email,
            contact_number,
            user_type,
            status: mainstatus,
            organisation,
            brand_array,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { status } = res?.data;
        toast.success(status);
        setToggleModal(false);
        setMain({
          full_name: "",
          email: "",
          contact_number: "",
          organisation: "",
          user_type: "",
          brand_array: [],
          status: "ACTIVE",
        });
        fetchusers();
        setLoading(false);
      } catch (error) {
        appError(error);
      } finally {
      }
    };
    // console.log(main);
    return (
      <>
        <div className="flex flex-col items-start self-stretch gap-6 ">
          <div className="flex flex-col w-full">
            <div
              className="self-stretch"
              style={{
                color: `${BLACK.B_100}`,
                fontSize: 16,
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "24px",
              }}
            >
              Assign Dashboard for Access
            </div>
            <div
              className="self-stetch"
              style={{
                color: `${BLACK.B_40}`,
                fontSize: 12,
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "18px",
              }}
            >
              Choose the platforms and select the access level for each
            </div>
          </div>

          <div className="flex flex-wrap gap-x-4 gap-y-6 items-start self-stretch">
            {platform_array.length > 0 &&
              platform_array.map((platform) => (
                <SelectDashboard
                  platform_arr={platform_arr}
                  setPlatform_arr={setPlatform_arr}
                  platform_id={platform.platform_id}
                  platform_name={platform.platform_name}
                  brand_id={main.brand_array[0].brand_id}
                  user_id=""
                  key={platform.platform_id}
                />
              ))}
          </div>
        </div>

        <div className="flex items-start justify-center gap-4 self-stretch">
          <Button
            color="neutral"
            sx={{
              height: "48px",
              width: "50%",
              backgroundColor: BLACK.B_5,
              color: BLACK.B_100,
              ":hover": {
                backgroundColor: BLACK.B_20,
                padding: "8px 16px",
              },
            }}
            // className="flex px-4 py-2 justify-center items-center gap-[8px]"
            onClick={() => setToggleModal(false)}
          >
            {/* flex 1 0 0 remaining */}
            Cancel
          </Button>
          <Button
            onClick={saveuser}
            sx={{
              height: "48px",
              width: "50%",
              backgroundColor: PRIMARY.P_BLue,
              padding: "8px 16px",
            }}
            loading={loading}
          >
            Save
          </Button>
        </div>
      </>
    );
  };

  const onNextClickHandler = (state: UploadStateObject) => {
    setFileDetail(state);
    setView(state.schedule_type);
  };

  return (
    <Modal
      open={true}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      className="h-full"
    >
      <div
        style={{ width: 790, backgroundColor: `${WHITE.W_100}` }}
        className="flex flex-col items-center rounded-[18px] px-10 py-10 gap-10"
      >
        {view === "add_user" && (
          <AddUserComponent main={main} setMain={setMain} setView={setView} />
        )}

        {view === "add_brand" && (
          <AddBrandComponent main={main} setMain={setMain} setView={setView} />
        )}
        {/* From Here REmaining */}
        {view === "add_platform" && (
          <AddPlatformComponent
            main={main}
            setMain={setMain}
            setView={setView}
          />
        )}

        {view === "add_dashboard" && (
          <AddDashboardComponent
            main={main}
            setMain={setMain}
            setView={setView}
          />
        )}
      </div>
    </Modal>
  );
};

export default AddNewUserModal;
