import { SetStateAction, useEffect, useRef, useState } from "react";
import { BASE_URL, BLACK, WHITE } from "../../appConstants";
import appError from "../../appError";
import axios from "axios";
import { Tooltip, Button } from "@mui/joy";
import { Dispatch } from "@reduxjs/toolkit";

type Brand = {
  brand_id: string;
  brand_name: string;
};

interface BrandsAssignProps {
  user_id: string; // or number, depending on your use case
  view: boolean;
  ipwidth: string;
  brands: Brand[];
}

const BrandsAssign: React.FC<BrandsAssignProps> = ({ user_id, view, ipwidth, brands }) => {

  const parentComponentRef = useRef<HTMLDivElement | null>(null);
  const [parentWidth, setParentWidth] = useState<number>(0);
  const [totalChildWidths, setTotalChildWidths] = useState<number[]>([]); // Holds the widths of each brand component
  const [visibleBrandsCount, setVisibleBrandsCount] = useState<number>(0); // Tracks how many brands are visible

  useEffect(() => {
    // Handle resize of the parent component (to track its available width)
    const handleResize = () => {
      if (parentComponentRef.current) {
        setParentWidth(Math.round(parentComponentRef.current.getBoundingClientRect().width) - 16 - 16 - 17 - 16 - 40);
      }
    };

    // Initial resize calculation
    const timer = setTimeout(()=> {
        handleResize();
    },500);

    // Event listener to recalculate on window resize
    window.addEventListener("resize", handleResize);
    // Cleanup event listener
    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    // Calculate width of each child component (brand)
  
    const childWidths = brands?.map((brand) => {
      const div = document.createElement("div");
      div.style.position = "absolute";
      div.style.visibility = "hidden";
      div.style.fontSize = "10px"; // Ensure font matches the actual usage
      div.innerText = brand.brand_name;
      document.body.appendChild(div);
      const width = div.offsetWidth + 16; // Adding padding/margin if needed
      document.body.removeChild(div); // Clean up
      return width;
    });

    setTotalChildWidths(childWidths);
  }, []);

  useEffect(() => {
    // Calculate how many brands can fit within the parent width
    let cumulativeWidth = 0;
    let count = 0;

    // Accumulate the width of brands and determine how many can fit in the parent container
    for (let i = 0; i < brands?.length; i++) {
      cumulativeWidth += totalChildWidths[i] || 0;
      if (cumulativeWidth <= parentWidth) {
        count += 1;
      } else {
        break; // Stop if the cumulative width exceeds parent width
      }
    }

    setVisibleBrandsCount(count); // Store the number of visible brands
  }, [parentWidth, totalChildWidths, brands]);

  const remainingBrands = brands.length - visibleBrandsCount; // Brands that don't fit in the container
  const getRemainingBrands = () => {
    let ans: string = "";
    for (let i = brands.length - remainingBrands; i < brands.length; i++) {
      if (i == brands.length - 1) {
        ans += `${brands[i].brand_name}`;
      } else {
        ans += `${brands[i].brand_name}, `;
      }
    }
    return ans;
  };
  return (
    <>
      {brands && brands.length > 0 && (
        <div
          className={view ? "flex items-center gap-2 w-full" : "flex p-4 items-center gap-2 self-stretch"}
          style={{ width: ipwidth }}
          ref={parentComponentRef}
        >
          {/* Render only the brands that fit */}
          {brands.slice(0, visibleBrandsCount).map((brand) => (
            <div
              className="flex px-2 py-1 justify-center items-center rounded-[4px]"
              style={{ backgroundColor: "rgba(28,28,28,0.05)" }}
              key={brand.brand_id}
            >
              <div
                style={{
                  fontSize: 10,
                  fontWeight: 500,
                  fontStyle: "normal",
                  lineHeight: "normal",
                  textWrap: "nowrap"
                }}
              >
                {brand.brand_name}
              </div>
            </div>
          ))}

          {/* Show tooltip if there are remaining brands */}
          {remainingBrands > 0 && (
            <Tooltip
              title={`${getRemainingBrands()}`}
              arrow
              placement="bottom-end"
              sx={{
                bgcolor: BLACK.B_100,
                padding: "16px",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
                borderRadius: "8px",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "normal",
                // lineHeight: "18px",
                color: WHITE.W_100,

              }}
            >
              <Button
                variant="plain"
                sx={{
                  padding: "2px",
                  color: "black",
                  ":hover": {
                    bgcolor: "#FFFF",
                  },
                  height: "22px"
                }}
              >
                <div
                  style={{
                    fontSize: 10,
                    fontWeight: 500,
                    fontStyle: "normal",
                    lineHeight: "normal",
                  }}
                >
                  +{remainingBrands}
                </div>
              </Button>
            </Tooltip>
          )}
        </div>
      )}
    </>
  );
};

export default BrandsAssign;