import { Button, Input, Modal } from "@mui/joy"
import { BASE_URL, BLACK, PRIMARY, WHITE } from "../../appConstants";
import MultiSelect from "../commonComponents/MultiSelect";
import React, { useEffect, useState } from "react";
import axios from "axios";
import appError from "../../appError";
import Joi from "joi";
import toast from "react-hot-toast";



type Platform = {
    platform_id: string;
    platform: string;
    platform_logo_url: string;
}


type BrandPlatform = {
    platform: string;
    platform_id: string;
    platform_logo_url: string;
};

type Brand = {
    brand_id: string;
    brand_name: string;
    company_name: string;
    status: string;
    platform_array: BrandPlatform[];
};
type BrandPlatformModalProps = {
    setAddPlatformModalToggle: (value: boolean) => void;
    brandPlatformMapping: Brand;
    addPlatform: (platform_array:Platform[]) => void;
}
const BrandPlatformEditModal: React.FC<BrandPlatformModalProps> = ({ brandPlatformMapping, setAddPlatformModalToggle ,addPlatform}) => {
    const { brand_id, brand_name, company_name, platform_array, status } = brandPlatformMapping;
    const [selectedPlatforms, setSelectedPlatforms] = useState<Set<string>>(new Set([]));
    const [platforms, setPlatforms] = useState<Platform[]>([]);
    const [loading, setLoading] = useState(false);
    type State = {
        brand_name: string;
        company_name: string;
    }
    const [state, setState] = useState<State>({} as State);

    useEffect(() => {
        (async () => {
            const token = localStorage.getItem("token");
            let api = `${BASE_URL}onboarding/platforms`;
            const headers = {
                "Authorization": `Bearer ${token}`
            }
            try {
                const result = await axios.get(api, { headers });
                const { platform_array: platformArray } = result?.data?.data;

                let platformSet = new Set<string>();
                platform_array.map((platform) => {
                    platformSet.add(platform.platform_id);
                });

                const newPlatformArray = [];
                for (let platform of platformArray) {
                    if (!platformSet.has(platform.platform_id)) {
                        newPlatformArray.push(platform);
                    }
                }
                setPlatforms(newPlatformArray);
            } catch (error) {

            } finally {

            }

        })();

    }, []);



    const onChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
        let set = new Set(selectedPlatforms);
        if (e.target.checked) {
            set.add(id);
        } else {
            if (set.has(id)) {
                set.delete(id);
            }
        }
        setSelectedPlatforms(set);
    }


    const onSaveClickHandler = async () => {
        let selectedPlatformsArray: Platform[] = [];
        for (let platform of platforms) {
            if (selectedPlatforms.has(platform.platform_id)) {
                selectedPlatformsArray.push(platform);
            }
        }
        addPlatform(selectedPlatformsArray);
    }

    let platformList = platforms.map((platform) => {
        return { id: platform.platform_id, label: platform.platform }
    })

    


    return (
        <Modal
            open={true}

            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >

            <div style={{ height: 414, width: 790, backgroundColor: WHITE.W_100, borderRadius: 18, padding: 40 }} >
                <div className="flex flex-col justify-between" style={{ height: 46 }} >
                    <div style={{ fontSize: 16, fontWeight: 600, lineHeight: "24px" }}>Select Platforms</div>
                    <div style={{ fontSize: 12, fontWeight: 400, lineHeight: "18px", color: BLACK.B_40 }} >Please select platforms you want to map with Brand</div>
                </div>

                <div className="flex mt-6" >
                    <div className="w-full" style={{ marginRight: 16 }} >
                        <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_80 }} >Brand Name</div>
                        <Input
                            placeholder="Enter the brand name"
                            value={brand_name}
                            disabled
                            sx={{
                                "--Input-focusedThickness": "0px",

                                height: "52px",
                                border: `1px solid ${BLACK.B_5}`,
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: "20px",
                                backgroundColor: WHITE.W_100,
                                boxShadow: "none"
                            }}
                            name="brand_name"
                        />
                    </div>
                    <div className="w-full" style={{}} >
                        <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_80 }} >Brand Name</div>
                        <Input
                            placeholder="Enter the company name"
                            value={company_name}
                            disabled
                            sx={{
                                "--Input-focusedThickness": "0px",
                                // width: "340px",
                                height: "52px",
                                border: `1px solid ${BLACK.B_5}`,
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: "20px",
                                backgroundColor: WHITE.W_100,
                                boxShadow: "none"
                            }}
                            name="company_name"
                        />
                    </div>
                </div>
                <div className="flex mt-6" >
                    <div className="w-full" style={{ marginRight: 16 }} >
                        <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_80 }} >Select Platform</div>
                        <MultiSelect
                            children={platformList}
                            selected={selectedPlatforms}
                            onChange={onChange}
                            placeholder="Select Platform"
                        />
                    </div>
                    <div className="w-full" style={{}} >

                    </div>
                </div>

                <div className="flex flex-row justify-between mt-10">
                    <Button color="neutral"
                        sx={{
                            height: "48px",
                            width: "340px",
                            backgroundColor: BLACK.B_5,
                            color: BLACK.B_100,
                            ":hover": {
                                backgroundColor: BLACK.B_20,
                            }
                        }}
                        onClick={() => setAddPlatformModalToggle(false)}
                    >Cancel</Button>
                    <Button
                        loading={loading}
                        onClick={onSaveClickHandler}

                        sx={{
                            height: "48px",
                            width: "340px",
                            backgroundColor: PRIMARY.P_BLue,
                        }}
                    >Save</Button>
                </div>
            </div>
        </Modal>
    )
}

export default BrandPlatformEditModal;  