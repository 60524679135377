import React, {
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { BASE_URL, BLACK, PRIMARY, WHITE } from "../../appConstants";
import axios from "axios";
import appError from "../../appError";

type SubDashboard = {
  sub_module_for_dashboard_type: string;
  sub_module_for_dashboard_type_id: string;
};

type Dashboard = {
  color: string;
  dashboard_type_id: string;
  dashboard_type_name: string;
  dashboard_type_sub_module?: SubDashboard[];
};

type Platform = {
  platform_id: string;
  platform_name: string;
  dashboard_type: Dashboard[];
};

type Brand_det = {
  brand_id: string;
  brand_name: string; // This property is in Brand_det, but not in EditBrand_det
  platform_array: Platform[];
};

type User = {
  full_name: string;
  status: string;
  user_type: string;
  email: string;
  organisation: string;
  contact_number: string;
  brand_array: Brand_det[];
};
type SubDashboardsProps = {
  brand_id: string;
  platform_id: string;
  dashboard_id: string;
  userDashboards?: SubDashboard[];
  user: User;
  setUser: Dispatch<SetStateAction<User>>;
  setDataChanged: Dispatch<SetStateAction<boolean>>;
};
const SubDashboardsForEdit: React.FC<SubDashboardsProps> = ({
  brand_id,
  platform_id,
  dashboard_id,
  userDashboards,
  user,
  setUser,
  setDataChanged
}) => {
  const dataset = new Set(
    userDashboards &&
      userDashboards.map((dashboard) => dashboard.sub_module_for_dashboard_type)
  );
  const [dashboards, setDashboards] = useState<SubDashboard[]>([]); // Storing data from get All subDashboards API

  const checkIfExists = (brandId : string, platformId : string, dashboardId : string, subDashboardId : string) => {
    return user.brand_array.some(brand => 
      brand.brand_id === brandId &&
      brand.platform_array.some(platform => 
        platform.platform_id === platformId &&
        platform.dashboard_type.some(dashboard => 
          dashboard.dashboard_type_id === dashboardId &&
          dashboard.dashboard_type_sub_module?.some(subModule => 
            subModule.sub_module_for_dashboard_type_id === subDashboardId
          )
        )
      )
    );
  }

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name,value } = e.target;
    if(checkIfExists(brand_id,platform_id, dashboard_id, value)){
        // Remove Sub Dashboard 
        setUser(prev => ({
            ...prev,
            brand_array: prev.brand_array.map(brand => {
              if (brand.brand_id === brand_id) {
                return {
                  ...brand,
                  platform_array: brand.platform_array.map(platform => {
                    if (platform.platform_id === platform_id) {
                      return {
                        ...platform,
                        dashboard_type: platform.dashboard_type.map(dashboard => {
                          if (dashboard.dashboard_type_id === dashboard_id) {
                            return {
                              ...dashboard,
                              dashboard_type_sub_module: dashboard.dashboard_type_sub_module
                                ? dashboard.dashboard_type_sub_module.filter(
                                    subModule => subModule.sub_module_for_dashboard_type_id !== value
                                  )
                                : [], // Use undefined instead of null if that's expected
                            };
                          }
                          return dashboard; // Return the dashboard unchanged if it doesn't match
                        }),
                      };
                    }
                    return platform; // Return the platform unchanged if it doesn't match
                  }),
                };
              }
              return brand; // Return the brand unchanged if it doesn't match
            }),
          }));
        setDataChanged(true);
    }
    else{
        // Add Sub Dashboard
        setUser(prev => ({
            ...prev,
            brand_array: prev.brand_array.map(brand => {
              if (brand.brand_id === brand_id) {
                return {
                  ...brand,
                  platform_array: brand.platform_array.map(platform => {
                    if (platform.platform_id === platform_id) {
                      return {
                        ...platform,
                        dashboard_type: platform.dashboard_type.map(dashboard => {
                          if (dashboard.dashboard_type_id === dashboard_id) {
                            return {
                              ...dashboard,
                              dashboard_type_sub_module: [
                                ...dashboard.dashboard_type_sub_module || [],
                                {
                                    sub_module_for_dashboard_type: name,
                                    sub_module_for_dashboard_type_id: value
                                }
                              ]
                            };
                          }
                          return dashboard; // Return the dashboard unchanged if it doesn't match
                        }),
                      };
                    }
                    return platform; // Return the platform unchanged if it doesn't match
                  }),
                };
              }
              return brand; // Return the brand unchanged if it doesn't match
            }),
          }));
        setDataChanged(true);
    }
  };

  const fetchSubDashboards = async () => {
    const token = localStorage.getItem("token");

    let api = `${BASE_URL}user/getSubDashboardByDashboardType?dashboard_id=${dashboard_id}`;

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const result = await axios.get(api, { headers });
      const { dashboard_type_sub_module } = result?.data?.data;
      if (!dashboard_type_sub_module) {
        appError("Error in fetching SubDashboards");
      } else {
        setDashboards(dashboard_type_sub_module);
      }
    } catch (error) {
      appError(error);
      // console.log(error);
      return;
    } finally {
    }
  };
  useEffect(() => {
    fetchSubDashboards();
  }, []);

  return (
    <div className="flex gap-10 items-start">
      {dashboards.length > 0 &&
        dashboards.map((dashboard) => {
          const isChecked = dataset.has(
            dashboard.sub_module_for_dashboard_type
          );
          return (
            <label
              className="flex items-center gap-2"
              style={{
                fontSize: 14,
                fontWeight: 400,
                fontStyle: "normal",
                color: "#1C1C1C",
              }}
              key={dashboard.sub_module_for_dashboard_type_id}
            >
              <input
                type="checkbox"
                name={dashboard.sub_module_for_dashboard_type}
                value={dashboard.sub_module_for_dashboard_type_id}
                checked={isChecked}
                onChange={onChangeHandler}
                style={{ width: 16, height: 16, accentColor: "black" }}
              />
              {dashboard.sub_module_for_dashboard_type}
            </label>
          );
        })}
    </div>
  );
};

export default SubDashboardsForEdit;
