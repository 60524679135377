import React, { useEffect, useState } from "react";
import { BASE_URL, BLACK, PRIMARY, STATUS, WHITE } from "../../appConstants";
import { Input } from "@mui/joy";
import axios from "axios";
import AddDashboardModal from "./AddDashboardModal";
import PlatformLogo from "../PlatformAndDashboardManagement/PlatformLogo";

type Dashboard = {
  brand_power_bi_dashboard_id?: string;
  brand_power_bi_dashboard_type_id: string;
  dashboard_type: string;
  workspace_id: string;
  report_id: string;
  color: string;
};

type PlatformDashboardMapping = {
  platform_id: string;
  platform: string;
  platform_logo_url: string;
  dashboard_array: Dashboard[];
};
type BrandPlatform = {
  platform: string;
  platform_id: string;
  platform_logo_url: string;
};
type PlatformDashboardMappingEditProps = {
  platformDashboardMapping: PlatformDashboardMapping;
  addNewDashboard: (dashboard: Dashboard, platformId: string) => void;
  editDashboard: (
    name: string,
    value: string,
    dashboardTypeId: string,
    platformId: string
  ) => void;
  removePlatformToggle: (platform: BrandPlatform, toggle: boolean) => void;
  removeDashboard: (
    dashboardTypeId: string,
    dashboard_type: string,
    platformId: string
  ) => void;
};

const PlatformDashboardMappingEdit: React.FC<
  PlatformDashboardMappingEditProps
> = ({
  platformDashboardMapping,
  addNewDashboard,
  editDashboard,
  removePlatformToggle,
  removeDashboard,
}) => {
  const [AddDashboardModalToggle, setAddDashboardModalToggle] =
    useState<boolean>(false);
  // const [platformDashboardMapping, setPlatformDashboardMapping] = useState<PlatformDashboardMapping>();

  const dashboard_type_array = platformDashboardMapping?.dashboard_array.map(
    (dashboard) => dashboard.brand_power_bi_dashboard_type_id
  );
  return (
    <div className="mt-6" style={{ borderRadius: 16, paddingBottom: 24 }}>
      <div
        className="flex items-center justify-between px-6"
        style={{
          height: 56,
          backgroundColor: "#F6F7F9",
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          fontSize: 16,
          fontWeight: 700,
          lineHeight: "20px",
        }}
      >
        {platformDashboardMapping?.platform}
        <div
          onClick={() =>
            removePlatformToggle(
              {
                platform_id: platformDashboardMapping?.platform_id,
                platform: platformDashboardMapping?.platform,
                platform_logo_url: platformDashboardMapping?.platform_logo_url
              },
              true
            )
          }
          className="w-fit cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M16.875 3.75H13.75V3.125C13.75 2.62772 13.5525 2.15081 13.2008 1.79917C12.8492 1.44754 12.3723 1.25 11.875 1.25H8.125C7.62772 1.25 7.15081 1.44754 6.79917 1.79917C6.44754 2.15081 6.25 2.62772 6.25 3.125V3.75H3.125C2.95924 3.75 2.80027 3.81585 2.68306 3.93306C2.56585 4.05027 2.5 4.20924 2.5 4.375C2.5 4.54076 2.56585 4.69973 2.68306 4.81694C2.80027 4.93415 2.95924 5 3.125 5H3.75V16.25C3.75 16.5815 3.8817 16.8995 4.11612 17.1339C4.35054 17.3683 4.66848 17.5 5 17.5H15C15.3315 17.5 15.6495 17.3683 15.8839 17.1339C16.1183 16.8995 16.25 16.5815 16.25 16.25V5H16.875C17.0408 5 17.1997 4.93415 17.3169 4.81694C17.4342 4.69973 17.5 4.54076 17.5 4.375C17.5 4.20924 17.4342 4.05027 17.3169 3.93306C17.1997 3.81585 17.0408 3.75 16.875 3.75ZM7.5 3.125C7.5 2.95924 7.56585 2.80027 7.68306 2.68306C7.80027 2.56585 7.95924 2.5 8.125 2.5H11.875C12.0408 2.5 12.1997 2.56585 12.3169 2.68306C12.4342 2.80027 12.5 2.95924 12.5 3.125V3.75H7.5V3.125ZM15 16.25H5V5H15V16.25ZM8.75 8.125V13.125C8.75 13.2908 8.68415 13.4497 8.56694 13.5669C8.44973 13.6842 8.29076 13.75 8.125 13.75C7.95924 13.75 7.80027 13.6842 7.68306 13.5669C7.56585 13.4497 7.5 13.2908 7.5 13.125V8.125C7.5 7.95924 7.56585 7.80027 7.68306 7.68306C7.80027 7.56585 7.95924 7.5 8.125 7.5C8.29076 7.5 8.44973 7.56585 8.56694 7.68306C8.68415 7.80027 8.75 7.95924 8.75 8.125ZM12.5 8.125V13.125C12.5 13.2908 12.4342 13.4497 12.3169 13.5669C12.1997 13.6842 12.0408 13.75 11.875 13.75C11.7092 13.75 11.5503 13.6842 11.4331 13.5669C11.3158 13.4497 11.25 13.2908 11.25 13.125V8.125C11.25 7.95924 11.3158 7.80027 11.4331 7.68306C11.5503 7.56585 11.7092 7.5 11.875 7.5C12.0408 7.5 12.1997 7.56585 12.3169 7.68306C12.4342 7.80027 12.5 7.95924 12.5 8.125Z"
              fill="#D72C0D"
            />
          </svg>
        </div>
      </div>
      <div
        className="p-6"
        style={{
          boxShadow: "0px 4px 10px 4px rgba(130, 130, 130, 0.05)",
          borderEndEndRadius: 16,
          borderEndStartRadius: 16,
        }}
      >
        <div style={{ height: 30 }}>
          {/**Platform image */}
          <PlatformLogo
            logo_url={platformDashboardMapping?.platform_logo_url as string}
          />
        </div>
        {platformDashboardMapping?.dashboard_array.map((dashboard, index) => {
          return (
            <div className="mt-10" key={dashboard?.brand_power_bi_dashboard_id}>
              <div className="flex items-center" style={{ height: 32 }}>
                <div
                  style={{
                    width: 4,
                    height: 32,
                    backgroundColor: dashboard?.color,
                  }}
                ></div>
                <div
                  className="ml-2"
                  style={{ fontSize: 16, fontWeight: 700, lineHeight: "24px" }}
                >
                  {dashboard?.dashboard_type}
                </div>
                <span
                  onClick={() =>
                    removeDashboard(
                      dashboard?.brand_power_bi_dashboard_type_id,
                      dashboard?.dashboard_type,
                      platformDashboardMapping?.platform_id
                    )
                  }
                  className="cursor-pointer"
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: "20px",
                    textDecoration: "underline",
                    color: STATUS.S_Red,
                    marginLeft: 12,
                  }}
                >
                  Remove
                </span>
              </div>
              <div className="flex mt-4" style={{}}>
                <div
                  className="flex flex-col justify-between"
                  style={{ marginRight: 60 }}
                >
                  <div
                    className="mb-1"
                    style={{ fontSize: 10, fontWeight: 500, color: BLACK.B_40 }}
                  >
                    Workspace ID
                  </div>
                  <Input
                    placeholder="Workspace ID"
                    value={dashboard?.workspace_id}
                    sx={{
                      "--Input-focusedThickness": "0px",
                      width: "390px",
                      height: "52px",
                      border: `1px solid ${BLACK.B_5}`,
                      fontSize: 14,
                      fontWeight: 400,
                      lineHeight: "20px",
                      backgroundColor: WHITE.W_100,
                      boxShadow: "none",
                    }}
                    name="workspace_id"
                    onChange={(e) =>
                      editDashboard(
                        e.target.name,
                        e.target.value,
                        dashboard?.brand_power_bi_dashboard_type_id,
                        platformDashboardMapping?.platform_id
                      )
                    }
                  />
                </div>
                <div className="flex flex-col justify-between">
                  <div
                    className="mb-1"
                    style={{ fontSize: 10, fontWeight: 500, color: BLACK.B_40 }}
                  >
                    Report ID
                  </div>
                  <Input
                    placeholder="Report ID"
                    value={dashboard?.report_id}
                    sx={{
                      "--Input-focusedThickness": "0px",
                      width: "390px",
                      height: "52px",
                      border: `1px solid ${BLACK.B_5}`,
                      fontSize: 14,
                      fontWeight: 400,
                      lineHeight: "20px",
                      backgroundColor: WHITE.W_100,
                      boxShadow: "none",
                    }}
                    name="report_id"
                    onChange={(e) =>
                      editDashboard(
                        e.target.name,
                        e.target.value,
                        dashboard?.brand_power_bi_dashboard_type_id,
                        platformDashboardMapping?.platform_id
                      )
                    }
                  />
                </div>
              </div>
            </div>
          );
        })}

        <div
          onClick={() => setAddDashboardModalToggle(true)}
          className="flex items-center cursor-pointer w-fit"
          style={{ marginTop: 14 }}
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M7.33325 8.66665H3.99992C3.81103 8.66665 3.6527 8.60276 3.52492 8.47498C3.39714 8.3472 3.33325 8.18887 3.33325 7.99998C3.33325 7.81109 3.39714 7.65276 3.52492 7.52498C3.6527 7.3972 3.81103 7.33331 3.99992 7.33331H7.33325V3.99998C7.33325 3.81109 7.39714 3.65276 7.52492 3.52498C7.6527 3.3972 7.81103 3.33331 7.99992 3.33331C8.18881 3.33331 8.34714 3.3972 8.47492 3.52498C8.6027 3.65276 8.66659 3.81109 8.66659 3.99998V7.33331H11.9999C12.1888 7.33331 12.3471 7.3972 12.4749 7.52498C12.6027 7.65276 12.6666 7.81109 12.6666 7.99998C12.6666 8.18887 12.6027 8.3472 12.4749 8.47498C12.3471 8.60276 12.1888 8.66665 11.9999 8.66665H8.66659V12C8.66659 12.1889 8.6027 12.3472 8.47492 12.475C8.34714 12.6028 8.18881 12.6666 7.99992 12.6666C7.81103 12.6666 7.6527 12.6028 7.52492 12.475C7.39714 12.3472 7.33325 12.1889 7.33325 12V8.66665Z"
                fill="#1886FE"
              />
            </svg>
          </span>
          <span
            style={{
              fontSize: 14,
              fontWeight: 500,
              lineHeight: "20px",
              textDecoration: "underline",
              color: PRIMARY.P_BLue,
            }}
          >
            Add Dashboard
          </span>
        </div>

        {AddDashboardModalToggle && (
          <AddDashboardModal
            dashboard_type_array={dashboard_type_array}
            setAddDashboardModalToggle={setAddDashboardModalToggle}
            addNewDashboard={addNewDashboard}
            platformId={platformDashboardMapping?.platform_id}
          />
        )}
      </div>
    </div>
  );
};

export default PlatformDashboardMappingEdit;
