import { Button, Input, Modal } from "@mui/joy"
import { BASE_URL, BLACK, PRIMARY, WHITE } from "../../appConstants";
import MultiSelect from "../commonComponents/MultiSelect";
import React, { useEffect, useState } from "react";
import axios from "axios";
import appError from "../../appError";
import Joi from "joi";
import toast from "react-hot-toast";

type Platform = {
    platform_id: string;
    platform: string;
}
type BrandPlatformModalProps = {
    setToggleModal: (value: boolean) => void;
}
const BrandPlatformModal: React.FC<BrandPlatformModalProps> = ({ setToggleModal }) => {

    const [platforms, setPlatforms] = useState<Platform[]>([]);
    const [loading, setLoading] = useState(false);

    type State = {
        brand_name: string;
        company_name: string;
    }
    const [state, setState] = useState<State>({} as State);

    useEffect(() => {
        (async () => {
            const token = localStorage.getItem("token");
            let api = `${BASE_URL}onboarding/platforms`;

            const headers = {
                "Authorization": `Bearer ${token}`
            }
            try {
                const result = await axios.get(api, { headers });
                const { platform_array } = result?.data?.data;
                setPlatforms(platform_array);
            } catch (error) {

            } finally {

            }

        })();

    }, []);

    const [selectedPlatforms, setSelectedPlatforms] = useState<Set<string>>(new Set([]));


    const onChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
        if (e.target.checked) {
            setSelectedPlatforms(new Set([...Array.from(selectedPlatforms), id]));
        } else {
            selectedPlatforms.delete(id);
            setSelectedPlatforms(new Set([...Array.from(selectedPlatforms)]))
        }
    }
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setState((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const onSaveClickHandler = async () => {
        const platform_ids = Array.from(selectedPlatforms);
        if (platform_ids.length === 0) {
            appError('Please select a "Platform"');
            return;
        }
        const schema = Joi.object({
            brand_name: Joi.string().required().min(1).label("Brand Name"),
            company_name: Joi.string().required().min(1).label("Company Name")
        });

        const validate = schema.validate(state);
        if (validate.error) {
            console.log(validate.error.message);
            appError(validate?.error.message);
            return;
        }
        try {
            setLoading(true);
            const token = localStorage.getItem("token");
            const res = await axios.post(`${BASE_URL}brand/brandPlatformMapping`,
                { ...validate?.value, platform_ids },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }
            );
            const { message } = res?.data?.data;
            toast.success(message);
            setToggleModal(false);
        } catch (error) {
            appError(error);
        } finally {
            setLoading(false);
        }

    }

    let platformList = platforms.map((platform) => {
        return { id: platform.platform_id, label: platform.platform }
    })
    return (
        <Modal
            open={true}

            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >

            <div style={{ height: 414, width: 790, backgroundColor: WHITE.W_100, borderRadius: 18, padding: 40 }} >
                <div className="flex flex-col justify-between" style={{ height: 46 }} >
                    <div style={{ fontSize: 16, fontWeight: 600, lineHeight: "24px" }}>Create a New Brand</div>
                    <div style={{ fontSize: 12, fontWeight: 400, lineHeight: "18px", color: BLACK.B_40 }} >Provide the necessary details to add a new brand</div>
                </div>

                <div className="flex mt-6" >
                    <div className="w-full" style={{ marginRight: 16 }} >
                        <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_80 }} >Brand Name</div>
                        <Input
                            placeholder="Enter the brand name"

                            sx={{
                                "--Input-focusedThickness": "0px",

                                height: "52px",
                                border: `1px solid ${BLACK.B_5}`,
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: "20px",
                                backgroundColor: WHITE.W_100,
                                boxShadow: "none"
                            }}
                            name="brand_name"
                            onChange={onChangeHandler}
                        />
                    </div>
                    <div className="w-full" style={{}} >
                        <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_80 }} >Brand Name</div>
                        <Input
                            placeholder="Enter the company name"
                            sx={{
                                "--Input-focusedThickness": "0px",
                                // width: "340px",
                                height: "52px",
                                border: `1px solid ${BLACK.B_5}`,
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: "20px",
                                backgroundColor: WHITE.W_100,
                                boxShadow: "none"
                            }}
                            name="company_name"
                            onChange={onChangeHandler}
                        />
                    </div>
                </div>
                <div className="flex mt-6" >
                    <div className="w-full" style={{ marginRight: 16 }} >
                        <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_80 }} >Select Platform</div>
                        <MultiSelect
                            children={platformList}
                            selected={selectedPlatforms}
                            onChange={onChange}
                            placeholder="Select Platform"
                        />
                    </div>
                    <div className="w-full" style={{}} >

                    </div>
                </div>

                <div className="flex flex-row justify-between mt-10">
                    <Button color="neutral"
                        sx={{
                            height: "48px",
                            width: "340px",
                            backgroundColor: BLACK.B_5,
                            color: BLACK.B_100,
                            ":hover": {
                                backgroundColor: BLACK.B_20,
                            }
                        }}
                        onClick={() => setToggleModal(false)}
                    >Cancel</Button>
                    <Button
                        loading={loading}
                        onClick={onSaveClickHandler}

                        sx={{
                            height: "48px",
                            width: "340px",
                            backgroundColor: PRIMARY.P_BLue,
                        }}
                    >Save</Button>
                </div>
            </div>
        </Modal>
    )
}

export default BrandPlatformModal;  