import toast from "react-hot-toast"

const appError = (error, component) => {
   
    if (error?.response?.status === 401 && component != "auth") {
        window.location.href = "/login";
        return;
    }
    let errorMessage = ""
    if (error?.response?.data?.status === "error") {
        errorMessage = error?.response?.data?.data?.message;
    } else if (error.message) {
        errorMessage = error.message;
    } else if (typeof error === "string") {
        errorMessage = error;
    }
    return toast.error(errorMessage);
}

export default appError;