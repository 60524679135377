import { Checkbox, Input } from "@mui/joy";
import React, { useEffect } from "react";
import { BLACK, WHITE } from "../../appConstants";


type Children = {
    id: string;
    label: string;
}

type MultiSelectProps = {
    children: Children[];
    selected: Set<string>;
    placeholder: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
}
const MultiSelect: React.FC<MultiSelectProps> = ({ children, selected, onChange, placeholder }) => {

    const inputRef = React.useRef<HTMLInputElement>(null);
    const dropdownRef = React.useRef<HTMLDivElement>(null);
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            if (inputRef.current && !inputRef.current.contains(e.target as Node) && dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
                setOpen(false);
            }
           
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, []);

    const displaySelected = () => {
        let selectedChildren = "";
        children?.map((child) => {
            if (selected.has(child.id)) {
                selectedChildren += child.label + ", ";
            }
        })
        if (selectedChildren === "") {
            return <div style={{ fontSize: 14, fontWeight: 400, lineHeight: "20px", color: BLACK.B_40 }} >{placeholder}</div>;
        }
        return selectedChildren.slice(0, -2);
    }


    return (
        <div >
            <div onClick={() => setOpen(toggle => !toggle)} className="flex items-center justify-between  px-4" ref={inputRef} style={{ height: 52, borderRadius: 8, border: `1px solid ${BLACK.B_5}` }} >
                <div style={{ fontSize: 14, fontWeight: 400, lineHeight: "20px" }}>
                    {displaySelected()}
                </div>
                {
                    open ? <>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M10.0003 9L6.75033 12.25C6.59755 12.4028 6.4031 12.4792 6.16699 12.4792C5.93088 12.4792 5.73644 12.4028 5.58366 12.25C5.43088 12.0972 5.35449 11.9028 5.35449 11.6667C5.35449 11.4306 5.43088 11.2361 5.58366 11.0833L9.41699 7.25C9.58366 7.08333 9.7781 7 10.0003 7C10.2225 7 10.417 7.08333 10.5837 7.25L14.417 11.0833C14.5698 11.2361 14.6462 11.4306 14.6462 11.6667C14.6462 11.9028 14.5698 12.0972 14.417 12.25C14.2642 12.4028 14.0698 12.4792 13.8337 12.4792C13.5975 12.4792 13.4031 12.4028 13.2503 12.25L10.0003 9Z" fill="#1C1C1C" fill-opacity="0.4" />
                        </svg>
                    </> : <>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M9.99984 12.4791C9.88873 12.4791 9.78456 12.4618 9.68734 12.4271C9.59012 12.3923 9.49984 12.3333 9.4165 12.25L5.58317 8.41665C5.43039 8.26387 5.354 8.06942 5.354 7.83331C5.354 7.5972 5.43039 7.40276 5.58317 7.24998C5.73595 7.0972 5.93039 7.02081 6.1665 7.02081C6.40262 7.02081 6.59706 7.0972 6.74984 7.24998L9.99984 10.5L13.2498 7.24998C13.4026 7.0972 13.5971 7.02081 13.8332 7.02081C14.0693 7.02081 14.2637 7.0972 14.4165 7.24998C14.5693 7.40276 14.6457 7.5972 14.6457 7.83331C14.6457 8.06942 14.5693 8.26387 14.4165 8.41665L10.5832 12.25C10.4998 12.3333 10.4096 12.3923 10.3123 12.4271C10.2151 12.4618 10.1109 12.4791 9.99984 12.4791Z" fill="#1C1C1C" fill-opacity="0.4" />
                        </svg>

                    </>
                }
            </div>

            {
                open && <div ref={dropdownRef} className="multiSelect" style={{ width: inputRef.current?.offsetWidth, position: "absolute", backgroundColor: WHITE.W_100, zIndex: 999, boxShadow: "0px 4px 10px 4px rgba(130, 130, 130, 0.10)", marginTop: 2, borderRadius: 8, maxHeight: 200, overflow: "scroll", scrollbarWidth: "none" }} >
                    <div style={{ height: 8 }}></div>
                    {
                        children?.map((child) => {
                            return (
                                <div key={child?.id} className="flex items-center  px-4 py-2" style={{ height: 34 }} >
                                    <Checkbox  size="sm" onChange={(e) => onChange(e, child?.id)} checked={selected?.has(child?.id)}  />
                                    <span className="ml-2" style={{ fontSize: 14, fontWeight: 400, lineHeight: "20px", color: BLACK.B_80 }} >{child?.label}</span>
                                </div>
                            )
                        })
                    }
                    <div style={{ height: 8 }}></div>
                </div>
            }

        </div>
    );
}


export default MultiSelect;