import {
  Button,
  CircularProgress,
  Input,
  Option,
  Select,
  selectClasses,
  Skeleton,
} from "@mui/joy";
import { BASE_URL, BLACK, PRIMARY, WHITE } from "../../appConstants";
import { useEffect, useState } from "react";

import PaginationComponent from "../commonComponents/PaginationComponent";

import { debounce } from "lodash";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import BrandStatusComponent from "../commonComponents/BrandStatusComponent";
import AddNewUserModal from "./AddNewUserModal";
import appError from "../../appError";
import BrandsAssign from "../commonComponents/BrandsAssign";
// import BrandsAssign from "./BrandsAssign";

const UserList: React.FC = () => {
  const navigate = useNavigate();
  const [toggleModal, setToggleModal] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [dataFetchingState, setDataFetchingState] = useState<string>("LOADING");
  const [totalRows, setTotalRows] = useState<number>(0);
  type User = {
    user_id: string; // UUID (string format)
    full_name: string; // Name of the user
    status: string; // User Status (Active/Inactive)
    user_type: string; // User Type (Manager/Analyst)
    email: string; // Email of User
    organisation: string; // Organisation name of User
  };
  const [users, setUsers] = useState<User[]>([]);
  type Brand = {
    brand_id: string;
    brand_name: string;
  };
  type FilterStateObj = {
    full_name?: string;
    limit: number;
    page: number;
  };
  const [filterState, setFilterState] = useState<FilterStateObj>({
    full_name: "",
    limit: 10,
    page: 1,
  });
  //--------------------Search Input-----------------------
  const [searchFileArray, setSearchFileArray] = useState<
    { full_name: string }[]
  >([]);
  const [searchuser, setSearchUser] = useState<string>("");
  const [searchFocus, setSearchFocus] = useState(false);

  const onUserInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value?.trimStart()) {
      setSearchFocus(true);
    } else {
      setSearchFocus(false);
      setFilterState({ page: 1, limit: 10 });
    }
    setSearchUser(e.target.value?.trimStart());
  };

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e?.key === "Enter") {
      setSearchFocus(false);
      setFilterState((prevState) => ({
        ...prevState,
        full_name: searchuser,
        page: 1,
        limit: 10,
      }));
    }
  };
  const selectSearchName = (value: string) => {
    setSearchUser(value);
    setSearchFocus(false);
    // setFilterState((prevState) => ({
    //   ...prevState,
    //   full_name: value,
    //   page: 1,
    //   limit: 10,
    // }));
  };

  const onSearchButtonClick = () => {
    setSearchFocus(false);
    setFilterState((prevState) => ({
      ...prevState,
      full_name: searchuser,
      page: 1,
      limit: 10,
    }));
  };

  const fetchProductSearchData = debounce(async () => {
    const token = localStorage.getItem("token");
    (async () => {
      try {
        let api = `${BASE_URL}user/getSuggestUser?full_name=${searchuser}`;
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const result = await axios.get(api, { headers });
        const { full_name_array } = result?.data?.data;
        setSearchFileArray(full_name_array);
      } catch (error) {
      } finally {
      }
    })();
  }, 500);

  const fetchusers = async () => {
    setDataFetchingState("LOADING");

    const token = localStorage.getItem("token");
    const { page, limit } = filterState;
    let api = `${BASE_URL}user/getUserFilter?page=${page}&limit=${limit}`;
    if (filterState.full_name) {
      api = `${api}&full_name=${filterState.full_name}`;
    }
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const result = await axios.get(api, { headers });
      const { full_name_array, totalDocuments } = result?.data?.data;
      setUsers(full_name_array);
      setTotalRows(totalDocuments);
      if (full_name_array.length === 0) {
        setDataFetchingState("EMPTY");
      } else {
        setDataFetchingState("SUCCESS");
      }
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    (async () => {
      setDataFetchingState("LOADING");

      const token = localStorage.getItem("token");
      const { page, limit } = filterState;
      let api = `${BASE_URL}user/getUserFilter?page=${page}&limit=${limit}`;
      if (filterState.full_name) {
        api = `${api}&full_name=${filterState.full_name}`;
      }
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      try {
        const result = await axios.get(api, { headers });
        const { full_name_array, totalDocuments } = result?.data?.data;
        setUsers(full_name_array);
        setTotalRows(totalDocuments);
        if (full_name_array.length === 0) {
          setDataFetchingState("EMPTY");
        } else {
          setDataFetchingState("SUCCESS");
        }
      } catch (error) {
      } finally {
      }
    })();
  }, [filterState.full_name, filterState.page, filterState.limit]);

  useEffect(() => {
    if (searchuser) {
      fetchProductSearchData.cancel();
      fetchProductSearchData();
    }
    return () => {
      fetchProductSearchData.cancel();
    };
  }, [searchuser]);
  //--------------------Search Input----------------------------

  const convertToNormalForm = (variable: string) => {
    // Capitalize the first letter of each word
    let result = variable
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    return result;
  };

  
  const BrandsAssignCmp: React.FC<{user_id : string; }> = ({user_id})=>{
    const [brands, setBrands] = useState<Brand[]>([]);
    const fetchBrands = async () => {
      if (!user_id) return;
      const token = localStorage.getItem("token");
      try {
        const api = `${BASE_URL}user/getBrandByUser?user_id=${user_id}`;
        const headers = { Authorization: `Bearer ${token}` };
        const result = await axios.get(api, { headers });
        const { brand_platform_mapping } = result?.data?.data;
       
        if (brand_platform_mapping) setBrands(brand_platform_mapping);
      } catch (error) {
        appError(error);
      }
    };
    // console.log(user_id);
    useEffect(()=>{
      fetchBrands();
    },[]);
    return (
      <BrandsAssign
        user_id={user_id}
        view={false}
        ipwidth="35%"
        brands={brands}
      />
    )
  }
  // useEffect(() => {
  //   if (users.length > 0) {
  //     users.map((user) => {
  //       fetchBrands(user.user_id);
  //     });
  //   }
  // }, [users.length]);

  return (
    <>
      <div className="px-10 pt-6">
        <div
          className="flex flex-row justify-between items-center px-6"
          style={{
            height: 96,
            borderRadius: 8,
            backgroundColor: PRIMARY.P_Purple50,
          }}
        >
          <div>
            <div
              className="mb-1"
              style={{ fontSize: 16, fontWeight: 600, lineHeight: "24px" }}
            >
              Add New User
            </div>
            <div
              style={{
                fontSize: 12,
                fontWeight: 400,
                lineHeight: "18px",
                color: BLACK.B_40,
              }}
            >
              {" "}
              Invite a new user and assign their role and permissions
            </div>
          </div>

          <Button
            // loading={loading}
            onClick={() => setToggleModal(true)}
            startDecorator={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M9.16663 10.8333H4.99996C4.76385 10.8333 4.56593 10.7535 4.40621 10.5937C4.24649 10.434 4.16663 10.2361 4.16663 9.99999C4.16663 9.76388 4.24649 9.56596 4.40621 9.40624C4.56593 9.24652 4.76385 9.16666 4.99996 9.16666H9.16663V4.99999C9.16663 4.76388 9.24649 4.56596 9.40621 4.40624C9.56593 4.24652 9.76385 4.16666 9.99996 4.16666C10.2361 4.16666 10.434 4.24652 10.5937 4.40624C10.7534 4.56596 10.8333 4.76388 10.8333 4.99999V9.16666H15C15.2361 9.16666 15.434 9.24652 15.5937 9.40624C15.7534 9.56596 15.8333 9.76388 15.8333 9.99999C15.8333 10.2361 15.7534 10.434 15.5937 10.5937C15.434 10.7535 15.2361 10.8333 15 10.8333H10.8333V15C10.8333 15.2361 10.7534 15.434 10.5937 15.5937C10.434 15.7535 10.2361 15.8333 9.99996 15.8333C9.76385 15.8333 9.56593 15.7535 9.40621 15.5937C9.24649 15.434 9.16663 15.2361 9.16663 15V10.8333Z"
                  fill="white"
                />
              </svg>
            }
            sx={{
              fontSize: 14,
              fontWeight: 500,
              lineHeight: "20px",
              height: "48px",
              width: "200px",
              backgroundColor: PRIMARY.P_BLue,
            }}
          >
            Add User
          </Button>
        </div>

        <div className="flex mt-6">
          {/**Search container */}
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              zIndex: 99,
              display: "flex",
              position: "relative",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Input
              onChange={onUserInputChange}
              value={searchuser}
              onKeyDown={onKeyDownHandler}
              placeholder="Search by name"
              startDecorator={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M17.9421 17.0578L14.0304 13.1469C15.1642 11.7857 15.7295 10.0398 15.6089 8.27244C15.4882 6.50506 14.6908 4.85223 13.3825 3.65779C12.0743 2.46334 10.3559 1.81926 8.58486 1.85951C6.81382 1.89976 5.12647 2.62125 3.87383 3.87389C2.62118 5.12653 1.89969 6.81388 1.85944 8.58492C1.8192 10.356 2.46328 12.0744 3.65772 13.3826C4.85217 14.6909 6.50499 15.4883 8.27238 15.6089C10.0398 15.7296 11.7856 15.1642 13.1468 14.0305L17.0577 17.9422C17.1158 18.0003 17.1848 18.0463 17.2606 18.0777C17.3365 18.1092 17.4178 18.1253 17.4999 18.1253C17.5821 18.1253 17.6634 18.1092 17.7392 18.0777C17.8151 18.0463 17.8841 18.0003 17.9421 17.9422C18.0002 17.8841 18.0463 17.8152 18.0777 17.7393C18.1091 17.6634 18.1253 17.5821 18.1253 17.5C18.1253 17.4179 18.1091 17.3366 18.0777 17.2607C18.0463 17.1848 18.0002 17.1159 17.9421 17.0578ZM3.12493 8.75C3.12493 7.63748 3.45483 6.54994 4.07292 5.62491C4.691 4.69989 5.56951 3.97892 6.59734 3.55317C7.62517 3.12743 8.75617 3.01604 9.84732 3.23308C10.9385 3.45012 11.9407 3.98585 12.7274 4.77252C13.5141 5.55919 14.0498 6.56147 14.2669 7.65261C14.4839 8.74376 14.3725 9.87475 13.9468 10.9026C13.521 11.9304 12.8 12.8089 11.875 13.427C10.95 14.0451 9.86245 14.375 8.74993 14.375C7.2586 14.3733 5.82882 13.7802 4.77429 12.7256C3.71975 11.6711 3.12659 10.2413 3.12493 8.75Z"
                    fill="#1C1C1C"
                    fill-opacity="0.4"
                  />
                </svg>
              }
              sx={{
                width: "320px",
                height: "40px",
                "--Input-focusedThickness": "0px",
                borderBottom: searchFocus ? "0px" : `1px solid ${BLACK.B_10}`,
                borderTop: `1px solid ${BLACK.B_10}`,
                borderLeft: `1px solid ${BLACK.B_10}`,
                borderRight: `1px solid ${BLACK.B_10}`,
                borderBottomRightRadius: searchFocus ? "0px" : "6px",
                borderBottomLeftRadius: searchFocus ? "0px" : "6px",
                backgroundColor: WHITE.W_100,
                fontSize: 14,
                fontWeight: 400,
                lineHeight: "20px",
                boxShadow: "none",
              }}
            />
            {searchFocus && (
              <div
                style={{
                  position: "absolute",
                  top: 40,
                  left: 0,
                  width: 320,
                  borderBottomRightRadius: "6px",
                  borderBottomLeftRadius: "6px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  borderBottom: `1px solid ${BLACK.B_10}`,
                  borderLeft: `1px solid ${BLACK.B_10}`,
                  borderRight: `1px solid ${BLACK.B_10}`,
                  backgroundColor: WHITE.W_100,
                }}
              >
                <div
                  style={{
                    width: 302,
                    flex: 1,
                    paddingTop: 16,
                    borderTop: `1px solid ${BLACK.B_10}`,
                    maxHeight: 200,
                    overflow: "auto",
                  }}
                >
                  {searchFileArray?.map((user) => {
                    return (
                      <div
                        key={user.full_name}
                        onClick={() => selectSearchName(user.full_name)}
                        style={{
                          cursor: "pointer",
                          marginBottom: 16,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          height: 26,
                        }}
                      >
                        <div
                          style={{
                            height: 20,
                            fontSize: 14,
                            fontWeight: 400,
                            color: BLACK.B_100,
                          }}
                        >
                          {user.full_name}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <Button
            sx={{
              height: 40,
              width: 120,
              fontSize: 14,
              fontWeight: 500,
              backgroundColor: PRIMARY.P_BLue,
              lineHeight: "20px",
              ":hover": {
                backgroundColor: PRIMARY.P_BLue,
              },
              marginLeft: "12px",
              borderRadius: 8,
            }}
            onClick={onSearchButtonClick}
          >
            Search
          </Button>
        </div>

        <div
          className="mt-6"
          style={{
            height: "calc(100vh - 376px)",
            borderRadius: 12,
            boxShadow: "0px 4px 10px 4px rgba(130, 130, 130, 0.05",
          }}
        >
          <div
            className="flex items-center w-full"
            style={{
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
              height: 56,
              fontSize: 14,
              fontWeight: 700,
              lineHeight: "20px",
              backgroundColor: PRIMARY.P_Purple50,
            }}
          >
            <div className="p-4" style={{ width: "20%" }}>
              User Name
            </div>
            <div className="p-4" style={{ width: "10%" }}>
              User Type
            </div>
            <div className="p-4" style={{ width: "20%" }}>
              Email ID
            </div>
            <div className="p-4" style={{ width: "15%" }}>
              Organisation
            </div>
            <div className="p-4" style={{ width: "35%" }}>
              Brand Assign
            </div>
          </div>

          {dataFetchingState === "LOADING" && (
            <div
              className="flex justify-center items-center"
              style={{
                position: "absolute",
                height: "calc(100vh - 486px)",
                width: "calc(100% - 80px)",
              }}
            >
              <CircularProgress />
            </div>
          )}

          {dataFetchingState === "EMPTY" && (
            <div
              className="flex justify-center items-center"
              style={{
                position: "absolute",
                height: "calc(100vh - 486px)",
                width: "calc(100% - 80px)",
              }}
            >
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: "20px",
                  color: BLACK.B_40,
                }}
              >
                No data found
              </div>
            </div>
          )}

          <div
            style={{ height: "calc(100vh - 486px)" }}
            className="overflow-y-auto"
          >
            {users.length > 0 &&
              users?.map((user) => {
                return (
                  <div
                    className="flex items-center w-full"
                    key={user?.user_id}
                    style={{
                      height: 52,
                      borderBottom: `1px solid ${BLACK.B_5}`,
                      color: BLACK.B_80,
                    }}
                  >
                    <div
                      className="p-4 flex items-center gap-2 self-stretch"
                      style={{ width: "20%" }}
                    >
                      {/* bg css remaining */}
                      <div
                        className="underline cursor-pointer"
                        onClick={() =>
                          navigate(
                            `/userManagement/userDetailView/${user?.user_id}`
                          )
                        }
                        style={{
                          textDecoration: "underline",
                          fontSize: 12,
                          fontWeight: 600,
                          lineHeight: "18px",
                          color: "#1C1C1C",
                        }}
                      >
                        {/* color remaining */}
                        {user?.full_name}
                      </div>
                      <div
                        className="flex justify-center items-center gap-1 px-2 py-1"
                        style={{
                          borderRadius: "4px",
                          fontSize: 10,
                          fontWeight: 500,
                          lineHeight: "normal",
                          color:
                            user?.status === "ACTIVE" ? "#007F5F" : "#D72C0D",
                          backgroundColor:
                            user?.status === "ACTIVE" ? "#E0F2F1" : "#FFEBEE",
                        }}
                      >
                        {convertToNormalForm(user?.status)}
                      </div>
                    </div>

                    <div
                      className="flex p-4 items-center gap-2 self-stretch"
                      style={{ width: "10%" }}
                    >
                      {/* bg color css remaining */}
                      <div
                        style={{
                          fontSize: 12,
                          fontWeight: 400,
                          fontStyle: "normal",
                          lineHeight: 18,
                        }}
                      >
                        {/* flex 1 0 0 remaining */}
                        {convertToNormalForm(user?.user_type)}
                      </div>
                    </div>

                    <div
                      className="flex p-4 items-center self-stretch gap-2"
                      style={{ width: "20%" }}
                    >
                      {/* bgcolor remaining */}
                      <div
                        style={{
                          fontSize: 12,
                          fontWeight: 400,
                          fontStyle: "normal",
                          lineHeight: 18,
                        }}
                      >
                        {/* flex 1 0 0 remaining */}
                        {user?.email}
                      </div>
                    </div>

                    <div
                      className="flex p-4 items-center self-stretch gap-2"
                      style={{ width: "15%" }}
                    >
                      {/* bgcolor remaining */}
                      <div
                        style={{
                          fontSize: 12,
                          fontWeight: 400,
                          fontStyle: "normal",
                          lineHeight: 18,
                        }}
                      >
                        {/* flex 1 0 0 remaining */}
                        {user?.organisation}
                      </div>
                    </div>

                    {/* <BrandsAssign
                      user_id={user.user_id}
                      view={false}
                      ipwidth="35%"
                      brands={brands}
                    /> */}
                    <BrandsAssignCmp user_id={user.user_id}/>
                  </div>
                );
              })}
          </div>

          <div
            className="flex justify-between items-center px-4"
            style={{
              borderTop: `1px solid ${BLACK.B_5}`,
              height: 60,
              borderBottomLeftRadius: 12,
              borderBottomRightRadius: 12,
            }}
          >
            <div className="flex items-center">
              <div
                className="mr-2"
                style={{ fontSize: 12, fontWeight: 400, lineHeight: "18px" }}
              >
                Rows per page:
              </div>
              <Select
                //  onChange={(e1, e2) => onSelectChangeHandler(e1, e2, "status")}
                onChange={(e, limit) =>
                  setFilterState((prevState) => ({
                    ...prevState,
                    page: 1,
                    limit: limit ? limit : 0 * 1,
                  }))
                }
                // placeholder="Select State"
                name="status"
                value={filterState.limit}
                indicator={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.56251 12.0625L6.54167 9.04171C6.50001 9.00004 6.46876 8.9549 6.44792 8.90629C6.42709 8.85768 6.41667 8.8056 6.41667 8.75004C6.41667 8.63893 6.45487 8.54171 6.53126 8.45837C6.60764 8.37504 6.70834 8.33337 6.83334 8.33337H13.1667C13.2917 8.33337 13.3924 8.37504 13.4688 8.45837C13.5451 8.54171 13.5833 8.63893 13.5833 8.75004C13.5833 8.77782 13.5417 8.87504 13.4583 9.04171L10.4375 12.0625C10.3681 12.132 10.2986 12.1806 10.2292 12.2084C10.1597 12.2362 10.0833 12.25 10 12.25C9.91667 12.25 9.84028 12.2362 9.77084 12.2084C9.70139 12.1806 9.63195 12.132 9.56251 12.0625Z"
                      fill="#1C1C1C"
                      fill-opacity="0.8"
                    />
                  </svg>
                }
                sx={{
                  "--Input-focusedThickness": "0px",
                  width: "80px",
                  height: "28px",
                  backgroundColor: WHITE.W_100,
                  color: BLACK.B_80,
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: "20px",
                  border: `1px solid ${BLACK.B_10}`,
                  [`& .${selectClasses.indicator}`]: {
                    transition: "0.2s",
                    [`&.${selectClasses.expanded}`]: {
                      transform: "rotate(-180deg)",
                    },
                  },
                }}
              >
                <Option value={10}>10</Option>
                <Option value={20}>20</Option>
                <Option value={50}>50</Option>
              </Select>
            </div>
            {/* ---------------------Pagination Component------------------ */}
            <PaginationComponent
              page={filterState.page}
              limit={filterState.limit}
              totalRows={totalRows}
              onChange={(page) =>
                setFilterState((prevState) => ({ ...prevState, page }))
              }
            />
            {/* ---------------------Pagination Component------------------ */}
          </div>
        </div>
      </div>
      {toggleModal && (
        <AddNewUserModal
          setToggleModal={setToggleModal}
          setRefresh={setRefresh}
          fetchusers={fetchusers}
        />
      )}
    </>
  );
};

export default UserList;
