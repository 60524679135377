import exp from "constants";
import React from "react";

const BrandStatusComponent: React.FC<{ status: string | undefined }> = ({ status }) => {

    const getBrandStatusComponent = (status: string) => {
        const statusMap = new Map([
            ["ACTIVE", "Active"],
            ["INACTIVE", "Inactive"]
        ]);
        if (status === "ACTIVE") {
            return (
                <div className="w-fit px-2 py-1" style={{ borderRadius: 4, fontSize: 10, fontWeight: 500, color: "#007F5F", backgroundColor: "#E0F2F1" }} >
                    {statusMap.get(status)}
                </div>
            )
        } else if (status === "INACTIVE") {
            return (
                <div className="w-fit px-2 py-1" style={{ borderRadius: 4, fontSize: 10, fontWeight: 500, color: "#D72C0D", backgroundColor: "#FFEBEE" }} >
                    {statusMap.get(status)}
                </div>
            )
        } else {
            <></>
        }
    }

    return (
        <>
            {status && getBrandStatusComponent(status)}
        </>
    )


}


export default BrandStatusComponent;