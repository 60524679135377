import { Autocomplete, AutocompleteOption, Button, DialogContent, DialogTitle, Input, List, ListItem, ListItemButton, ModalClose, Option, Select, selectClasses, createFilterOptions, Tooltip, Skeleton, CircularProgress } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { BASE_URL, BLACK, PRIMARY, WHITE } from "../../appConstants";
import Drawer from '@mui/joy/Drawer';
import { useSelector } from "react-redux";
import axios from "axios";
import appError from "../../appError";
import { UUID } from "crypto";
import toast from "react-hot-toast";
import PaginationComponent from "../commonComponents/PaginationComponent";
import { set } from "lodash";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { debounce } from "lodash";
type DropDownObject = {
    product_name: string,
    style_code: string,
    model_code: string,
    variant_code: string,
    sub_category_id: string
}


type ProductObject = {
    product_id: string,
    short_name: string,
    // product_name: string,
    product_code: string,
    sku: string,

    variant_code_: string,
    other_variant_code_: string,

    model_code_: string,
    other_model_code_: string,

    product_name_: string,
    other_product_name_: string,

    sub_category_id: string,
    sub_category: string,

    style_code_: string,
    other_style_code_: string,
}

type ProductMapObject = {
    short_name: string,
    product_code: string,
    sku: string,
    variant_code_: string,
    model_code_: string,
    product_name_: string,
    style_code_: string
}

const filter = createFilterOptions<string | undefined>();
const ProductMaster: React.FC = () => {

    const { brand_id: brandId } = useSelector((state: any) => state.appData);
    //const []
    const [dataFetchingState, setDataFetchingState] = useState<string>("LOADING");
    type FilterStateObj = {
        product_code?: string;
        limit: number;
        page: number;
    }
    const [totalRows, setTotalRows] = useState<number>(0);
    const [filterState, setFilterState] = useState<FilterStateObj>({
        product_code: "",
        limit: 10,
        page: 1
    });
    const [state, setState] = useState<ProductObject>({
        product_id: "",
        short_name: "",
        // product_name: "",
        product_code: "",
        sku: "",

        variant_code_: "",
        other_variant_code_: "",

        model_code_: "",
        other_model_code_: "",

        product_name_: "",
        other_product_name_: "",

        sub_category_id: "",
        sub_category: "",

        style_code_: "",
        other_style_code_: "",
    });

    const [editDrawer, setEditDrawer] = useState(false);
    const [unmappedProduct, setUnmappedProduct] = useState<ProductObject[]>([] as ProductObject[]);
    const [mappedProduct, setMappedProduct] = useState<Map<string, ProductObject>>(new Map<string, ProductObject>());
   
    const [lodingOnSave, setLoadingOnSave] = useState<boolean>(false);


    //

    const [productNameArray, setProductNameArray] = useState<string[]>([]);
    const [productNameSubCategoryIdMap, setProductNameSubCategoryIdMap] = useState<Map<string, string>>(new Map<string, string>());
    const [productNameStyleCodeMap, setProductNameStyleCodeMap] = useState<Map<string, string[]>>(new Map<string, string[]>());
    const [styleCodeModelCodeMap, setStyleCodeModelCodeMap] = useState<Map<string, string[]>>(new Map<string, string[]>());
    const [modelCodeVariantCodeMap, setModelCodeVariantCodeMap] = useState<Map<string, string[]>>(new Map<string, string[]>());
    //const [selectedMpn, setSelectedMpn] = useState<string>("");
    // const [mpnOpen, setMpnOpen] = useState<boolean>(false);

    type SubCategorybject = {
        sub_category_id: string,
        principal_category: string,
        main_category: string,
        category: string,
        sub_category: string
    }



    const [dropDown, setDropDown] = useState<DropDownObject[]>();
    const [subCategories, setSubCategories] = useState<SubCategorybject[]>([]);

    useEffect(() => {
        fetchDropDownData();
        fetchSubCategories();
    }, [brandId])


    const fetchSubCategories = async () => {
        try {
            const token = localStorage.getItem("token");

            const res = await axios.get(`${BASE_URL}onboarding/subCategories?brand_id=${brandId}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const { brand_sub_category_array } = res?.data?.data;
            setSubCategories(brand_sub_category_array);
        } catch (error) {
            appError(error);
        }
    }


    const fetchDropDownData = async () => {
        try {
            const token = localStorage.getItem("token");
            if (!brandId) {
                return;
            }

            const brandsRes = await axios.get(`${BASE_URL}onboarding/unmappedData?brand_id=${brandId}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const dropdown_array: DropDownObject[] = brandsRes?.data?.data?.dropdown_array;


            // const mpnSet = new Set<string>();
            // const masterMpnSet = new Set<string>();
            // const subCategorySet = new Set<string>();
            // const mainCategorySet = new Set<string>();
            const productNameStyleCodeMap = new Map<string, string[]>();
            const productNameSubCategoryIdMap = new Map<string, string>();
            const styleCodeModelCodeMap = new Map<string, string[]>();
            const modelCodeVariantCodeMap = new Map<string, string[]>();
            const prodcutNameSet = new Set<string>();
            for (let dropDown of dropdown_array) {
                const { model_code, product_name, style_code, variant_code, sub_category_id } = dropDown;
                if (product_name) {
                    prodcutNameSet.add(product_name);
                    if (sub_category_id) {
                        if (productNameSubCategoryIdMap.has(product_name)) {
                            productNameSubCategoryIdMap.set(product_name, sub_category_id)
                        } else {
                            productNameSubCategoryIdMap.set(product_name, sub_category_id)
                        }
                    }
                    if (style_code) {
                        if (productNameStyleCodeMap.has(product_name)) {
                            productNameStyleCodeMap.set(product_name, [...productNameStyleCodeMap.get(product_name) as string[], style_code])
                        } else {
                            productNameStyleCodeMap.set(product_name, [style_code])
                        }
                        if (model_code) {
                            if (styleCodeModelCodeMap.has(`${product_name}<>${style_code}`)) {
                                styleCodeModelCodeMap.set(`${product_name}<>${style_code}`, [...styleCodeModelCodeMap.get(`${product_name}<>${style_code}`) as string[], model_code])
                            } else {
                                styleCodeModelCodeMap.set(`${product_name}<>${style_code}`, [model_code])
                            }
                            if (variant_code) {
                                if (modelCodeVariantCodeMap.has(`${product_name}<>${style_code}<>${model_code}`)) {
                                    modelCodeVariantCodeMap.set(`${product_name}<>${style_code}<>${model_code}`, [...modelCodeVariantCodeMap.get(`${product_name}<>${style_code}<>${model_code}`) as string[], variant_code])
                                } else {
                                    modelCodeVariantCodeMap.set(`${product_name}<>${style_code}<>${model_code}`, [variant_code])
                                }
                            }
                        }
                    }
                }
                // const { product_name_, style_code_, model_code_, variant_code_ } = dropDown;
                // mpnSet.add(product_name_);
                // masterMpnSet.add(style_code_);
                // subCategorySet.add(model_code_);
                // mainCategorySet.add(variant_code_);
            }

            setProductNameArray(Array.from(prodcutNameSet));
            setProductNameSubCategoryIdMap(productNameSubCategoryIdMap);
            setProductNameStyleCodeMap(productNameStyleCodeMap);
            setStyleCodeModelCodeMap(styleCodeModelCodeMap);
            setModelCodeVariantCodeMap(modelCodeVariantCodeMap);

            // setMpn(Array.from(mpnSet));
            // setMasterMpn(Array.from(masterMpnSet));
            // setSubCategory(Array.from(subCategorySet));
            // setMainCategory(Array.from(mainCategorySet));

            setDropDown(dropdown_array);

        } catch (error) {

        } finally {

        }
    }


    const toggleDrawer = (open: boolean, product: ProductObject | null) => {
        // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        //     return;
        // }
        if (product) {
            setState(product);
        }
        setEditDrawer(open);
    };


    const onSelectChangeHandler = (name: string, value: string) => {

        setState(prevState => {

            if (value != "Other") {
                if (value === null) {
                    if (name === "product_name_") {
                        return {
                            ...prevState,
                            product_name_: "",
                            other_product_name_: "",
                            style_code_: "",
                            other_style_code_: "",
                            model_code_: "",
                            other_model_code_: "",
                            variant_code_: "",
                            other_variant_code_: ""
                        }
                    } else if (name === "style_code_") {
                        return {
                            ...prevState,
                            style_code_: "",
                            other_style_code_: "",
                            model_code_: "",
                            other_model_code_: "",
                            variant_code_: "",
                            other_variant_code_: ""
                        }
                    } else if (name === "model_code_") {
                        return {
                            ...prevState,
                            model_code_: "",
                            other_model_code_: "",
                            variant_code_: "",
                            other_variant_code_: ""
                        }
                    } else if (name === "variant_code_") {
                        return {
                            ...prevState,
                            variant_code_: "",
                            other_variant_code_: ""
                        }
                    } else {
                        return prevState
                    }
                } else {
                    // let obj = dropDown?.find(el => el[name as keyof DropDownObject] === value);
                    // if (!obj) {
                    //     return prevState
                    // }
                    if (name === "product_name_") {
                        const sub_category_id = productNameSubCategoryIdMap.get(value);
                        const sub_category = subCategories.find(el => el.sub_category_id === sub_category_id)?.sub_category;
                        return {
                            ...prevState,
                            product_name_: value,
                            sub_category_id: sub_category_id ? sub_category_id : "",
                            sub_category: sub_category ? sub_category : "",
                            // style_code_: obj?.style_code_,
                            // model_code_: obj?.model_code_,
                            // variant_code_: obj?.variant_code_
                        }
                    } else if (name === "style_code_") {
                        return {
                            ...prevState,
                            style_code_: value,
                            // model_code_: obj?.model_code_,
                            // variant_code_: obj?.variant_code_
                        }
                    } else if (name === "model_code_") {
                        return {
                            ...prevState,
                            model_code_: value,
                            // variant_code_: obj?.variant_code_
                        }
                    } else if (name === "variant_code_") {
                        return {
                            ...prevState,
                            variant_code_: value
                        }
                    } else {
                        return prevState
                    }
                }

            } else {
                if (name === "product_name_") {

                    return {
                        ...prevState,
                        product_name_: value,
                        sub_category_id: "",
                        sub_category: "",
                        // style_code_: "",
                        // model_code_: "",
                        // variant_code_: ""
                    }
                } else if (name === "style_code_") {
                    return {
                        ...prevState,
                        style_code_: value,
                        // model_code_: "",
                        // variant_code_: ""
                    }
                } else if (name === "model_code_") {
                    return {
                        ...prevState,
                        model_code_: value,
                        variant_code_: ""
                    }
                } else if (name === "variant_code_") {
                    return {
                        ...prevState,
                        variant_code_: value
                    }
                } else {
                    return prevState
                }

            }
        });
    }

    const onInputChangeHandler = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setState(prevState => ({ ...prevState, [name]: value }))
    }


    const onSaveClickHandler = async () => {
        let product = {} as ProductObject;

        let tempState = {} as ProductObject;
        for (let [key, value] of Object.entries(state)) {
            tempState[key as keyof ProductObject] = value?.trim();
        }

        const {

            product_id,
            // product_name,
            product_code,
            sku,
            product_name_,
            other_product_name_,
            style_code_,
            other_style_code_,
            model_code_,
            other_model_code_,
            variant_code_,
            other_variant_code_,
            short_name,
            sub_category_id,
            sub_category
        } = tempState;




        if (!product_name_ || (product_name_ === "Other" && !other_product_name_)) {
            appError("Please provide Product Name.");
            return;
        }

        product["product_name_"] = product_name_ === "Other" ? "Other" : product_name_;
        product["other_product_name_"] = product_name_ === "Other" ? other_product_name_ : "";


        if (!style_code_ || (style_code_ === "Other" && !other_style_code_)) {
            appError("Please provide Style Code.");
            return;
        }
        product["style_code_"] = style_code_ === "Other" ? "Other" : style_code_;
        product["other_style_code_"] = style_code_ === "Other" ? other_style_code_ : "";

        if (!model_code_ || (model_code_ === "Other" && !other_model_code_)) {
            appError("Please provide Model Code.");
            return;
        }
        product["model_code_"] = model_code_ === "Other" ? "Other" : model_code_;
        product["other_model_code_"] = model_code_ === "Other" ? other_model_code_ : "";

        if (!variant_code_ || (variant_code_ === "Other" && !other_variant_code_)) {
            appError("Please provide Variant Code.");
            return;
        }
        product["variant_code_"] = variant_code_ === "Other" ? "Other" : variant_code_;
        product["other_variant_code_"] = variant_code_ === "Other" ? other_variant_code_ : "";


        if (!sub_category_id) {
            appError("Please select Sub Category.");
            return;
        }
        product["sub_category_id"] = sub_category_id;

        if (!short_name) {
            appError("Please provide Short Name.");
            return;
        }

        product["short_name"] = short_name;


        product["sku"] = sku;
        //product["short_name"] = short_name;
        product["product_code"] = product_code;
        //product["product_name"] = product_name;
        product["product_id"] = product_id;
        product["sub_category"] = sub_category;




        setMappedProduct(prevState => {
            let tempMap = new Map(prevState);
            tempMap.set(product?.product_id as string, product);
            return tempMap;
        })


        setUnmappedProduct(prevState => {
            let unmapped_product_array: ProductObject[] = [];
            for (let unmapped_product of prevState) {
                if (product?.product_id === unmapped_product?.product_id) {
                    unmapped_product_array.push(product);
                    continue;
                }
                unmapped_product_array.push(unmapped_product);
            }
            return unmapped_product_array;
        })



     

        setState({
            product_id: "",
            short_name: "",
            product_code: "",
            sku: "",

            variant_code_: "",
            other_variant_code_: "",

            model_code_: "",
            other_model_code_: "",

            product_name_: "",
            other_product_name_: "",

            sub_category_id: "",
            sub_category: "",
            style_code_: "",
            other_style_code_: "",

        });
        setEditDrawer(false);
    }


    const onMapDataClickHandler = async () => {

        type Product = {
            product_id: string;
            product_code: string;
            sku: string;

            product_name: string;
            style_code: string;
            model_code: string;
            variant_code: string;
            sub_category_id: string;
            short_name: string;

        }

        const product_array: Product[] = []

        try {


            for (let [key, value] of mappedProduct) {
                const product: Product = {} as Product;

                const {
                    product_id,
                    product_code,
                    sku,

                    product_name_,
                    other_product_name_,

                    style_code_,
                    other_style_code_,

                    model_code_,
                    other_model_code_,

                    variant_code_,
                    other_variant_code_,

                    sub_category_id,

                    short_name,

                } = value;
                product["product_id"] = product_id;
                product["product_code"] = product_code;
                product["sku"] = sku;

                product["product_name"] = product_name_ === "Other" ? other_product_name_ : product_name_;
                product["style_code"] = style_code_ === "Other" ? other_style_code_ : style_code_;
                product["model_code"] = model_code_ === "Other" ? other_model_code_ : model_code_;
                product["variant_code"] = variant_code_ === "Other" ? other_variant_code_ : variant_code_;
                product["sub_category_id"] = sub_category_id;
                product["short_name"] = short_name;

                product_array.push(product);

            }
            setLoadingOnSave(true);
            const token = localStorage.getItem("token");
            const res = await axios.post(`${BASE_URL}onboarding/map/products?brand_id=${brandId}`,
                { brand_product_array: product_array },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });

            const message = res?.data?.data?.message;
         
            setState({} as ProductObject);
            setEditDrawer(false);
            setMappedProduct(new Map<string, ProductObject>());
            fetchUnmappedProduct();
            fetchDropDownData();
            toast.success(message);
        } catch (error) {
            appError(error);
        } finally {
            setLoadingOnSave(false);
        }
    }



    // Column Definitions: Defines the columns to be displayed.

    const EditRowComponent = ({ data }: { data: ProductObject }) => {

        return (
            <div onClick={() => toggleDrawer(true, data)} className="flex justify-center items-center cursor-pointer" style={{ height: 52 }} >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M4.16667 15.8333H5.35417L13.5 7.6875L12.3125 6.5L4.16667 14.6458V15.8333ZM3.33333 17.5C3.09722 17.5 2.89931 17.4201 2.73958 17.2604C2.57986 17.1007 2.5 16.9028 2.5 16.6667V14.6458C2.5 14.4236 2.54167 14.2118 2.625 14.0104C2.70833 13.809 2.82639 13.6319 2.97917 13.4792L13.5 2.97917C13.6667 2.82639 13.8507 2.70833 14.0521 2.625C14.2535 2.54167 14.4653 2.5 14.6875 2.5C14.9097 2.5 15.125 2.54167 15.3333 2.625C15.5417 2.70833 15.7222 2.83333 15.875 3L17.0208 4.16667C17.1875 4.31944 17.309 4.5 17.3854 4.70833C17.4618 4.91667 17.5 5.125 17.5 5.33333C17.5 5.55556 17.4618 5.76736 17.3854 5.96875C17.309 6.17014 17.1875 6.35417 17.0208 6.52083L6.52083 17.0208C6.36806 17.1736 6.19097 17.2917 5.98958 17.375C5.78819 17.4583 5.57639 17.5 5.35417 17.5H3.33333ZM12.8958 7.10417L12.3125 6.5L13.5 7.6875L12.8958 7.10417Z" fill="#1C1C1C" fill-opacity="0.8" />
                </svg>
            </div>
        )
    }


    const ProductNameComponent = ({ data, name, otherName }: { data: ProductObject, name: string, otherName: string }) => {

        return (
            <>
                {
                    (
                        () => {
                            if (data[name as keyof ProductObject] === "Other") {
                                return (data[otherName as keyof ProductObject] ? data[otherName as keyof ProductMapObject] : "")
                            } else {
                                return (data[name as keyof ProductObject] ? data[name as keyof ProductMapObject] : "")
                            }
                        }
                    )()
                }
            </>
        )
    }
    const [colDefs, setColDefs] = useState<ColDef[]>([
        { headerName: "Platform Code", field: "product_code", pinned: "left" },
        { headerName: "SKU ID", field: "sku", pinned: "left" },
        { headerName: "Product Name", field: "product_name", cellRenderer: ProductNameComponent, cellRendererParams: { name: "product_name_", otherName: "other_product_name_" } },
        { headerName: "Style Code", field: "style_code", cellRenderer: ProductNameComponent, cellRendererParams: { name: "style_code_", otherName: "other_style_code_" } },
        { headerName: "Model Code", field: "model_code", cellRenderer: ProductNameComponent, cellRendererParams: { name: "model_code_", otherName: "other_model_code_" } },
        { headerName: "Variant Code", field: "variant_code", cellRenderer: ProductNameComponent, cellRendererParams: { name: "variant_code_", otherName: "other_variant_code_" } },
        { headerName: "Sub Category", field: "sub_category", cellRenderer: ProductNameComponent, cellRendererParams: { name: "sub_category", otherName: "sub_category" } },
        { headerName: "Short Name", field: "short_name", cellRenderer: ProductNameComponent, cellRendererParams: { name: "short_name", otherName: "short_name" } },
        { headerName: "Custom Tags", field: "custom_tags" },
        { headerName: "", field: "edit", cellRenderer: EditRowComponent, width: 52, pinned: "right" },
    ]);
    //--------------------Search Input-----------------------
    const [searchFileArray, setSearchFileArray] = useState<{ product_code: string }[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const [searchFocus, setSearchFocus] = useState(false);

    const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        if (e.target.value?.trimStart()) {
            setSearchFocus(true);
        } else {
            setSearchFocus(false);
            setFilterState({ page: 1, limit: 10 });
        }
        setSearchValue(e.target.value?.trimStart());
    }

    const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e?.key === "Enter") {
            setSearchFocus(false);
            // setFilterState((prevState) => ({ ...prevState, brand_name: searchValue, page: 1, limit: 10 }));
        }
    }
    const selectSearchValue = (value: string) => {
        setSearchValue(value);
        setSearchFocus(false);
        /// setFilterState((prevState) => ({ ...prevState, brand_name: value, page: 1, limit: 10 }));
    };

    const onSearchButtonClick = () => {

        setSearchFocus(false);
        setFilterState((prevState) => ({ ...prevState, product_code: searchValue, page: 1, limit: 10 }));
    }

    const fetchProductSearchData = debounce(async () => {
        const token = localStorage.getItem("token");
        (async () => {
            try {
                let api = `${BASE_URL}onboarding/unmappedData/getUnmappedProductsSuggestions?brand_id=${brandId}&product_code=${searchValue}`;
                const headers = {
                    "Authorization": `Bearer ${token}`
                }
                const result = await axios.get(api, { headers });
                const { product_array } = result?.data?.data;
                setSearchFileArray(product_array);
            } catch (error) {

            } finally {

            }
        })();
    }, 500)




    useEffect(() => {
        if (searchValue) {
            fetchProductSearchData.cancel();
            fetchProductSearchData();
        }
        return () => {
            fetchProductSearchData.cancel();
        }
    }, [searchValue]);


    useEffect(() => {
        fetchUnmappedProduct();
    }, [brandId, filterState.limit, filterState.page, filterState.product_code]);


    const fetchUnmappedProduct = async () => {

        try {

            setDataFetchingState("LOADING");

            const token = localStorage.getItem("token");
            if (!brandId) {
                return;
            }
            let api = `${BASE_URL}onboarding/unmappedData/products?brand_id=${brandId}&page=${filterState.page}&limit=${filterState.limit}`;


            if (filterState.product_code) {
                api += `&product_code=${filterState.product_code}`;
            }
            const unmappedProductRes = await axios.get(api, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });

            let { unmapped_product_array, total_records }: { unmapped_product_array: ProductObject[], total_records: number } = unmappedProductRes?.data?.data;


            let unmapped_product_array_new = unmapped_product_array.map((product: ProductObject) => {
                if (mappedProduct.has(product?.product_id)) {
                    return mappedProduct.get(product?.product_id) as ProductObject;
                } else {
                    return product;
                }
            })

            setUnmappedProduct(unmapped_product_array_new);
            setTotalRows(total_records);
            setDataFetchingState("SUCCESS");
        } catch (error) {
            setDataFetchingState("EMPTY");
            appError(error);
        } finally {

        }

    }




    //--------------------Search Input----------------------------


    const clearAllHandler = () => {
        setMappedProduct(new Map<string, ProductObject>());
        setUnmappedProduct(prevState => {
            let tempArray: ProductObject[] = [...prevState];
            tempArray = tempArray.map((product: ProductObject) => {
                return {
                    product_id: product.product_id,
                    product_code: product.product_code,
                    sku: product.sku
                } as ProductObject
            })
            return tempArray;
        }
        );
    }


    const onSelectSubCategoryHandler = (event: any, value: any) => {
        const subCategoryObj: SubCategorybject | undefined = subCategories.find(el => el.sub_category_id === value);

        if (subCategoryObj) {
            const { sub_category } = subCategoryObj
            setState(prevState => ({ ...prevState, sub_category_id: value, sub_category: sub_category }));
        }

    }

    //disabled
    const disabledStyleCodeSelect = (state.product_name_ && state.product_name_ != "Other") || state.other_product_name_ ? false : true;
    const disabledModelCodeSelect = (state.style_code_ && state.style_code_ != "Other") || state.other_style_code_ ? false : true;
    const disabledVariantCodeSelect = (state.model_code_ && state.model_code_ != "Other") || state.other_model_code_ ? false : true;



    //
    const styleCodes = state.product_name_ && state.product_name_ != "Other" ? productNameStyleCodeMap.get(state.product_name_) : [];
    const modelCodes = (state.product_name_ && state.product_name_ != "Other") && (state.style_code_ && state.style_code_ != "Other") ? styleCodeModelCodeMap.get(`${state?.product_name_}<>${state?.style_code_}`) : [];
    const variantCodes = (state.product_name_ && state.product_name_ != "Other") && (state.style_code_ && state.style_code_ != "Other") && (state.model_code_ && state.model_code_ != "Other") ? modelCodeVariantCodeMap.get(`${state?.product_name_}<>${state?.style_code_}<>${state?.model_code_}`) : [];


    const disabledSaveButton = mappedProduct.size > 0 ? true : false;
    return (
        <div className="flex flex-col pt-10 h-full" >
            <div className="h-full" >
                <div className="flex ml-10 mb-6">{/**Search container */}
                    <div onClick={(e) => e.stopPropagation()} style={{ zIndex: 99, display: "flex", position: "relative", flexDirection: "row", alignItems: "center" }} >
                        <Input
                            onChange={onFileInputChange}
                            value={searchValue}
                            onKeyDown={onKeyDownHandler}
                            placeholder="Search by brand name"
                            startDecorator={
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M17.9421 17.0578L14.0304 13.1469C15.1642 11.7857 15.7295 10.0398 15.6089 8.27244C15.4882 6.50506 14.6908 4.85223 13.3825 3.65779C12.0743 2.46334 10.3559 1.81926 8.58486 1.85951C6.81382 1.89976 5.12647 2.62125 3.87383 3.87389C2.62118 5.12653 1.89969 6.81388 1.85944 8.58492C1.8192 10.356 2.46328 12.0744 3.65772 13.3826C4.85217 14.6909 6.50499 15.4883 8.27238 15.6089C10.0398 15.7296 11.7856 15.1642 13.1468 14.0305L17.0577 17.9422C17.1158 18.0003 17.1848 18.0463 17.2606 18.0777C17.3365 18.1092 17.4178 18.1253 17.4999 18.1253C17.5821 18.1253 17.6634 18.1092 17.7392 18.0777C17.8151 18.0463 17.8841 18.0003 17.9421 17.9422C18.0002 17.8841 18.0463 17.8152 18.0777 17.7393C18.1091 17.6634 18.1253 17.5821 18.1253 17.5C18.1253 17.4179 18.1091 17.3366 18.0777 17.2607C18.0463 17.1848 18.0002 17.1159 17.9421 17.0578ZM3.12493 8.75C3.12493 7.63748 3.45483 6.54994 4.07292 5.62491C4.691 4.69989 5.56951 3.97892 6.59734 3.55317C7.62517 3.12743 8.75617 3.01604 9.84732 3.23308C10.9385 3.45012 11.9407 3.98585 12.7274 4.77252C13.5141 5.55919 14.0498 6.56147 14.2669 7.65261C14.4839 8.74376 14.3725 9.87475 13.9468 10.9026C13.521 11.9304 12.8 12.8089 11.875 13.427C10.95 14.0451 9.86245 14.375 8.74993 14.375C7.2586 14.3733 5.82882 13.7802 4.77429 12.7256C3.71975 11.6711 3.12659 10.2413 3.12493 8.75Z" fill="#1C1C1C" fill-opacity="0.4" />
                                </svg>
                            }
                            sx={{
                                width: "320px",
                                height: "40px",
                                "--Input-focusedThickness": "0px",
                                borderBottom: searchFocus ? "0px" : `1px solid ${BLACK.B_10}`,
                                borderTop: `1px solid ${BLACK.B_10}`,
                                borderLeft: `1px solid ${BLACK.B_10}`,
                                borderRight: `1px solid ${BLACK.B_10}`,
                                borderBottomRightRadius: searchFocus ? "0px" : "6px",
                                borderBottomLeftRadius: searchFocus ? "0px" : "6px",
                                backgroundColor: WHITE.W_100,
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: "20px",
                                boxShadow: "none",
                            }}
                        />
                        {
                            searchFocus &&
                            (
                                <div style={{
                                    position: "absolute",
                                    top: 40,
                                    left: 0,
                                    width: 320,
                                    borderBottomRightRadius: "6px",
                                    borderBottomLeftRadius: "6px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    borderBottom: `1px solid ${BLACK.B_10}`,
                                    borderLeft: `1px solid ${BLACK.B_10}`,
                                    borderRight: `1px solid ${BLACK.B_10}`,
                                    backgroundColor: WHITE.W_100
                                }}>
                                    <div style={{ width: 302, flex: 1, paddingTop: 16, borderTop: `1px solid ${BLACK.B_10}`, maxHeight: 200, overflow: "auto" }} >
                                        {
                                            searchFileArray?.map(file => {
                                                return (
                                                    <div
                                                        key={file.product_code}
                                                        onClick={() => selectSearchValue(file.product_code)}
                                                        style={{ cursor: "pointer", marginBottom: 16, display: "flex", flexDirection: "column", justifyContent: "space-between", height: 26 }} >
                                                        <div style={{ height: 20, fontSize: 14, fontWeight: 400, color: BLACK.B_100 }} >
                                                            {file.product_code}
                                                        </div>

                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <Button
                        sx={{
                            height: 40,
                            width: 120,
                            fontSize: 14,
                            fontWeight: 500,
                            backgroundColor: PRIMARY.P_BLue,
                            lineHeight: "20px",
                            ":hover": {
                                backgroundColor: PRIMARY.P_BLue
                            },
                            marginLeft: "12px",
                            borderRadius: 8
                        }}
                        onClick={onSearchButtonClick}
                    >Search</Button>
                </div>
                {/* Table */}
                {/* {
                    loadingUnmappedData ? (
                        <Skeleton
                            sx={{
                                height: "calc(100vh - 324px)",
                                width: "calc(100% - 80px)",
                                marginLeft: "40px",
                                borderRadius: 12,
                                opacity: "0.25",
                            }}
                        ></Skeleton>
                    ) : ( */}
                <div className="mx-10 ag-theme-quartz" style={{ height: "calc(100vh - 324px)", borderRadius: 12, boxShadow: "0px 4px 10px 4px rgba(130, 130, 130, 0.05" }} >

                    {dataFetchingState === "LOADING" && (
                        <div className="flex justify-center items-center" style={{ zIndex: 999, position: "absolute", height: "calc(100vh - 324px)", width: "calc(100% - 80px)" }} >
                            <CircularProgress />
                        </div>
                    )}
                    <div style={{ height: "calc(100vh - 384px)" }}  >
                        <AgGridReact
                            rowData={unmappedProduct}
                            columnDefs={colDefs}
                            headerHeight={56}
                            rowHeight={52}

                            gridOptions={{
                                overlayNoRowsTemplate: "<div></div>",
                            }}
                            className="ag-grid-table"
                        />
                    </div>



                    {/* <div className="flex items-center w-full" style={{ borderTopLeftRadius: 12, borderTopRightRadius: 12, height: 56, fontSize: 14, fontWeight: 700, lineHeight: "20px", backgroundColor: PRIMARY.P_Purple50 }}  >
                        <div className="p-4" style={{ width: "11%" }}>Platform Code</div>
                        <div className="p-4" style={{ width: "28%" }} >Product Name</div>
                        <div className="p-4" style={{ width: "15%" }} >SKU ID</div>
                        <div className="p-4" style={{ width: "9%" }} >MPN</div>
                        <div className="p-4" style={{ width: "9%" }} >Master MPN</div>
                        <div className="p-4" style={{ width: "14%" }} >Sub Category</div>
                        <div className="p-4" style={{ width: "11%" }} >Main Category</div>
                        <div className="p-4" style={{ width: "4%" }}  ></div>
                    </div>



                    {dataFetchingState === "LOADING" && (
                        <div className="flex justify-center items-center" style={{ position: "absolute", height: "calc(100vh - 440px)", width: "calc(100% - 80px)" }} >
                            <CircularProgress />
                        </div>
                    )}

                    {
                        dataFetchingState === "EMPTY" && (
                            <div className="flex justify-center items-center" style={{ position: "absolute", height: "calc(100vh - 440px)", width: "calc(100% - 80px)" }} >
                                <div style={{ fontSize: 14, fontWeight: 400, lineHeight: "20px", color: BLACK.B_40 }} >No data found</div>
                            </div>
                        )
                    }
                    <div style={{ height: "calc(100vh - 440px)" }} className="overflow-y-auto" >

                        {
                            unmappedProduct.length > 0 && unmappedProduct?.map(product => {
                                return (
                                    <div key={product.product_id} className="flex items-center w-full" style={{ height: 52, fontSize: 12, fontWeight: 400, lineHeight: "18px", borderBottom: `1px solid ${BLACK.B_5}` }}  >
                                        <div className="p-4" style={{ width: "11%" }}>{product?.product_code}</div>
                                        <div className="p-4" style={{ width: "28%" }} >
                                            <Tooltip
                                                sx={{
                                                    maxWidth: "400px",
                                                    color: WHITE.W_100,
                                                    backgroundColor: BLACK.B_100,
                                                    padding: "16px",
                                                    '& .MuiTooltip-tooltipArrow': {
                                                        backgroundColor: 'red' // Change this to the desired color
                                                    }
                                                }}

                                                title={product?.product_name} placement="bottom">
                                                <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }} >{product?.product_name}</div>
                                            </Tooltip>
                                        </div>
                                        <div className="p-4" style={{ width: "15%" }} >{product?.sku}</div>
                                        <div className="p-4" style={{ width: "9%" }} >
                                            {
                                                product?.product_name_ && product?.product_name_ !== "Other" ? product?.product_name_ : product?.product_name_ === "Other" ? product?.other_product_name_ : "-"
                                            }
                                        </div>
                                        <div className="p-4" style={{ width: "9%" }} >
                                            {
                                                product?.style_code_ && product?.style_code_ !== "Other" ? product?.style_code_ : product?.style_code_ === "Other" ? product?.other_style_code_ : "-"
                                            }
                                        </div>
                                        <div className="p-4" style={{ width: "14%" }} >
                                            {
                                                product?.model_code_ && product?.model_code_ !== "Other" ? product?.model_code_ : product?.model_code_ === "Other" ? product?.other_model_code_ : "-"
                                            }
                                        </div>
                                        <div className="p-4" style={{ width: "11%" }} >
                                            {
                                                product?.variant_code_ && product?.variant_code_ !== "Other" ? product?.variant_code_ : product?.variant_code_ === "Other" ? product?.other_variant_code_ : "-"
                                            }
                                        </div>
                                        <div className="p-4" style={{ width: "4%" }}  >
                                            <div className="cursor-pointer w-fit" onClick={() => toggleDrawer(true, product)} >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path d="M4.16667 15.8333H5.35417L13.5 7.6875L12.3125 6.5L4.16667 14.6458V15.8333ZM3.33333 17.5C3.09722 17.5 2.89931 17.4201 2.73958 17.2604C2.57986 17.1007 2.5 16.9028 2.5 16.6667V14.6458C2.5 14.4236 2.54167 14.2118 2.625 14.0104C2.70833 13.809 2.82639 13.6319 2.97917 13.4792L13.5 2.97917C13.6667 2.82639 13.8507 2.70833 14.0521 2.625C14.2535 2.54167 14.4653 2.5 14.6875 2.5C14.9097 2.5 15.125 2.54167 15.3333 2.625C15.5417 2.70833 15.7222 2.83333 15.875 3L17.0208 4.16667C17.1875 4.31944 17.309 4.5 17.3854 4.70833C17.4618 4.91667 17.5 5.125 17.5 5.33333C17.5 5.55556 17.4618 5.76736 17.3854 5.96875C17.309 6.17014 17.1875 6.35417 17.0208 6.52083L6.52083 17.0208C6.36806 17.1736 6.19097 17.2917 5.98958 17.375C5.78819 17.4583 5.57639 17.5 5.35417 17.5H3.33333ZM12.8958 7.10417L12.3125 6.5L13.5 7.6875L12.8958 7.10417Z" fill="#1C1C1C" fill-opacity="0.8" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div> */}

                    <div className="flex justify-between items-center px-4" style={{ borderTop: `1px solid ${BLACK.B_5}`, height: 60, borderBottomLeftRadius: 12, borderBottomRightRadius: 12 }} >
                        <div className="flex items-center">
                            <div className="mr-2" style={{ fontSize: 12, fontWeight: 400, lineHeight: "18px" }} >Rows per page:</div>
                            <Select
                                //  onChange={(e1, e2) => onSelectChangeHandler(e1, e2, "status")}
                                onChange={(e, limit) => setFilterState((prevState) => ({ ...prevState, page: 1, limit: limit ? limit : 0 * 1 }))}
                                // placeholder="Select State"
                                name="status"
                                value={filterState.limit}

                                indicator={
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M9.56251 12.0625L6.54167 9.04171C6.50001 9.00004 6.46876 8.9549 6.44792 8.90629C6.42709 8.85768 6.41667 8.8056 6.41667 8.75004C6.41667 8.63893 6.45487 8.54171 6.53126 8.45837C6.60764 8.37504 6.70834 8.33337 6.83334 8.33337H13.1667C13.2917 8.33337 13.3924 8.37504 13.4688 8.45837C13.5451 8.54171 13.5833 8.63893 13.5833 8.75004C13.5833 8.77782 13.5417 8.87504 13.4583 9.04171L10.4375 12.0625C10.3681 12.132 10.2986 12.1806 10.2292 12.2084C10.1597 12.2362 10.0833 12.25 10 12.25C9.91667 12.25 9.84028 12.2362 9.77084 12.2084C9.70139 12.1806 9.63195 12.132 9.56251 12.0625Z" fill="#1C1C1C" fill-opacity="0.8" />
                                    </svg>
                                }
                                sx={{
                                    "--Input-focusedThickness": "0px",
                                    width: "80px",
                                    height: "28px",
                                    backgroundColor: WHITE.W_100,
                                    color: BLACK.B_80,
                                    fontSize: 14,
                                    fontWeight: 400,
                                    lineHeight: "20px",
                                    border: `1px solid ${BLACK.B_10}`,
                                    [`& .${selectClasses.indicator}`]: {
                                        transition: '0.2s',
                                        [`&.${selectClasses.expanded}`]: {
                                            transform: 'rotate(-180deg)',
                                        },
                                    },


                                }}>
                                <Option value={10} >10</Option>
                                <Option value={20} >20</Option>
                                <Option value={50} >50</Option>

                            </Select>
                        </div>
                        {/* ---------------------Pagination Component------------------ */}
                        <PaginationComponent page={filterState.page} limit={filterState.limit} totalRows={totalRows} onChange={(page) => setFilterState(prevState => ({ ...prevState, page }))} />
                        {/* ---------------------Pagination Component------------------ */}
                    </div>
                </div>
                {/* )
                } */}


            </div>
            {
                disabledSaveButton && (
                    <div className="flex flex-row justify-end items-center px-10 py-4" style={{ height: 72, boxShadow: "0px -4px 40px 4px rgba(0, 0, 0, 0.05)" }}>
                        <Button
                            //loading={lodingOnSave}
                            sx={{
                                marginRight: "16px",
                                height: 40,
                                width: 220,
                                fontSize: 14,
                                fontWeight: 500,
                                backgroundColor: BLACK.B_5,
                                color: BLACK.B_100,
                                lineHeight: "20px",
                                ":hover": {
                                    backgroundColor: BLACK.B_5
                                },
                                borderRadius: 8
                            }}
                            disabled={lodingOnSave}
                            onClick={clearAllHandler}
                        >Clear All</Button>

                        <Button
                            loading={lodingOnSave}
                            sx={{
                                height: 40,
                                width: 220,
                                fontSize: 14,
                                fontWeight: 500,
                                backgroundColor: PRIMARY.P_BLue,
                                lineHeight: "20px",
                                ":hover": {
                                    backgroundColor: PRIMARY.P_BLue
                                },
                                borderRadius: 8
                            }}
                            onClick={onMapDataClickHandler}
                        >Map Data</Button>
                    </div>
                )
            }

            {/*  */}

            <Drawer
                key={'right'}
                anchor={"right"}
                open={editDrawer}
                onClose={() => toggleDrawer(false, null)}
                size="sm"
                sx={{

                }}


            >
                <div className="flex flex-row justify-between p-6" style={{ height: 72, borderBottom: `1px solid ${BLACK.B_10}` }} >
                    <div style={{ fontSize: 18, fontWeight: 500, lineHeight: "24px", color: BLACK.B_100 }} >Product Details</div>
                    <div className="w-fit cursor-pointer" onClick={() => toggleDrawer(false, null)} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M19.2806 18.2194C19.3502 18.289 19.4055 18.3718 19.4432 18.4628C19.4809 18.5539 19.5003 18.6514 19.5003 18.75C19.5003 18.8485 19.4809 18.9461 19.4432 19.0372C19.4055 19.1282 19.3502 19.2109 19.2806 19.2806C19.2109 19.3503 19.1281 19.4056 19.0371 19.4433C18.9461 19.481 18.8485 19.5004 18.7499 19.5004C18.6514 19.5004 18.5538 19.481 18.4628 19.4433C18.3717 19.4056 18.289 19.3503 18.2193 19.2806L11.9999 13.0603L5.78055 19.2806C5.63982 19.4213 5.44895 19.5004 5.24993 19.5004C5.05091 19.5004 4.86003 19.4213 4.7193 19.2806C4.57857 19.1399 4.49951 18.949 4.49951 18.75C4.49951 18.551 4.57857 18.3601 4.7193 18.2194L10.9396 12L4.7193 5.78061C4.57857 5.63988 4.49951 5.44901 4.49951 5.24999C4.49951 5.05097 4.57857 4.8601 4.7193 4.71936C4.86003 4.57863 5.05091 4.49957 5.24993 4.49957C5.44895 4.49957 5.63982 4.57863 5.78055 4.71936L11.9999 10.9397L18.2193 4.71936C18.36 4.57863 18.5509 4.49957 18.7499 4.49957C18.949 4.49957 19.1398 4.57863 19.2806 4.71936C19.4213 4.8601 19.5003 5.05097 19.5003 5.24999C19.5003 5.44901 19.4213 5.63988 19.2806 5.78061L13.0602 12L19.2806 18.2194Z" fill="#1C1C1C" />
                        </svg>
                    </div>
                </div>
                <DialogContent sx={{ paddingTop: "40px", paddingBottom: "16px", paddingX: "24px" }} >
                    <div className="mb-6" style={{ height: 72 }} >
                        <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_40 }} >Platform Code</div>
                        <Input
                            value={state.product_code}
                            sx={{
                                "--Input-focusedThickness": "0px",
                                ':focus-within': {
                                    border: `1px solid ${PRIMARY.P_BLue}`,
                                },
                                boxShadow: 'none',
                                height: 52,
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: "20px",
                                borderRadius: 8,
                                backgroundColor: BLACK.B_10
                            }}
                            placeholder="Add Platform Code"
                            disabled={true}

                        />
                    </div>
                    {/* <div className="mb-6" style={{ height: 72 }} >
                        <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_40 }} >Product Name</div>
                        <Input
                            value={state.product_name}
                            sx={{
                                "--Input-focusedThickness": "0px",
                                ':focus-within': {
                                    border: `1px solid ${PRIMARY.P_BLue}`,
                                },
                                boxShadow: 'none',
                                height: 52,
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: "20px",
                                borderRadius: 8,
                                backgroundColor: BLACK.B_10
                            }}
                            placeholder="Add Product Name"
                            disabled={true}
                        />
                    </div> */}
                    <div className="mb-6" style={{ height: 72 }} >
                        <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_40 }} >SKU ID</div>
                        <Input
                            value={state.sku}
                            sx={{
                                "--Input-focusedThickness": "0px",
                                ':focus-within': {
                                    border: `1px solid ${PRIMARY.P_BLue}`,
                                },
                                boxShadow: 'none',
                                height: 52,
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: "20px",
                                borderRadius: 8,
                                backgroundColor: BLACK.B_10

                            }}
                            placeholder="Add SKU ID"
                            disabled={true}
                        />
                    </div>
                    <div className="mb-6" style={{ height: 72 }} >
                        <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_40 }} >Product Name</div>
                        <Autocomplete

                            value={state?.product_name_}
                            placeholder="Search Value"
                            onChange={(event, newValue) => {

                                onSelectChangeHandler("product_name_", newValue);
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                if (params.inputValue !== '') {
                                    filtered.push("Other")
                                }

                                return filtered;
                            }}
                            options={productNameArray?.length ? productNameArray as readonly any[] : []}
                            getOptionLabel={(option) => {
                                // for example value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option;
                            }}
                            freeSolo
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            renderOption={(props, option) => (
                                <AutocompleteOption
                                    {...props}>{option}</AutocompleteOption>
                            )}
                            sx={{

                                width: "100%",
                                height: 52,
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: "20px",
                                borderRadius: 8,
                                "--Input-focusedThickness": "0px",
                                ':focus-within': {
                                    border: `1px solid ${PRIMARY.P_BLue}`,
                                },
                                boxShadow: 'none',

                            }}
                        // open={mpnOpen}
                        // onOpen={() => setMpnOpen(true)}
                        // onClose={() => setMpnOpen(false)}
                        // endDecorator={
                        //     !mpnOpen ? (
                        //         <svg onClick={() => setMpnOpen(true)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        //             <path d="M9.99984 12.4791C9.88873 12.4791 9.78456 12.4618 9.68734 12.4271C9.59012 12.3923 9.49984 12.3333 9.4165 12.25L5.58317 8.41665C5.43039 8.26387 5.354 8.06942 5.354 7.83331C5.354 7.5972 5.43039 7.40276 5.58317 7.24998C5.73595 7.0972 5.93039 7.02081 6.1665 7.02081C6.40262 7.02081 6.59706 7.0972 6.74984 7.24998L9.99984 10.5L13.2498 7.24998C13.4026 7.0972 13.5971 7.02081 13.8332 7.02081C14.0693 7.02081 14.2637 7.0972 14.4165 7.24998C14.5693 7.40276 14.6457 7.5972 14.6457 7.83331C14.6457 8.06942 14.5693 8.26387 14.4165 8.41665L10.5832 12.25C10.4998 12.3333 10.4096 12.3923 10.3123 12.4271C10.2151 12.4618 10.1109 12.4791 9.99984 12.4791Z" fill="#1C1C1C" fill-opacity="0.4" />
                        //         </svg>
                        //     ) : null
                        // }

                        />
                    </div>
                    {
                        state?.product_name_ === "Other" && (
                            <div className="mb-6" style={{ height: 72 }} >
                                <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_40 }} >Other Product Name</div>
                                <Input
                                    value={state.other_product_name_}
                                    name="other_product_name_"
                                    onChange={onInputChangeHandler}
                                    sx={{
                                        "--Input-focusedThickness": "0px",
                                        ':focus-within': {
                                            border: `1px solid ${PRIMARY.P_BLue}`,
                                        },
                                        boxShadow: 'none',
                                        height: 52,
                                        fontSize: 14,
                                        fontWeight: 400,
                                        lineHeight: "20px",
                                        borderRadius: 8,
                                    }}
                                    placeholder="Add Other Product Name"
                                />
                            </div>
                        )
                    }
                    <div className="mb-6" style={{ height: 72 }} >
                        <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_40 }} >Style Code</div>
                        <Autocomplete
                            disabled={disabledStyleCodeSelect}
                            value={state?.style_code_}
                            placeholder="Search Value"
                            onChange={(event, newValue) => {

                                onSelectChangeHandler("style_code_", newValue);
                            }}
                            filterOptions={(options, params) => {


                                const filtered = filter(options, params);

                                if (params.inputValue !== '') {
                                    filtered.push("Other")
                                }

                                return filtered;
                            }}
                            options={styleCodes?.length ? styleCodes as readonly any[] : []}
                            getOptionLabel={(option) => {
                                // for example value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option;
                            }}
                            freeSolo
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            renderOption={(props, option) => (
                                <AutocompleteOption
                                    {...props}>{option}</AutocompleteOption>
                            )}
                            sx={{
                                backgroundColor: disabledStyleCodeSelect ? BLACK.B_10 : null,
                                width: "100%",
                                height: 52,
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: "20px",
                                borderRadius: 8,
                                "--Input-focusedThickness": "0px",
                                ':focus-within': {
                                    border: `1px solid ${PRIMARY.P_BLue}`,
                                },
                                boxShadow: 'none',


                            }}
                        // open={mpnOpen}
                        // onOpen={() => setMpnOpen(true)}
                        // onClose={() => setMpnOpen(false)}
                        // endDecorator={
                        //     !mpnOpen ? (
                        //         <svg onClick={() => setMpnOpen(true)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        //             <path d="M9.99984 12.4791C9.88873 12.4791 9.78456 12.4618 9.68734 12.4271C9.59012 12.3923 9.49984 12.3333 9.4165 12.25L5.58317 8.41665C5.43039 8.26387 5.354 8.06942 5.354 7.83331C5.354 7.5972 5.43039 7.40276 5.58317 7.24998C5.73595 7.0972 5.93039 7.02081 6.1665 7.02081C6.40262 7.02081 6.59706 7.0972 6.74984 7.24998L9.99984 10.5L13.2498 7.24998C13.4026 7.0972 13.5971 7.02081 13.8332 7.02081C14.0693 7.02081 14.2637 7.0972 14.4165 7.24998C14.5693 7.40276 14.6457 7.5972 14.6457 7.83331C14.6457 8.06942 14.5693 8.26387 14.4165 8.41665L10.5832 12.25C10.4998 12.3333 10.4096 12.3923 10.3123 12.4271C10.2151 12.4618 10.1109 12.4791 9.99984 12.4791Z" fill="#1C1C1C" fill-opacity="0.4" />
                        //         </svg>
                        //     ) : null
                        // }

                        />
                    </div>
                    {
                        state?.style_code_ === "Other" && (
                            <div className="mb-6" style={{ height: 72 }} >
                                <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_40 }} >Other Style Code</div>
                                <Input
                                    onChange={onInputChangeHandler}
                                    name="other_style_code_"
                                    value={state.other_style_code_}
                                    sx={{
                                        "--Input-focusedThickness": "0px",
                                        ':focus-within': {
                                            border: `1px solid ${PRIMARY.P_BLue}`,
                                        },
                                        boxShadow: 'none',
                                        height: 52,
                                        fontSize: 14,
                                        fontWeight: 400,
                                        lineHeight: "20px",
                                        borderRadius: 8,
                                    }}
                                    placeholder="Add Other Style "
                                />
                            </div>
                        )
                    }
                    <div className="mb-6" style={{ height: 72 }} >
                        <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_40 }} >Model Code</div>
                        <Autocomplete
                            disabled={disabledModelCodeSelect}
                            value={state?.model_code_}
                            placeholder="Search Value"
                            onChange={(event, newValue) => {

                                onSelectChangeHandler("model_code_", newValue);
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                if (params.inputValue !== '') {
                                    filtered.push("Other")
                                }

                                return filtered;
                            }}
                            options={modelCodes?.length ? modelCodes as readonly any[] : []}
                            getOptionLabel={(option) => {
                                // for example value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option;
                            }}
                            freeSolo
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            renderOption={(props, option) => (
                                <AutocompleteOption
                                    {...props}>{option}</AutocompleteOption>
                            )}
                            sx={{
                                backgroundColor: disabledModelCodeSelect ? BLACK.B_10 : null,
                                width: "100%",
                                height: 52,
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: "20px",
                                borderRadius: 8,
                                "--Input-focusedThickness": "0px",
                                ':focus-within': {
                                    border: `1px solid ${PRIMARY.P_BLue}`,
                                },
                                boxShadow: 'none',

                            }}
                        // open={mpnOpen}
                        // onOpen={() => setMpnOpen(true)}
                        // onClose={() => setMpnOpen(false)}
                        // endDecorator={
                        //     !mpnOpen ? (
                        //         <svg onClick={() => setMpnOpen(true)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        //             <path d="M9.99984 12.4791C9.88873 12.4791 9.78456 12.4618 9.68734 12.4271C9.59012 12.3923 9.49984 12.3333 9.4165 12.25L5.58317 8.41665C5.43039 8.26387 5.354 8.06942 5.354 7.83331C5.354 7.5972 5.43039 7.40276 5.58317 7.24998C5.73595 7.0972 5.93039 7.02081 6.1665 7.02081C6.40262 7.02081 6.59706 7.0972 6.74984 7.24998L9.99984 10.5L13.2498 7.24998C13.4026 7.0972 13.5971 7.02081 13.8332 7.02081C14.0693 7.02081 14.2637 7.0972 14.4165 7.24998C14.5693 7.40276 14.6457 7.5972 14.6457 7.83331C14.6457 8.06942 14.5693 8.26387 14.4165 8.41665L10.5832 12.25C10.4998 12.3333 10.4096 12.3923 10.3123 12.4271C10.2151 12.4618 10.1109 12.4791 9.99984 12.4791Z" fill="#1C1C1C" fill-opacity="0.4" />
                        //         </svg>
                        //     ) : null
                        // }

                        />
                    </div>
                    {
                        state?.model_code_ === "Other" && (
                            <div className="mb-6" style={{ height: 72 }} >
                                <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_40 }} >Other Model Code</div>
                                <Input
                                    onChange={onInputChangeHandler}
                                    name="other_model_code_"
                                    value={state.other_model_code_}
                                    sx={{
                                        "--Input-focusedThickness": "0px",
                                        ':focus-within': {
                                            border: `1px solid ${PRIMARY.P_BLue}`,
                                        },
                                        boxShadow: 'none',
                                        height: 52,
                                        fontSize: 14,
                                        fontWeight: 400,
                                        lineHeight: "20px",
                                        borderRadius: 8,
                                    }}
                                    placeholder="Add Other Model Code"
                                />
                            </div>
                        )
                    }
                    <div className="mb-6" style={{ height: 72 }} >
                        <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_40 }} >Variant Code</div>
                        <Autocomplete
                            disabled={disabledVariantCodeSelect}
                            value={state?.variant_code_}
                            placeholder="Search Value"
                            onChange={(event, newValue) => {
                                onSelectChangeHandler("variant_code_", newValue);
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                if (params.inputValue !== '') {
                                    filtered.push("Other")
                                }

                                return filtered;
                            }}
                            options={variantCodes?.length ? variantCodes as readonly any[] : []}
                            getOptionLabel={(option) => {
                                // for example value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option;
                            }}
                            freeSolo
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            renderOption={(props, option) => (
                                <AutocompleteOption
                                    {...props}>{option}</AutocompleteOption>
                            )}
                            sx={{
                                backgroundColor: disabledVariantCodeSelect ? BLACK.B_10 : null,
                                width: "100%",
                                height: 52,
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: "20px",
                                borderRadius: 8,
                                "--Input-focusedThickness": "0px",
                                ':focus-within': {
                                    border: `1px solid ${PRIMARY.P_BLue}`,
                                },
                                boxShadow: 'none',

                            }}
                        // open={mpnOpen}
                        // onOpen={() => setMpnOpen(true)}
                        // onClose={() => setMpnOpen(false)}
                        // endDecorator={
                        //     !mpnOpen ? (
                        //         <svg onClick={() => setMpnOpen(true)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        //             <path d="M9.99984 12.4791C9.88873 12.4791 9.78456 12.4618 9.68734 12.4271C9.59012 12.3923 9.49984 12.3333 9.4165 12.25L5.58317 8.41665C5.43039 8.26387 5.354 8.06942 5.354 7.83331C5.354 7.5972 5.43039 7.40276 5.58317 7.24998C5.73595 7.0972 5.93039 7.02081 6.1665 7.02081C6.40262 7.02081 6.59706 7.0972 6.74984 7.24998L9.99984 10.5L13.2498 7.24998C13.4026 7.0972 13.5971 7.02081 13.8332 7.02081C14.0693 7.02081 14.2637 7.0972 14.4165 7.24998C14.5693 7.40276 14.6457 7.5972 14.6457 7.83331C14.6457 8.06942 14.5693 8.26387 14.4165 8.41665L10.5832 12.25C10.4998 12.3333 10.4096 12.3923 10.3123 12.4271C10.2151 12.4618 10.1109 12.4791 9.99984 12.4791Z" fill="#1C1C1C" fill-opacity="0.4" />
                        //         </svg>
                        //     ) : null
                        // }

                        />
                    </div>
                    {
                        state?.variant_code_ === "Other" && (
                            <div className="mb-6" style={{ height: 72 }} >
                                <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_40 }} >Other Variant Code</div>
                                <Input
                                    onChange={onInputChangeHandler}
                                    name="other_variant_code_"
                                    value={state.other_variant_code_}
                                    sx={{
                                        "--Input-focusedThickness": "0px",
                                        ':focus-within': {
                                            border: `1px solid ${PRIMARY.P_BLue}`,
                                        },
                                        boxShadow: 'none',
                                        height: 52,
                                        fontSize: 14,
                                        fontWeight: 400,
                                        lineHeight: "20px",
                                        borderRadius: 8,
                                    }}
                                    placeholder="Add Other Variant Code"
                                />
                            </div>
                        )
                    }
                    <div className="mb-6" style={{ height: 72 }} >
                        <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_40 }} >Sub Category</div>

                        <Select
                            value={state.sub_category_id}
                            onChange={onSelectSubCategoryHandler}
                            placeholder="Select Sub Category"
                            indicator={
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M9.99984 12.4791C9.88873 12.4791 9.78456 12.4617 9.68734 12.427C9.59012 12.3923 9.49984 12.3333 9.4165 12.2499L5.58317 8.41659C5.43039 8.26381 5.354 8.06936 5.354 7.83325C5.354 7.59714 5.43039 7.4027 5.58317 7.24992C5.73595 7.09714 5.93039 7.02075 6.1665 7.02075C6.40262 7.02075 6.59706 7.09714 6.74984 7.24992L9.99984 10.4999L13.2498 7.24992C13.4026 7.09714 13.5971 7.02075 13.8332 7.02075C14.0693 7.02075 14.2637 7.09714 14.4165 7.24992C14.5693 7.4027 14.6457 7.59714 14.6457 7.83325C14.6457 8.06936 14.5693 8.26381 14.4165 8.41659L10.5832 12.2499C10.4998 12.3333 10.4096 12.3923 10.3123 12.427C10.2151 12.4617 10.1109 12.4791 9.99984 12.4791Z" fill="#1C1C1C" fill-opacity="0.4" />
                                </svg>
                            }
                            sx={{
                                height: 52,
                                borderRadius: 8,
                                [`& .${selectClasses.indicator}`]: {
                                    transition: '0.2s',
                                    [`&.${selectClasses.expanded}`]: {
                                        transform: 'rotate(-180deg)',
                                    },
                                },
                            }}
                        >
                            {
                                subCategories?.map(el => {
                                    return <Option value={el.sub_category_id}  >{el.sub_category}</Option>
                                })
                            }
                        </Select>
                    </div>
                    <div className="mb-6" style={{ height: 72 }} >
                        <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_40 }} >Short Name</div>
                        <Input
                            onChange={onInputChangeHandler}
                            name="short_name"
                            value={state.short_name}
                            sx={{
                                "--Input-focusedThickness": "0px",
                                ':focus-within': {
                                    border: `1px solid ${PRIMARY.P_BLue}`,
                                },
                                boxShadow: 'none',
                                height: 52,
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: "20px",
                                borderRadius: 8,
                            }}
                            placeholder="Add Short Name"
                        />
                    </div>
                </DialogContent>
                <div className="flex items-center  px-6" style={{ height: 88, borderTop: `1px solid ${BLACK.B_10}` }} >
                    <Button
                        loading={lodingOnSave}
                        sx={{
                            height: 40,
                            width: "100%",
                            fontSize: 14,
                            fontWeight: 500,
                            backgroundColor: PRIMARY.P_BLue,
                            lineHeight: "20px",
                            ":hover": {
                                backgroundColor: PRIMARY.P_BLue
                            },
                            borderRadius: 8
                        }}
                        onClick={onSaveClickHandler}
                    >Save</Button>
                </div>
            </Drawer>
        </div>
    )
}

export default ProductMaster;