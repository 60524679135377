import axios from "axios";
import MultiSelect from "../commonComponents/MultiSelect";
import { SetStateAction, useEffect, useState, Dispatch } from "react";
import appError from "../../appError";
import { BASE_URL, BLACK, PRIMARY, WHITE } from "../../appConstants";

type Dashboard_arr = {
  dashboard_type_id: string;
  dashboard_type_name: string;
};
type Platform = {
  platform_id: string;
  platform_name: string;
  dashboard_type: Dashboard_arr[];
};
type Brand = {
  brand_id: string;
  dashboard_array: Platform[];
};
type Main = {
  full_name: string;
  email: string;
  contact_number: string;
  organisation: string;
  user_type: string;
  brand_array: Brand[];
};

type SelectDashboard = {
  platform_id: string;
  platform_name: string;
  brand_id: string;
  user_id: string;
  platform_arr: Platform[];
  setPlatform_arr: Dispatch<SetStateAction<Platform[]>>;
};
const SelectDashboard: React.FC<SelectDashboard> = ({
  platform_arr,
  setPlatform_arr,
  platform_id,
  platform_name,
  brand_id,
  user_id,
}) => {
  type Dashboard = {
    brand_power_bi_dashboard_type_id: string;
    dashboard_type_name: string;
  };
  const [selectedDashboard, setSelectedDashboard] = useState<Set<string>>(
    new Set([])
  );
  const [dashboards, setDashboards] = useState<Dashboard[]>([]);
  let dashboardList = dashboards.map((dashboard) => {
    return {
      id: dashboard.brand_power_bi_dashboard_type_id,
      label: dashboard.dashboard_type_name,
    };
  });
  
  const fetchsuggestdashboards = async () => {
    const token = localStorage.getItem("token");
    try {
      let api = `${BASE_URL}user/getDashboardTypeForAccess?brand_id=${brand_id}&platform_id=${platform_id}`;
      if (user_id !== "") {
        api = `${BASE_URL}user/getDashboardTypeForAccess?user_id=${user_id}&brand_id=${brand_id}&platform_id=${platform_id}`;
      }
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const result = await axios.get(api, { headers });
      const { dashboard_array } = result?.data?.data;
      setDashboards(dashboard_array);
    } catch (error) {
      appError(error);
    } finally {
    }
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    let set = new Set(selectedDashboard);
    if (e.target.checked) {
      set.add(id);
      setPlatform_arr((prevState) => {
        let newArray: Platform[] = prevState.map((platform) => {
          if (platform?.platform_id === platform_id) {
            let dashboard = platform.dashboard_type.find(
              (dashboard) => dashboard?.dashboard_type_id === id
            );
            if (!dashboard) {
              const foundDashboard = dashboardList.find(
                (dashboard) => dashboard.id === id
              );
              if (foundDashboard) {
                platform.dashboard_type = [
                  ...platform.dashboard_type,
                  {
                    dashboard_type_id: id,
                    dashboard_type_name: foundDashboard.label,
                  },
                ];
              }
            }
            return platform;
          } else {
            return platform;
          }
        });
        return newArray;
      });
    } else {
      if (set.has(id)) {
        set.delete(id);
        setPlatform_arr((prevState) => {
          let newArray: Platform[] = prevState.map((platform) => {
            if (platform?.platform_id === platform_id) {
              let dashboard = platform.dashboard_type.find(
                (dashboard) => dashboard?.dashboard_type_id === id
              );
              if (dashboard) {
                platform.dashboard_type = platform.dashboard_type.filter(
                  (dashboard) => dashboard.dashboard_type_id !== id
                );
              }
              return platform;
            } else {
              return platform;
            }
          });
          return newArray;
        });
      }
    }
    setSelectedDashboard(set);
  };

  useEffect(() => {
    fetchsuggestdashboards();
  }, []);
  // console.log("Select Dashboard "); // Error because lots of time rendering
  return (
    <div className="w-[48%] flex flex-col gap-[4px]">
      <div
        className="flex flex-col h-[20px] justify-center self-stretch"
        style={{
          fontSize: 14,
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "20px",
          color:"#5B5B5B"
        }}
      >
        Select Dashboards for {platform_name}
      </div>
      <MultiSelect
        children={dashboardList}
        selected={selectedDashboard}
        onChange={onChange}
        placeholder="Please Select"
      />
    </div>
  );
};

export default SelectDashboard;
