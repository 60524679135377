import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL, BLACK, PRIMARY, STATUS } from "../../appConstants";
import axios from "axios";
import { useEffect, useState } from "react";
import BrandStatusComponent from "../commonComponents/BrandStatusComponent";
import { set } from "lodash";
import PlatformDashboardMappingView from "./PlatformDashboardMappingView";
import { Skeleton } from "@mui/joy";




type BrandPlatform = {
    platform: string;
    platform_id: string;
};

type Brand = {
    brand_id: string;
    brand_name: string;
    company_name: string;
    status: string;
    platform_array: BrandPlatform[];
};




const BrandDetailView = () => {
    const navigate = useNavigate();

    const { brand_id } = useParams<{ brand_id: string }>()
    const [dataFetchingState, setDataFetchingState] = useState<string>("LOADING");

    const [brandPlatformMapping, setBrandPlatformMapping] = useState<Brand>();

    useEffect(() => {
        fetchBrandPlatformMapping();
    }, []);


    const fetchBrandPlatformMapping = async () => {
        setDataFetchingState("LOADING");

        const token = localStorage.getItem("token");

        let api = `${BASE_URL}brand/brandPlatformMapping?brand_id=${brand_id}`;

        const headers = {
            "Authorization": `Bearer ${token}`
        }
        try {
            const result = await axios.get(api, { headers });
            const { brand_platform_mapping } = result?.data?.data;

            setBrandPlatformMapping(brand_platform_mapping);
            setDataFetchingState("SUCCESS");
        } catch (error) {
            setDataFetchingState("ERROR");
        } finally {

        }
    }







    return (
        <div className="px-10 py-6" >
            <div className="flex flex-row justify-between items-center" >
                <div className="flex flex-row items-center">
                    <span onClick={() => navigate("/brandManagement/brandList")} className="cursor-pointer" style={{ fontSize: 12, fontWeight: 400, lineHeight: "18px", color: BLACK.B_40 }} >Brand List</span>
                    <span className="mx-2" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M8.40003 7.99999L5.80003 5.39999C5.67781 5.27777 5.6167 5.12221 5.6167 4.93333C5.6167 4.74444 5.67781 4.58888 5.80003 4.46666C5.92225 4.34444 6.07781 4.28333 6.2667 4.28333C6.45559 4.28333 6.61114 4.34444 6.73337 4.46666L9.80003 7.53333C9.8667 7.59999 9.91392 7.67221 9.9417 7.74999C9.96948 7.82777 9.98337 7.9111 9.98337 7.99999C9.98337 8.08888 9.96948 8.17221 9.9417 8.24999C9.91392 8.32777 9.8667 8.39999 9.80003 8.46666L6.73337 11.5333C6.61114 11.6555 6.45559 11.7167 6.2667 11.7167C6.07781 11.7167 5.92225 11.6555 5.80003 11.5333C5.67781 11.4111 5.6167 11.2555 5.6167 11.0667C5.6167 10.8778 5.67781 10.7222 5.80003 10.6L8.40003 7.99999Z" fill="#1C1C1C" fill-opacity="0.4" />
                        </svg>
                    </span>
                    <span style={{ fontSize: 12, fontWeight: 500, lineHeight: "18px" }} >Brand Details</span>
                </div>
                {
                    dataFetchingState === "SUCCESS" && (
                        <div onClick={() => navigate(`/brandManagement/brandDetailEdit/${brand_id}`)} className="w-fit cursor-pointer" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: PRIMARY.P_BLue, textDecoration: "underline" }} >
                            Edit Details
                        </div>
                    )
                }

            </div>
            {/* Loding Brand Skeleton  */}
            {
                dataFetchingState === "LOADING" && (
                    <div className="mt-6 w-fit" style={{ height: 132 }} >
                        <Skeleton
                            sx={{ height: 132, borderRadius: 16, width: "calc(100% - 80px)", opacity: 0.5 }}
                        />
                    </div>
                )
            }
            {/* Brand Detail */}

            {
                dataFetchingState === "SUCCESS" && (
                    <div className="flex flex-col justify-between p-6 mt-6" style={{ height: 132, boxShadow: "0px 4px 10px 4px rgba(130, 130, 130, 0.05)", borderRadius: 16 }} >
                        <div className="flex" >
                            <span className="mr-2" style={{ fontSize: 18, fontWeight: 600, lineHeight: "24px" }} >{brandPlatformMapping?.brand_name}</span>
                            <span>
                                <BrandStatusComponent status={brandPlatformMapping?.status} />
                            </span>
                        </div>
                        <div className="flex flex-row" >
                            <div className="flex flex-col justify-between" style={{ height: 36, marginRight: 50 }} >
                                <div style={{ fontSize: 10, fontWeight: 500, color: BLACK.B_40 }} >Company Name</div>
                                <div style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px" }} >{brandPlatformMapping?.company_name}</div>
                            </div>

                            <div className="flex flex-col justify-between" style={{ height: 36 }} >
                                <div style={{ fontSize: 10, fontWeight: 500, color: BLACK.B_40 }} >Platforms</div>
                                <div className="flex" style={{ height: 20 }} >
                                    {
                                        brandPlatformMapping?.platform_array.map((platform, index) => {
                                            let marginLeft = index === 0 ? 0 : 8;
                                            return (
                                                <div key={index} className="flex justify-center items-center  px-2 py-1" style={{ marginLeft, height: 20, borderRadius: 4, backgroundColor: BLACK.B_5, fontSize: 10, fontWeight: 500 }} >{platform.platform}</div>
                                            )
                                        })
                                    }
                                    {/* <div className="flex justify-center items-center  px-2 py-1" style={{ height: 20, borderRadius: 4, backgroundColor: BLACK.B_5, fontSize: 10, fontWeight: 500 }} >Amazon</div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            {/* Brand Data not available */}
            {
                dataFetchingState === "ERROR" && (
                    <div className="flex justify-center items-center  mt-6" style={{ height: 132, fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_40 }} >Unable to fetch brand data please refresh.</div>
                )
            }
            {
                dataFetchingState === "SUCCESS" && brandPlatformMapping?.platform_array.map((platform, index) => {
                    return (
                        <div className="mt-6">
                            <PlatformDashboardMappingView key={platform?.platform_id} brand_id={brand_id} platform_id={platform?.platform_id} />
                        </div>
                    )
                })
            }
        </div>
    )
}
export default BrandDetailView;